import React, { useEffect, useState } from "react";
import FinancialCrumbs from "./components/FinancialCrumbs";
import FinancialTabs from "./components/FinancialTabs";
import "./financialPage.css";
import { Table2 } from "../../components/Table2/Table2";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../Error/ApiError";
import { DateBox, LoadPanel, Popup } from "devextreme-react";

import { FinancialFunction } from "./FinancialFunction";
import { headersidebarfunction } from "../HeaderSideBar/headersidebarfunction";
import HelpPopup from "../HeaderSideBar/HelpPopup";
import { PrintChecks } from "./Popups/PrintChecks";

const Tablecoloum = [
  { title: "", moreoption: true, customwidth: "50px" },
  {
    title: "Select",
    customSelectBox: true,
    bool: true,
  },
  { title: "Recipient Category" },
  {
    title: "Court Name",
  },
  {
    title: "Disburse Frequency",
  },
  {
    title: "Alert",
    bool: true,
  },
  {
    title: "Amount",
    currency: true,
  },
  {
    title: "Added Disbursement",
    currency: true,
  },
  {
    title: "Sub'd Payment",
    currency: true,
  },
  {
    title: "Bank Acct",
  },
  {
    title: "Recipient Name",
  },
  {
    title: "Address",
    bool: true,
  },
  {
    title: "Offender #",
  },
  {
    title: "Person Name",
  },
  {
    title: "Fee Code",
  },
  {
    title: "Fee Name",
  },
  {
    title: "Person Status",
  },
];

const PreDisbursementsReport = () => {
  const [loading, setLoading] = useState(false);
  const [errorhandler, seterrorhandler] = useState(false);
  const clas = new FinancialFunction();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [disbursementrowdata, setdisbursementrowdata] = useState([]);

  const [refresh, setrefresh] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);

  const [date, setdate] = useState(new Date().toISOString().slice(0, 10));

  const refreshApi = () => {
    clas.getPreDisbursementsReport(setLoading, seterrorhandler, navigate, setdata, date);
  };
  useEffect(() => {
    refreshApi();
  }, [date]);

  const clas2 = new headersidebarfunction();
  const [markupid, setmarkupid] = useState("");
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);
  const [printCheckPopup, setPrintCheckPopup] = useState(false);

  useEffect(() => {
    clas2.getHelp(setLoading, navigate, setmarkup, setmarkupid, "OB.17", setmarkupissScreenNamu, setmarkupisCaption);
  }, []);

  return (
    <>
      {markupid && (
        <Popup
          visible={markupid && helpPopUp}
          onHiding={() => {
            setHelpPopUp(false);
          }}
          showTitle={false}
          width={1200}
          height={670}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <HelpPopup
            markup={markup}
            markupid={markupid}
            helpPopUp={helpPopUp}
            setHelpPopUp={setHelpPopUp}
            markupsCaption={markupsCaption}
            markupsScreenName={markupsScreenName}
          />
        </Popup>
      )}
      <Popup
        visible={printCheckPopup}
        onHiding={() => {
          setPrintCheckPopup(false);
        }}
        showTitle={false}
        width={770}
        height={670}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <PrintChecks printCheckPopup={printCheckPopup} setPrintCheckPopup={setPrintCheckPopup} />
      </Popup>

      <FinancialCrumbs title={"Pre-Disbursements Report"} />
      <div className="financial-page">
        <FinancialTabs />
        <div className="financial-page-section">
          <p className="page-info-number">OB.17</p>
          {markupid && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28px"
              height="28px"
              viewBox="0 0 24 24"
              fill="none"
              style={{ cursor: "pointer" }}
              className="svg-blue-black"
              onClick={() => {
                setHelpPopUp(true);
              }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                fill="white"
              />
            </svg>
          )}
          <>
            {errorhandler ? (
              <ApiError />
            ) : (
              <div>
                <LoadPanel shadingColor="true" visible={loading} delay={10} />

                <div>
                  <div className="page-details-search-content">
                    <div className="display-flex-div">
                      <label className="edit_container_label">Effective Date</label>
                      <div id="Query">
                        <DateBox
                          value={date}
                          onValueChange={(e) => {
                            const initialDateString = e;
                            const initialDate = new Date(initialDateString);
                            const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                            const utcFormatted = new Date(utcTime).toISOString();

                            setdate(e ? utcFormatted : null);
                          }}
                          useMaskBehavior={true}
                        />
                      </div>
                    </div>
                    <div className="buttons">
                      <button
                        className="btn"
                        onClick={() => {
                          refreshApi();
                        }}
                      >
                        Refresh
                        <svg
                          className="svg-blue-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_6247_15093)">
                            <path
                              d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                              fill="#424167"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6247_15093">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                      <button
                        className="btn"
                        onClick={() => {
                          setexportPopupOpen(true);
                        }}
                      >
                        Export
                        <svg
                          className="svg-blue-white"
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                            fill="#424167"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div style={{ marginTop: "1%" }}>
                    <Table2
                      Tablecoloum={Tablecoloum}
                      tableRow={data}
                      editTable={false}
                      load={false}
                      coloumWidthText={"PreDisbursementsReport"}
                      height={800}
                      mandatoryColoum={"Offender #"}
                      showTotalBlock={true}
                      PreDisbursementsReport={true}
                      PreDisbursementsReporteightclick={true}
                      exportInDifferentFormate={true}
                      exportPopupOpen={exportPopupOpen}
                      setexportPopupOpen={setexportPopupOpen}
                      exportwidth={25}
                      exportFont={8}
                      exporttitle={"Pre-Disbursements Report"}
                      setPrintCheckPopup={setPrintCheckPopup}
                      disbursementrowdata={disbursementrowdata}
                      setdisbursementrowdata={setdisbursementrowdata}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default PreDisbursementsReport;
