import { useCallback, useEffect, useState } from "react";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import MonitoringTabs from "./components/MonitoringTabs";
import { DataGrid, DateBox, DropDownBox, LoadPanel } from "devextreme-react";
import { Toster } from "../../components/Toster/Toster";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { receiveAgencyPaymentTableColumn } from "./Data";
import { ColumnDropdown } from "../../components/Dropdownn";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import "./monitoring.css";
import { MonitoringFunction } from "./MonitoringFunction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeMonitoringTabs } from "../../reducer/AdminReadReducer";
import { useSelector } from "react-redux";
import { getAccessData } from "../../utils/getAccessPermission";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const ReceiveAgencyPayment = () => {
  const { userAccessData } = useSelector((store) => store.Home);
  const { monitoringTabs } = useSelector((store) => store.AdminTable);
  const [receiveAgencyPaymentData, setReceiveAgencyPaymentData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [selectedValue, setSelectedValue] = useState({ gBillingID: "" });
  const [dataSource, setDataSource] = useState([]);
  const ApiClass = new MonitoringFunction();
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    paymentDate: new Date().toISOString().slice(0, 10),
    cashDrawer: { name: "", id: "" },
    checkAuthorizationNumber: "",
    selectMaxAmount: "",
  });
  const [cashDrawerDropdownData, setCashDrawerDropdownData] = useState([]);
  const [checkAuthorizationValidation, setCheckAuthorizationValidation] =
    useState("");
  const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);
  const [selectMaxAmountValidation, setSelectMaxAmountValidation] =
    useState("");
  useState("");
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const dispatch = useDispatch();
  const [temp, setTemp] = useState(false);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const getAgencyPaymentGridDataApi = () => {
    ApiClass.getAgencyPaymentGridData(
      setLoading,
      navigate,
      setReceiveAgencyPaymentData,
      setErrorhandling,
      selectedValue?.gBillingID
    );
  };

  useEffect(() => {
    ApiClass.getReceiveAgencyPaymentInvoiceDropdown(
      setLoading,
      navigate,
      setDataSource
    );
    ApiClass.getAgencyPaymentCashDrawerDropdownApi(
      setLoading,
      navigate,
      setCashDrawerDropdownData
    );
  }, []);

  useEffect(() => {
    const getUserAccessPermissions = () => {
      const accessData = getAccessData(userAccessData, "EM24");
      setAddAccess(accessData?.Add);
      setEditAccess(accessData?.Edit);
      setViewAccess(accessData?.View);
    };
    getUserAccessPermissions();
  }, []);

  useEffect(() => {
    if (selectedValue?.gBillingID) {
      getAgencyPaymentGridDataApi();
    }
  }, [selectedValue?.gBillingID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "checkAuthorizationNumber") {
      if (fields?.checkAuthorizationNumber?.charAt(0) === " ") {
        setCheckAuthorizationValidation(
          "Please Enter Check Authorization Number."
        );
      } else {
        setCheckAuthorizationValidation("");
      }
    }
    if (name === "selectMaxAmount") {
      setSelectMaxAmountValidation("");
    }
  };

  const resetFields = () => {
    setSelectedValue({});
    setFields({
      paymentDate: new Date().toISOString().slice(0, 10),
      cashDrawer: { name: "", id: "" },
      checkAuthorizationNumber: "",
      selectMaxAmount: "",
    });
    setReceiveAgencyPaymentData([]);
    setSelectedRowCheckbox([]);
  };

  const data = {
    gBillingID: selectedValue?.gBillingID,
    authorizationNumber: fields?.checkAuthorizationNumber,
    cashDrawerID: fields?.cashDrawer?.id,
    paymentDate: fields?.paymentDate,
    gridData: selectedRowCheckbox?.map(({ ID, ...rest }) => rest),
  };

  const handleSave = () => {
    let isValid = true;
    if (
      !fields?.checkAuthorizationNumber ||
      fields?.checkAuthorizationNumber?.[0] === " "
    ) {
      isValid = false;
      setCheckAuthorizationValidation(
        "Please Enter Check Authorization Number."
      );
    }
    if (selectedValue?.gBillingID === "") {
      isValid = false;
    }
    if (isValid) {
      ApiClass.saveReceiveAgencyPayment(
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        data,
        resetFields
      );
    }
  };

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  const onClose = () => {
    dispatch(removeMonitoringTabs("Receive Agency Payment"));
    setTemp(true);
  };

  useEffect(() => {
    if (temp) {
      navigate(monitoringTabs[monitoringTabs.length - 1]?.link);
    }
  }, [monitoringTabs, temp]);

  const handleSelectAll = () => {
    setSelectedRowCheckbox(receiveAgencyPaymentData);
  };

  const handleDeselectAll = () => {
    setSelectedRowCheckbox([]);
  };

  const handleSelectMaxAmount = () => {
    let isValid = true;
    if (!fields?.selectMaxAmount || fields?.selectMaxAmount === "0") {
      isValid = false;
      setSelectMaxAmountValidation("Maximum Amount Must Be Greater Than Zero");
    }
    if (isValid) {
      const targetAmount = parseInt(fields?.selectMaxAmount, 10) || 0;
      let currentAmount = 0;
      const selectedRowsForAmount = [];
      for (let i = 0; i < receiveAgencyPaymentData?.length; i++) {
        const row = receiveAgencyPaymentData[i];
        if (currentAmount + row.Amount < targetAmount) {
          currentAmount += row.Amount;
          selectedRowsForAmount.push(row);
        } else {
          break;
        }
      }
      setSelectedRowCheckbox(selectedRowsForAmount);
    }
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <MonitoringCrumbs title={"Receive Agency Payment"} />
      <div className="financial-page">
        <MonitoringTabs />
        {viewAccess ? <div className="financial-page-section receive-agency-payment">
          <p className="page-info-number">EM.24</p>
          <div className="financial-page-section receive-agency-payment"
            style={{
              pointerEvents: editAccess ? "auto" : "none",
              opacity: editAccess ? 1 : 0.6,
              cursor: editAccess ? "default" : "not-allowed"
            }}
          >
            <div className="page-details-search-content">
              <div className="display-flex-div">
                <label className="edit_container_label">Invoice</label>
                <DropDownBox
                  placeholder="Select..."
                  width={400}
                  dataSource={dataSource}
                  value={selectedValue?.sDescription}
                  valueExpr={"sDescription"}
                  displayExpr={"sDescription"}
                  opened={isGridBoxOpened}
                  onOptionChanged={onGridBoxOpened}
                  contentRender={(value, element) => (
                    <DataGrid
                      dataSource={dataSource}
                      hoverStateEnabled={true}
                      height={180}
                      onRowClick={(e) => {
                        setSelectedValue(e?.data);
                        setSelectedRowCheckbox([]);
                        setIsGridBoxOpened(false);
                      }}
                    >
                      <Selection mode="single" />
                      <FilterRow visible={true} />
                      <Column
                        dataField="Invoice #"
                        caption="Invoice #"
                        width={"auto"}
                      />
                      <Column
                        dataField="Entity"
                        caption="Entity"
                        width={"auto"}
                      />
                      <Column
                        dataField="Amount"
                        caption="Amount"
                        width={"auto"}
                        format={{ type: "currency", precision: 2 }}
                      />
                      <Column
                        dataField="Due"
                        caption="Due"
                        width={"auto"}
                        format={{ type: "currency", precision: 2 }}
                      />
                      <Column dataField="Items" caption="Items" width={"auto"} />
                      <Column dataField="Date" caption="Date" width={"auto"} />
                    </DataGrid>
                  )}
                />
              </div>
              <div className="display-flex-div">
                <label
                  htmlFor="checkAuthorizationNumber"
                  className="edit_container_label"
                >
                  Check/Authorization Number
                  <span className="marks_mandatary">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    name="checkAuthorizationNumber"
                    id="checkAuthorizationNumber"
                    placeholder="Enter Check/Authorization Number"
                    className="fee-payment-content-info-input"
                    onChange={handleInputChange}
                    value={fields?.checkAuthorizationNumber}
                  />
                  {checkAuthorizationValidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "268px" }}
                    >
                      {checkAuthorizationValidation}
                    </p>
                  )}
                </div>
              </div>
              <div className="buttons">
                <button className="btn">Process Credit Card</button>
              </div>
            </div>
            <div
              className="page-details-search-content"
              style={{ alignItems: "center", marginTop: "10px" }}
            >
              <div className="display-flex-div edit_content_space">
                <label className="edit_container_label">Cash Drawer</label>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"cashDrawer"}
                  dropDownData={cashDrawerDropdownData}
                />
              </div>
              <div className="display-flex-div">
                <label className="edit_container_label">Payment Date</label>
                <DateBox
                  useMaskBehavior={true}
                  value={fields.paymentDate}
                  onValueChange={(e) => {
                    if (e) {
                      const initialDateString = e;
                      const initialDate = new Date(initialDateString);
                      const utcTime =
                        initialDate.getTime() -
                        initialDate.getTimezoneOffset() * 60000;
                      const utcFormatted = new Date(utcTime).toISOString();
                      setFields((prev) => ({
                        ...prev,
                        paymentDate: e ? utcFormatted : null,
                      }));
                    }
                  }}
                />
              </div>
            </div>
            <div className="table-section">
              {errorhandling ? (
                <ApiError />
              ) : (
                <>
                  {receiveAgencyPaymentData?.length ? (
                    <Table1
                      editTable={false}
                      height={800}
                      Tablecoloum={receiveAgencyPaymentTableColumn}
                      tableRow={receiveAgencyPaymentData}
                      load={loading}
                      coloumWidthText={"Receive Agency Payment"}
                      mandatoryColoum={"Start"}
                      setSelectedRowCheckbox={setSelectedRowCheckbox}
                      selectedRowCheckbox={selectedRowCheckbox}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <div className="page-info-buttons">
              <div className="buttons" style={{ alignItems: "baseline" }}>
                <button className="btn primary_btn" onClick={handleSave}>
                  Ok
                </button>
                <button className="btn" onClick={onClose}>
                  Cancel
                </button>
                <div className="agency-column-fields">
                  <button className="btn" onClick={handleSelectAll}>
                    Select All
                  </button>
                  <button className="btn" onClick={handleDeselectAll}>
                    Deselect All
                  </button>
                </div>
                <div className="agency-column-fields">
                  <button
                    className="btn"
                    onClick={handleSelectMaxAmount}
                    style={{ width: "200px" }}
                  >
                    SelectMax Amount Below
                  </button>
                  <input
                    type="number"
                    name="selectMaxAmount"
                    id="selectMaxAmount"
                    className="fee-payment-content-info-input"
                    style={{ width: "260px" }}
                    onChange={handleInputChange}
                    value={fields?.selectMaxAmount}
                  />
                  {selectMaxAmountValidation && (
                    <p className="error_validation">
                      {selectMaxAmountValidation}
                    </p>
                  )}
                </div>
                <p className="edit_container_label">
                  Amount Received:{" "}
                  {formattedPrice(
                    selectedRowCheckbox?.reduce(
                      (sum, item) => sum + item["AMT To Recieve"],
                      0
                    )
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        :
        "No EM.28 View Access"}
      </div>
    </>
  );
};

export default ReceiveAgencyPayment;
