import { LoadPanel, Popup } from "devextreme-react";
import { useEffect, useState } from "react";
import { Toster } from "../../components/Toster/Toster";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import MonitoringTabs from "./components/MonitoringTabs";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { rateEditorTableColumn } from "./Data";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add.svg";
import AddNewRate from "./Popups/AddNewRate";
import { MonitoringFunction } from "./MonitoringFunction";
import { useNavigate } from "react-router-dom";

const RateEditor = () => {
  const [rateEditorData, setRateEditorData] = useState([]);
  const [rateEditorRowData, setRateEditorRowData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [addNewRatePopup, setAddNewRatePopup] = useState(false);
  const [addNewRatePopupType, setAddNewRatePopupType] = useState("");
  const [equipmentTypeDropdownData, setEquipmentTypeDropdownData] = useState(
    []
  );
  const [dataSource, setDataSource] = useState([]);
  const ApiClass = new MonitoringFunction();
  const navigate = useNavigate();

  const refreshApi = () => {
    ApiClass.getRateEditorLogData(
      setLoading,
      navigate,
      setRateEditorData,
      setErrorhandling
    );
  };

  const dropdownApi = () => {
    ApiClass.getEquipmentTypeDropdownApi(
      setLoading,
      navigate,
      setEquipmentTypeDropdownData
    );
    ApiClass.getRateEditorEntityDropdownApi(
      setLoading,
      navigate,
      setDataSource
    );
  };

  useEffect(() => {
    refreshApi();
    dropdownApi();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <Popup
        visible={addNewRatePopup}
        onHiding={() => {
          setAddNewRatePopup(false);
        }}
        showTitle={false}
        width={768}
        height={620}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddNewRate
          addNewRatePopup={addNewRatePopup}
          setAddNewRatePopup={setAddNewRatePopup}
          addNewRatePopupType={addNewRatePopupType}
          equipmentTypeDropdownData={equipmentTypeDropdownData}
          dataSource={dataSource}
          navigate={navigate}
          setLoading={setLoading}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={refreshApi}
          rateEditorRowData={rateEditorRowData}
        />
      </Popup>
      <MonitoringCrumbs title={"Rate Editor"} />
      <div className="financial-page">
        <MonitoringTabs />
        <div className="financial-page-section">
          <p className="page-info-number">EM.27</p>
          <div className="page-info-buttons">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  setAddNewRatePopupType("Add");
                  setAddNewRatePopup(true);
                }}
              >
                Add New Rate <AddIcon className="svg-blue-white" />
              </button>
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-white"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="table-section">
            {errorhandling ? (
              <ApiError />
            ) : (
              <Table1
                editTable={false}
                height={800}
                Tablecoloum={rateEditorTableColumn}
                tableRow={rateEditorData}
                load={loading}
                coloumWidthText={"Rate Editor"}
                mandatoryColoum={"Entity ID"}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={32}
                exporttitle={"Rate Editor"}
                monitoringRateEditorMenu={true}
                setRateEditorRowData={setRateEditorRowData}
                setAddNewRatePopupType={setAddNewRatePopupType}
                setAddNewRatePopup={setAddNewRatePopup}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RateEditor;
