import React, { useEffect, useState } from "react";
import "./models.css";
import { Table1 } from "../../Administration/Table1/Table1";
import { LoadPanel, Popup, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { logOut } from "../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../Error/ApiError";
import SendEmailConfirmPopup from "./SendEmailConfirmPopup";

const sendEmailTablecoloumn = [
  { title: "Select", bool: true, sendEmailCheckData: true },
  { title: "Category" },
  { title: "Contact Type" },
  { title: "Infromation" },
  { title: "Comment" },
  { title: "Primary", bool: true },
];

const sendEmailTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Category: val?.sType,
      "Contact Type": val?.sContactInfoTypeName,
      SContactInfoTypeName:val?.sType,
      Infromation:val?.sInformation,
      SInformation: val?.sInformation,
      Comment: val?.sComment,
      Primary: val?.bPrimary,
      ID: i,
      gContactInfoTypeId: val?.gContactInfoTypeId,
      gPersonContactInfoId: val?.gPersonContactInfoId,
      gPersonId: val?.gPersonId,
      sEmailSuffix: val?.sEmailSuffix,
      Select: true,
    });
  });
  return arr;
};

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const SendEmail = ({
  recivePaymentModel,
  setRecivePaymentModel,
  userinfo,
  toastConfig,
  setToastConfig,
  documentWizrard,
}) => {
  const [fields, setFields] = useState({
    sendFrom: "",
    selectBox: "",
    emailTemplate: { name: "", id: "" },
    emailMessage: "",
    emailSubject: "",
    textTemplate: { name: "", id: "" },
    textMessage: "",
  });
  const [emailData, setEmailData] = useState([]);
  const [emailDropdownData, setEmailDropdownData] = useState([]);
  const [emailTableData, setEmailTableData] = useState([]);
  const [load, setLoad] = useState(false);
  const [emailErrorErrorHandling, setEmailErrorErrorHandling] = useState(false);
  const [emailSubjectvalidation, setEmailSubjectvalidation] = useState("");
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");
  const [emailSentData, setEmailSentData] = useState([]);
  const [emailTemplatesData, setEmailTemplatesData] = useState([]);
  const [sendEmailPopup, setSendEmailPopup] = useState(false);
  const [checkDataValidation, setCheckDataValidation] = useState("");

  const getEmailData = async () => {
    setLoad(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Person/get-email-prefill?personId=${userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setEmailTableData(sendEmailTableData(val?.data?.data?.contacts));
          setEmailSentData(sendEmailTableData(val?.data?.data?.contacts));
          setEmailData(val?.data?.data);
          setFields((prev) => ({
            ...prev,
            emailTemplate: {
              name: val?.data?.data?.templates?.[0]?.sName,
              id: val?.data?.data?.templates?.[0]?.gReportCatalogId,
            },
            textTemplate: {
              name: val?.data?.data?.templates?.[0]?.sName,
              id: val?.data?.data?.templates?.[0]?.gReportCatalogId,
            },
            selectBox: ["text", "email"],
            sendFrom: val?.data?.data?.sendFrom,
            emailSubject: documentWizrard
              ? `Documents Attached for ${userinfo?.sFirstLast}`
              : "",
          }));
          setEmailDropdownData(
            val?.data?.data?.templates?.map((val) => {
              return {
                value: val?.sName,
                key: val?.gReportCatalogId,
                sEmailBody: val?.sEmailBody,
              };
            })
          );
          setEmailTemplatesData(val?.data?.data?.templates);
          setLoad(false);
        } else {
          setEmailErrorErrorHandling(true);
          console.error(val?.data?.errors);
        }
      })
      .catch((error) => {
        setLoad(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setEmailErrorErrorHandling(true);
        }
      });
  };

  useEffect(() => {
    if (recivePaymentModel) {
      getEmailData();
    }
    setFields({
      sendFrom: "",
      selectBox: "",
      emailTemplate: { name: "", id: "" },
      emailMessage: "",
      emailSubject: "",
      textTemplate: { name: "", id: "" },
      textMessage: "",
    });
    setCheckDataValidation("");
    setEmailSubjectvalidation("");
    setEmailSentData([]);
    setEmailTableData([]);
  }, [recivePaymentModel]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "emailSubject") {
      if (fields?.emailSubject.charAt(0) === " ") {
        setEmailSubjectvalidation("Please Enter Email Subject.");
      } else {
        setEmailSubjectvalidation("");
      }
    }
  };

  const handleCheckboxChange = (option) => {
    setFields((prevFields) => {
      const updatedSelectBox = [...prevFields.selectBox];
      const optionIndex = updatedSelectBox.indexOf(option);

      if (optionIndex === -1) {
        if (option === "email") {
          const emailItems = emailTableData.filter(
            (item) => item.Category === "Email"
          );
          setEmailSentData([...emailSentData, ...emailItems]);
        }
        if (option === "text") {
          const textItems = emailTableData.filter(
            (item) => item.Category !== "Email"
          );
          setEmailSentData((prevData) => [...prevData, ...textItems]);
        }
        updatedSelectBox.push(option);
      } else {
        if (option === "email") {
          const filteredData = emailSentData.filter(
            (item) => item.Category !== "Email"
          );
          setEmailSentData(filteredData);
        }
        if (option === "text") {
          const filteredData = emailSentData.filter(
            (item) => item.Category === "Email"
          );
          setEmailSentData(filteredData);
        }
        updatedSelectBox.splice(optionIndex, 1);
      }

      return { ...prevFields, selectBox: updatedSelectBox };
    });
  };

  useEffect(() => {
    const selectedDropdownItem = emailDropdownData.find(
      (item) => item.key === fields?.emailTemplate?.id
    );
    const plainText = selectedDropdownItem?.sEmailBody || "";
    setFields((prev) => ({
      ...prev,
      emailMessage: plainText,
    }));
  }, [fields?.emailTemplate?.id]);

  useEffect(() => {
    const selectedDropdownItem = emailDropdownData.find(
      (item) => item.key === fields?.textTemplate?.id
    );
    const plainText = selectedDropdownItem?.sEmailBody || "";
    setFields((prev) => ({ ...prev, textMessage: plainText }));
  }, [fields?.textTemplate?.id]);

  const data = {
    SendFrom: fields?.sendFrom,
    Subject: fields?.emailSubject,
    EmailTemplateId: fields?.emailTemplate?.id
      ? fields?.emailTemplate?.id
      : "00000000-0000-0000-0000-000000000000",
    Templates: emailTemplatesData,
    EmailMessage: fields?.emailMessage,
    TextTemplateId: fields?.textTemplate?.id
      ? fields?.textTemplate?.id
      : "00000000-0000-0000-0000-000000000000",
    TextMessage: fields?.textMessage,
    Contacts: emailSentData,
    // ApiReturn: {
    //   IsSuccess: true,
    //   ResponseMessage: null,
    //   ResponseIcon: null,
    // },
  };

  const handleSave = async () => {
    let isValid = true;
    if (!fields.emailSubject || fields.emailSubject.charAt(0) === " ") {
      setEmailSubjectvalidation("Please Enter Email Subject.");
      isValid = false;
    }
    if (!fields.emailSubject && emailSentData?.length <= 0) {
      setSendEmailPopup(true);
      isValid = false;
    }
    if (fields.selectBox.length === 1) {
      if (
        fields.selectBox.includes("email") &&
        emailSentData.some((item) => item.Category !== "Email")
      ) {
        setCheckDataValidation(`'1aa' is not valid phone number`);
        isValid = false;
      }
      if (
        fields.selectBox.includes("text") &&
        emailSentData.some((item) => item.Category === "Email")
      ) {
        setCheckDataValidation(`'1aa' is not valid phone number`);
        isValid = false;
      }
    }
    if (isValid) {
      setSendEmailPopup(true);
    }
  };

  const sendEmailApi = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Person/send-email?personId=${userinfo?.gPersonId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          setRecivePaymentModel(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Email Sent!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Email Sent!",
              type: "success",
            });
          }, 1900);
        } else {
          console.error(val?.data?.errors);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error(error?.message);
      });
  };

  useEffect(() => {
    setCheckDataValidation("");
  }, [emailSentData]);

  return (
    <>
      <Popup
        visible={sendEmailPopup}
        onHiding={() => {
          setSendEmailPopup(false);
        }}
        showTitle={false}
        width={500}
        height={140}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SendEmailConfirmPopup
          sendEmailPopup={sendEmailPopup}
          setSendEmailPopup={setSendEmailPopup}
          selectedRecipient={emailSentData?.length}
          sendEmailApi={sendEmailApi}
        />
      </Popup>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      
      <div className="email-container"> 

        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  id="Vector_2"
                  d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
                  fill="#fff"
                />
              </svg>
              <p>Send Email / Text</p>
            </div>
            <p class="popup_header_title_para">UT.7</p>
          </div>
          <svg
            onClick={() => {
              setRecivePaymentModel(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        {/* <ScrollView style={{ width: "100%", height: "650px" }}> */}
          <div>
            <div className="email-item">
              <div className="left-item">
                <div className="input-container">
                  <label htmlFor="sendFrom">Send From</label>
                  <input
                    id="sendFrom"
                    name="sendFrom"
                    type="email"
                    placeholder="Enter Email Address"
                    className="input-type"
                    value={fields.sendFrom}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-container flex">
                  <div className="checkbox-flex">
                    <input
                      type="checkbox"
                      name=""
                      id="email"
                      checked={fields.selectBox.includes("email")}
                      onChange={() => handleCheckboxChange("email")}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="checkbox-flex">
                    <input
                      type="checkbox"
                      name=""
                      id="text"
                      checked={fields.selectBox.includes("text")}
                      onChange={() => handleCheckboxChange("text")}
                    />
                    <label htmlFor="text">Text</label>
                  </div>
                </div>
                <div className="input-container custom-width-dropdown">
                  <label htmlFor="">Email Template</label>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"emailTemplate"}
                    dropDownData={emailDropdownData?.map((vall) => {
                      return {
                        name: vall?.value,
                        id: vall?.key,
                      };
                    })}
                    disabled={
                      fields?.selectBox.length === 0 ||
                      (fields.selectBox.includes("text") &&
                        !fields.selectBox.includes("email"))
                    }
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="emailSubject">
                    Email Subject <span className="marks_mandatary">*</span>
                  </label>
                  <input
                    id="emailSubject"
                    name="emailSubject"
                    type="text"
                    placeholder="Add a subject"
                    className="input-type"
                    onChange={handleInputChange}
                    value={fields.emailSubject}
                  />
                  {emailSubjectvalidation && (
                    <p className="error_validation">{emailSubjectvalidation}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="emailMessage">Email Message</label>
                  <textarea
                    name="emailMessage"
                    id="emailMessage"
                    placeholder="Write message"
                    className="textarea-type"
                    value={fields?.emailMessage}
                    onChange={handleInputChange}
                    // disabled={true}
                  ></textarea>
                </div>
                <div className="input-container custom-width-dropdown">
                  <label htmlFor="">Text Template</label>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"textTemplate"}
                    dropDownData={emailDropdownData?.map((vall) => {
                      return {
                        name: vall?.value,
                        id: vall?.key,
                      };
                    })}
                    disabled={
                      fields?.selectBox.length === 0 ||
                      (fields.selectBox.includes("email") &&
                        !fields.selectBox.includes("text"))
                    }
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="textMessage">Text Message</label>
                  <textarea
                    name="textMessage"
                    id="textMessage"
                    placeholder="Write message"
                    className="textarea-type"
                    value={fields?.textMessage}
                    onChange={handleInputChange}
                    // disabled={true}
                  ></textarea>
                </div>
                {fields?.textMessage?.length > 0 && (
                  <div className="input-container">
                    <label htmlFor="emailMessage">
                      {fields?.textMessage?.length} Characters
                    </label>
                  </div>
                )}
              </div>
              <div className="right-item">
                {emailErrorErrorHandling ? (
                  <ApiError />
                ) : (
                  <Table1
                    editTable={false}
                    height={400}
                    Tablecoloum={sendEmailTablecoloumn}
                    tableRow={emailTableData.map((val, i) => {
                      return { ...val, ID: i };
                    })}
                    load={load}
                    coloumWidthText={"SendEmail"}
                    mandatoryColoum={"Category"}
                    emailSentData={emailSentData}
                    setEmailSentData={setEmailSentData}
                  />
                )}
                <p className="error_validation">{checkDataValidation}</p>
              </div>
            </div>
            <div className="popup_buttons" style={{ marginRight: "20px" }}>
              <button
                className="btn primary_btn"
                style={{
                  background: "var(--button2)",
                  color: "#fff",
                }}
                onClick={handleSave}
              >
                Send
              </button>
              <button
                className="btn"
                onClick={() => {
                  setRecivePaymentModel(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        {/* </ScrollView> */}

      </div>
    </>
  );
};
