import axios from "axios";
import { Calendar, DateBox, LoadPanel, Popup, ScrollView, Toast } from "devextreme-react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { handleGetLocal } from "../../../../services/auth-service";
import { useSelector } from "react-redux";
import { seton_Save_Edit } from "../../../../reducer/participantNoteReducer";
import { useDispatch } from "react-redux";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={reset}
      />
    </>
  );
};

export const CreateAppointment = ({
  setreportingData,
  userinfo,
  sentanceNote,
  interval,
  toastConfig,
  setToastConfig,
}) => {
  const componentRef = useRef(null);
  const pathname = handleGetLocal("pathURL");
  const [loading, setLoading] = useState(false);
  const [reportType, setreportType] = useState([]);
  const [locationEntity, setlocationEntity] = useState([]);
  const [supervisor, setsupervisor] = useState([]);
  const { user_data } = useSelector((store) => store.Home);
  const [fields, setfields] = useState({
    ReportType: {
      name: "Report Date",
      id: "76c688af-b5f2-e011-98aa-00508d98a9ee",
    },
    PreviousFailedToReport: false,
    ReportTime: "",
    LocationEntity: {
      name: "Acme Recovery and Monitoring",
      id: "fa386380-28d1-4207-9069-5cd173af2547",
    },
    SentenceNotes: "",
    ReportDate: new Date().toISOString().split("T")[0] + "T00:00:00",
    ortime: "",
    Supervisor: {
      name: `${user_data.sLastName} ${user_data.sFirstName}`,
      id: user_data.gUserId,
    },
    PreReportNote: "",
    getTime: {
      name: "",
      id: "",
    },
  });

  const [ReportType, setReportType] = useState("");
  const [LocationEntity, setLocationEntity] = useState("");
  const [Supervisor, setSupervisor] = useState("");
  const [ReportDate, setReportDate] = useState("");
  const [PreReportNote, setPreReportNote] = useState("");
  const [ReportTime, setReportTime] = useState("");
  const [gActivityTypeID, setgActivityTypeID] = useState("");
  const { resetReportDates, on_Save_Edit } = useSelector((store) => store.participateNotes);
  const navigate = useNavigate();

  const getDropDown = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/PersonReportDates/masterDropdownValues`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        val.data.data.activityNoteType.map((vall) => {
          if (vall.value === "Pre-Report Note") {
            setgActivityTypeID(vall.key);
          }
        });
        setreportType(
          val.data.data.reportType.map((vall) => {
            return { name: vall.value, id: vall.key };
          })
        );

        setlocationEntity(
          val.data.data.locationEntity.map((vall) => {
            return { name: vall.value, id: vall.key };
          })
        );

        setsupervisor(
          val.data.data.supervisor.map((vall) => {
            return { name: vall.value, id: vall.key };
          })
        );

        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const dispatch = useDispatch();

  const createAppointment = async (body) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/PersonReportDates/createAppointment`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        ResetValue();

        dispatch(seton_Save_Edit(!on_Save_Edit));
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Added!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Added!",
            type: "success",
          });
        }, 1400);
        setLoading(false);
        setreportingData(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const onSave = () => {
    const date1 = new Date(fields.ReportDate);

    // Extract date from date1
    const year = date1.getFullYear();
    const month = String(date1.getMonth() + 1).padStart(2, "0");
    const day = String(date1.getDate()).padStart(2, "0");

    // Extract time from date2
    const hours = fields.getTime.name.split(":")[0];
    const minutes = fields.getTime.name.split(":")[1];
    const seconds = fields.getTime.name.split(":")[2];

    const mergedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000`;
    if (
      userinfo?.gPersonId &&
      fields.ReportType.id &&
      fields.LocationEntity.id &&
      fields.Supervisor.id &&
      fields.PreReportNote &&
      fields.ReportDate &&
      fields.ReportTime
    ) {
      setReportType("");
      setLocationEntity("");
      setSupervisor("");
      setReportTime("");
      setPreReportNote("");
      setReportDate("");
      createAppointment({
        GPersonId: userinfo?.gPersonId,
        GReportDateTypeId: fields.ReportType.id,
        ReportDateTime: mergedDateTime,
        GLocationEntityId: fields.LocationEntity.id,
        GSupervisorId: fields.Supervisor.id,
        GPreReportActivityNoteId: null,
        GPostReportActivityNoteId: null,
        SSentenseNotes: fields.SentenceNotes,
        SPreReportNote: fields.PreReportNote,
        BShowFtr: fields.PreviousFailedToReport,
      });
    } else {
      !fields.ReportType.id ? setReportType("Please Select Report Type") : setReportType("");
      !fields.LocationEntity.id ? setLocationEntity("Please Select Report Type") : setLocationEntity("");
      !fields.Supervisor.id ? setSupervisor("Please Select Supervisor") : setSupervisor("");
      !fields.ReportDate ? setReportDate("Please Select Report Date") : setReportDate("");
      !fields.PreReportNote ? setPreReportNote("Please enter Pre Report Note") : setPreReportNote("");
      !fields.ReportTime ? setReportTime("Please Select Report Time") : setReportTime("");
    }
  };
  const CopyLastNote = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/PersonReportDates/CopyLastNote?gPersonID=${userinfo?.gPersonId}&gActivityTypeID=${gActivityTypeID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.data?.sMemo) {
          setPreReportNote("");
        }
        setfields({
          ...fields,
          PreReportNote: val?.data?.data?.sMemo ? val?.data?.data?.sMemo : "",
        });
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const ResetValue = () => {
    setReportType("");
    setLocationEntity("");
    setSupervisor("");
    setReportTime("");
    setPreReportNote("");
    setReportDate("");
    setfields({
      ReportType: {
        name: "Report Date",
        id: "76c688af-b5f2-e011-98aa-00508d98a9ee",
      },
      PreviousFailedToReport: false,
      ReportTime: "Thu Jan 01 1970 8:00:00 GMT+0530 (India Standard Time)",
      LocationEntity: {
        // name: "Acme Recovery and Monitoring",
        // id: "fa386380-28d1-4207-9069-5cd173af2547",
      },
      SentenceNotes: "",
      ReportDate: new Date().toISOString().split("T")[0] + "T00:00:00",
      ortime: "",
      Supervisor: {
        name: userinfo?.sPrimarySupervisorName,
        id: userinfo?.gPrimarySupervisorId,
      },
      PreReportNote: "",
      getTime: {
        name: displayTime[0]?.name ? displayTime[0]?.name : "",
        id: displayTime[0]?.id ? displayTime[0]?.id : "",
      },
    });
  };

  const [displayTime, setdisplayTime] = useState([]);

  const getDisplayTime = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/ReportDate/get-report-time`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setdisplayTime(
          val?.data?.data?.map((val) => {
            return { name: val?.startTime, id: val?.startTime };
          })
        );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getDisplayTime();
  }, []);

  useEffect(() => {
    ResetValue();
  }, [resetReportDates]);

  useEffect(() => {
    getDropDown();
  }, []);

  const [visible, setVisible] = useState(false);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      <Popup
        visible={visible}
        onHiding={() => {
          setVisible(false);
        }}
        showTitle={false}
        width={300}
        height={330}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <Calendar isValid={true} showTodayButton={true} />
      </Popup>
      <ScrollView
        style={{
          width: "100%",
          height: "647px",
          border: "3px solid var(--button2)",
        }}
      >
        <div ref={componentRef} className="CreateAppointmentPopup">
          <div className="RecivePaymentModel_header">
            <div style={{ width: "200px" }} className="RecivePaymentModel_header_name">
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18 2H17V1C17 0.45 16.55 0 16 0C15.45 0 15 0.45 15 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM17 20H3C2.45 20 2 19.55 2 19V7H18V19C18 19.55 17.55 20 17 20Z"
                  fill="white"
                />
              </svg>
              <p>Create Appointment </p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  RD.1
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setreportingData(false);
                ResetValue();
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="CreateAppointment_lower_block">
            <div className="CreateAppointment_lower_block_left">
              <div className="drop_downn_block">
                <p>
                  Report type <span>*</span>
                </p>
                <DropDown
                  setfields={setfields}
                  fields={fields}
                  fieldNmae={"ReportType"}
                  dropDownData={reportType}
                  reset={setReportType}
                />
                <p
                  style={{
                    color: "red",
                    position: "absolute",
                    fontWeight: "400",
                  }}
                >
                  {ReportType}
                </p>
              </div>
              <div style={{ display: "flex", marginTop: "8%" }}>
                <input
                  checked={fields.PreviousFailedToReport}
                  onClick={() => {
                    setfields({
                      ...fields,
                      PreviousFailedToReport: !fields.PreviousFailedToReport,
                    });
                  }}
                  type="checkbox"
                />
                <p>Previous Failed to Report</p>
              </div>
              <div style={{ marginTop: "9%" }} className="drop_downn_block">
                <p>Report Time</p>
                {/* <DateBox
                  value={fields.ReportTime}
                  onValueChange={(e) => {
                    setReportTime("");
                    setfields({ ...fields, ReportTime: e });
                  }}
                  min={`Thu Jan 01 1970 8:00:00 GMT+0530 (India Standard Time)`}
                  max={`Thu Jan 01 1970 18:59:00 GMT+0530 (India Standard Time)`}
                  type="time"
                  interval={Number(interval)}
                  isValid={true}
                /> */}

                <DropDown
                  setfields={setfields}
                  fields={fields}
                  fieldNmae={"getTime"}
                  dropDownData={displayTime}
                  reset={setReportTime}
                />

                <p
                  style={{
                    color: "red",
                    position: "absolute",
                    fontWeight: "400",
                  }}
                >
                  {ReportTime}
                </p>
              </div>
              <div style={{ marginTop: "6%" }} className="drop_downn_block">
                <p>Location Entity</p>
                <DropDown
                  setfields={setfields}
                  fields={fields}
                  fieldNmae={"LocationEntity"}
                  dropDownData={locationEntity}
                  reset={setLocationEntity}
                />
                <p
                  style={{
                    color: "red",
                    position: "absolute",
                    fontWeight: "400",
                  }}
                >
                  {LocationEntity}
                </p>
              </div>
              <div style={{ marginTop: "7%" }} className="drop_downn_block">
                <p>Sentence Notes</p>
                <textarea
                  disabled={true}
                  placeholder="Write note"
                  style={{ height: "195px" }}
                  value={sentanceNote}
                  onChange={(e) => {
                    setfields({ ...fields, SentenceNotes: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="CreateAppointment_lower_block_right">
              <div className="drop_downn_block">
                <p>Report Date</p>
                <DateBox
                  style={{ marginTop: "3.5%" }}
                  value={fields.ReportDate}
                  onValueChange={(e) => {
                    setReportDate("");
                    setfields({ ...fields, ReportDate: e });
                  }}
                  useMaskBehavior={true}
                />
                <p
                  style={{
                    color: "red",
                    position: "absolute",
                    fontWeight: "400",
                  }}
                >
                  {ReportDate}
                </p>
              </div>
              <div style={{ display: "flex", marginTop: "7%" }}>
                <button
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    background: "var(--button-lightblack-lightblue)",
                  }}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  <p>View Calendar</p>{" "}
                  <svg
                    className="svg-blue-white"
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 2H17V1C17 0.45 16.55 0 16 0C15.45 0 15 0.45 15 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM17 20H3C2.45 20 2 19.55 2 19V7H18V19C18 19.55 17.55 20 17 20Z"
                      fill="#424167"
                    />
                  </svg>
                </button>
              </div>
              <div style={{ marginTop: "3.8%" }} className="drop_downn_block">
                <p>or</p>
                <DateBox
                  value={
                    fields.ReportTime &&
                    new Date(fields.ReportTime).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })
                  }
                  disabled={true}
                  type="time"
                  isValid={true}
                  displayFormat="HH:mm"
                />
              </div>
              <div style={{ marginTop: "6%" }} className="drop_downn_block">
                <p>Supervisor</p>
                <DropDown
                  setfields={setfields}
                  fields={fields}
                  fieldNmae={"Supervisor"}
                  dropDownData={supervisor}
                  reset={setSupervisor}
                />
                <p
                  style={{
                    color: "red",
                    position: "absolute",
                    fontWeight: "400",
                  }}
                >
                  {Supervisor}
                </p>
              </div>
              <div style={{ marginTop: "7%" }} className="drop_downn_block">
                <p>
                  Pre-Report Note <span>*</span>
                </p>
                <textarea
                  style={{ height: "125px" }}
                  placeholder="Write note"
                  value={fields.PreReportNote}
                  onChange={(e) => {
                    setPreReportNote("");
                    setfields({ ...fields, PreReportNote: e.target.value });
                  }}
                />
                <p
                  style={{
                    color: "red",
                    position: "absolute",
                    fontWeight: "400",
                  }}
                >
                  {PreReportNote}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "7%",
                  gap: "5px",
                }}
              >
                <button
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "space-around",
                    background: "var(--button-lightblack-lightblue)",
                  }}
                  onClick={() => {
                    CopyLastNote();
                  }}
                >
                  <p>Copy Last Note</p>
                  <svg
                    className="svg-blue-white"
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 0H2C0.9 0 0 0.9 0 2V15C0 15.55 0.45 16 1 16C1.55 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H13C13.55 2 14 1.55 14 1C14 0.45 13.55 0 13 0ZM17 4H6C4.9 4 4 4.9 4 6V20C4 21.1 4.9 22 6 22H17C18.1 22 19 21.1 19 20V6C19 4.9 18.1 4 17 4ZM16 20H7C6.45 20 6 19.55 6 19V7C6 6.45 6.45 6 7 6H16C16.55 6 17 6.45 17 7V19C17 19.55 16.55 20 16 20Z"
                      fill="#424167"
                    />
                  </svg>
                </button>
                <button
                  style={{
                    background: "var(--button-lightblack-lightblue)",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                  onClick={() => {
                    setfields({ ...fields, PreReportNote: "" });
                  }}
                >
                  <p>Erase Note</p>
                  <svg
                    className="svg-blue-white"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                      fill="#424167"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    onSave();
                  }}
                  style={{
                    backgroundColor: "var(--button2)",
                    color: "white",
                    width: "auto",
                    float: "right",
                    marginTop: "6%",
                    marginBottom: "6%",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </ScrollView>
    </>
  );
};
