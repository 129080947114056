import { Link, useLocation, useNavigate } from "react-router-dom";
import { removeProntactContactsdynamicTab } from "../../../reducer/AdminReadReducer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setsetChangesavedatapopup } from "../../../reducer/ParticipateReducer";

const ContactsTabs = () => {
  const { ProntactContactsdynamicTab, adminSavePopup } = useSelector(
    (store) => store.AdminTable
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  useEffect(() => {
    if (ProntactContactsdynamicTab.length === 0) {
      navigate("/home");
    } else {
      if (data !== undefined && data > ProntactContactsdynamicTab) {
        navigate(
          ProntactContactsdynamicTab[ProntactContactsdynamicTab.length - 1]
            ?.link
        );
      }
    }
  }, [ProntactContactsdynamicTab]);

  return (
    <>
      <div className="tabs_admin">
        <ul className="tabs_admin_tabs">
          {ProntactContactsdynamicTab.map((val) => {
            return (
              <>
                {val.title !== "Dashboard" && (
                  <li
                    className="tabs_admin_tab"
                    style={{
                      borderBottom:
                        location.pathname === val.link && "4px solid #424167",
                    }}
                  >
                    <p
                      onClick={() => {
                        if (!adminSavePopup) {
                          navigate(val.link);
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                      data-rel="tab-2"
                      className={
                        location.pathname === val.link ? "yesactiv" : "activee"
                      }
                      style={{
                        padding: "0 1px",
                        margin: "13px",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      {val.title}
                    </p>
                    <svg
                      class="svg-black-white"
                      style={{
                        marginTop: "5px",
                        marginRight: "8px",
                      }}
                      width="12"
                      height="12"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        if (!adminSavePopup) {
                          dispatch(removeProntactContactsdynamicTab(val.title));
                          setData(ProntactContactsdynamicTab);
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      <path
                        d="M9.19656 0.806647C8.93656 0.546647 8.51656 0.546647 8.25656 0.806647L4.99656 4.05998L1.73656 0.79998C1.47656 0.53998 1.05656 0.53998 0.796562 0.79998C0.536563 1.05998 0.536563 1.47998 0.796562 1.73998L4.05656 4.99998L0.796562 8.25998C0.536563 8.51998 0.536563 8.93998 0.796562 9.19998C1.05656 9.45998 1.47656 9.45998 1.73656 9.19998L4.99656 5.93998L8.25656 9.19998C8.51656 9.45998 8.93656 9.45998 9.19656 9.19998C9.45656 8.93998 9.45656 8.51998 9.19656 8.25998L5.93656 4.99998L9.19656 1.73998C9.4499 1.48665 9.4499 1.05998 9.19656 0.806647Z"
                        fill={
                          location.pathname === val.link
                            ? "rgb(66, 65, 103)"
                            : "rgba(0, 0, 0, 0.5)"
                        }
                      />
                    </svg>
                  </li>
                )}
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ContactsTabs;
