import { useEffect, useState } from "react";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import MonitoringTabs from "./components/MonitoringTabs";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { LoadPanel, Popup } from "devextreme-react";
import { Toster } from "../../components/Toster/Toster";
import { equimentTableColumn } from "./Data";
import { MonitoringFunction } from "./MonitoringFunction";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add.svg";
import AddEquipment from "./Popups/AddEquipment";
import "./monitoring.css";
import ImportEquipment from "./Popups/ImportEquipment";
import ScramControlPanel from "./Popups/ScramControlPanel";

const Equiment = () => {
  const [equimentData, setEquimentData] = useState([]);
  const [equimentRowData, setEquimentRowData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const ApiClass = new MonitoringFunction();
  const navigate = useNavigate();
  const [addEquipmentPopup, setAddEquipmentPopup] = useState(false);
  const [importEquipmentPopup, setImportEquipmentPopup] = useState(false);
  const [vendorDropdownData, setVendorDropdownData] = useState([]);
  const [equipmentTypeDropdownData, setEquipmentTypeDropdownData] = useState([]);
  const [equipmentStatusDropdownData, setEquipmentStatusDropdownData] = useState([]);
  const [equipmentLocationDropdownData, setEquipmentLocationDropdownData] = useState([]);
  const [addEquipmentPopupType, setAddEquipmentPopupType] = useState("");
  // Scram Control Panel Popup Show/Hide
  const [scramControlPanelPopup, setScramPanelControlPopup] = useState(false);

  const refreshApi = () => {
    ApiClass.getEquimentData(setLoading, navigate, setEquimentData, setErrorhandling);
  };

  const dropdownApi = () => {
    ApiClass.getVendorDropdownApi(setLoading, navigate, setVendorDropdownData);
    ApiClass.getEquipmentTypeDropdownApi(setLoading, navigate, setEquipmentTypeDropdownData);
    ApiClass.getEquipmentStatusDropdownApi(setLoading, navigate, setEquipmentStatusDropdownData);
    ApiClass.getEquipmentLocationDropdownApi(setLoading, navigate, setEquipmentLocationDropdownData);
  };

  useEffect(() => {
    refreshApi();
    dropdownApi();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <Popup
        visible={scramControlPanelPopup}
        onHiding={() => {
          setScramPanelControlPopup(false);
        }}
        showTitle={false}
        width={1050}
        height={500}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ScramControlPanel setScramPanelControlPopup={setScramPanelControlPopup} />
      </Popup>
      <Popup
        visible={addEquipmentPopup}
        onHiding={() => {
          setAddEquipmentPopup(false);
        }}
        showTitle={false}
        width={768}
        height={620}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddEquipment
          addEquipmentPopup={addEquipmentPopup}
          setAddEquipmentPopup={setAddEquipmentPopup}
          setLoading={setLoading}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={refreshApi}
          vendorDropdownData={vendorDropdownData}
          equipmentTypeDropdownData={equipmentTypeDropdownData}
          equipmentStatusDropdownData={equipmentStatusDropdownData}
          equipmentLocationDropdownData={equipmentLocationDropdownData}
          addEquipmentPopupType={addEquipmentPopupType}
          navigate={navigate}
          equimentRowData={equimentRowData}
        />
      </Popup>
      <Popup
        visible={importEquipmentPopup}
        onHiding={() => {
          setImportEquipmentPopup(false);
        }}
        showTitle={false}
        width={1050}
        height={490}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ImportEquipment
          importEquipmentPopup={importEquipmentPopup}
          setImportEquipmentPopup={setImportEquipmentPopup}
          vendorDropdownData={vendorDropdownData}
          equipmentTypeDropdownData={equipmentTypeDropdownData}
          equipmentStatusDropdownData={equipmentStatusDropdownData}
          equipmentLocationDropdownData={equipmentLocationDropdownData}
        />
      </Popup>
      <MonitoringCrumbs title={"Equipment"} />
      <div className="financial-page">
        <MonitoringTabs />
        <div className="financial-page-section">
          <p className="page-info-number">EM.7</p>
          <div className="page-info-buttons">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  setAddEquipmentPopupType("Add");
                  setAddEquipmentPopup(true);
                }}
              >
                Add Equipment <AddIcon className="svg-blue-white" />
              </button>
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-white"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  setImportEquipmentPopup(true);
                }}
              >
                Import
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_7255_62558)">
                    <path
                      d="M10 16.0002H14C14.55 16.0002 15 15.5502 15 15.0002V10.0002H16.59C17.48 10.0002 17.93 8.92021 17.3 8.29021L12.71 3.70021C12.32 3.31021 11.69 3.31021 11.3 3.70021L6.71 8.29021C6.08 8.92021 6.52 10.0002 7.41 10.0002H9V15.0002C9 15.5502 9.45 16.0002 10 16.0002ZM6 18.0002H18C18.55 18.0002 19 18.4502 19 19.0002C19 19.5502 18.55 20.0002 18 20.0002H6C5.45 20.0002 5 19.5502 5 19.0002C5 18.4502 5.45 18.0002 6 18.0002Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7255_62558">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  refreshApi();
                  setErrorhandling(false);
                  dropdownApi();
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div className="table-section">
            {errorhandling ? (
              <ApiError />
            ) : (
              <Table1
                editTable={false}
                height={800}
                Tablecoloum={equimentTableColumn}
                tableRow={equimentData}
                load={loading}
                coloumWidthText={"Monitoring Equipment"}
                mandatoryColoum={"Vendor"}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={27}
                exporttitle={"Monitoring Equipment"}
                addEquipmentMenu={true}
                setAddEquipmentPopup={setAddEquipmentPopup}
                setAddEquipmentPopupType={setAddEquipmentPopupType}
                setEquimentRowData={setEquimentRowData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Equiment;
