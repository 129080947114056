import { useState } from "react";
import { Table2 } from "../../../../../components/Table2/Table2";
import { GeneratePaymentPlanPopup } from "./GeneratePaymentPlanPopup";
import { Popup } from "devextreme-react";
import { WrieOffPopup } from "./WrieOffPopup";
import { Toster } from "../../../../../components/Toster/Toster";
import { Collection } from "./Collections";
import { QuickaddObligationPopup } from "./QuickaddObligation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setAddObligation,
  setgotooblogationValue,
  setresetReportDates,
} from "../../../../../reducer/participantNoteReducer";
import { ApiError } from "../../../../Error/ApiError";
import { useNavigate } from "react-router-dom";
import { set_dynamicParticipatTab } from "../../../../../reducer/ParticipateReducer";

const GeneratePaymentPlan = ({
  openGeneratePaymentPlanpopup,
  setopenGeneratePaymentPlanpopup,
  getTopDynamicDocketid,
  obligationData,
  toastConfig,
  setToastConfig,
  generatePaymenttogal,
  setCallObligationApi,
  callObligationApi,
}) => {
  const dispatch = useDispatch();
  const { resetReportDates } = useSelector((store) => store.participateNotes);
  return (
    <Popup
      visible={obligationData?.gObligationId && openGeneratePaymentPlanpopup}
      onHiding={() => {
        dispatch(setresetReportDates(!resetReportDates));
        setopenGeneratePaymentPlanpopup(false);
      }}
      showTitle={false}
      width={720}
      height={520}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <GeneratePaymentPlanPopup
        setopenGeneratePaymentPlanpopup={setopenGeneratePaymentPlanpopup}
        getTopDynamicDocketid={getTopDynamicDocketid}
        obligationData={obligationData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        generatePaymenttogal={generatePaymenttogal}
        setCallObligationApi={setCallObligationApi}
        callObligationApi={callObligationApi}
      />
    </Popup>
  );
};
const WrieOff = ({
  WrieOffpopup,
  setWrieOffpopup,
  toastConfig,
  setToastConfig,
  callObligationApi,
  setCallObligationApi,
  obligationData,
}) => {
  return (
    <Popup
      visible={WrieOffpopup}
      onHiding={() => {
        setWrieOffpopup(false);
      }}
      showTitle={false}
      width={768}
      height={572}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <WrieOffPopup
        writeOffAmount={WrieOffpopup}
        setWrieOffpopup={setWrieOffpopup}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        callObligationApi={callObligationApi}
        setCallObligationApi={setCallObligationApi}
        obligationData={obligationData}
      />
    </Popup>
  );
};

const QuickaddObligation = ({
  setgetQuickaddObligationPopup,
  getQuickaddObligationPopup,
  dropdownData,
  getTopDynamicDocketid,
  toastConfig,
  setToastConfig,
  header,
  callObligationApi,
  setCallObligationApi,
}) => {
  const dispatch = useDispatch();
  const { resetReportDates } = useSelector((store) => store.participateNotes);
  return (
    <Popup
      visible={getQuickaddObligationPopup}
      onHiding={() => {
        dispatch(setresetReportDates(!resetReportDates));
        setgetQuickaddObligationPopup(false);
      }}
      showTitle={false}
      width={608}
      height={640}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <QuickaddObligationPopup
        getTopDynamicDocketid={getTopDynamicDocketid}
        setgetQuickaddObligationPopup={setgetQuickaddObligationPopup}
        dropdownData={dropdownData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        header={header}
        callObligationApi={callObligationApi}
        setCallObligationApi={setCallObligationApi}
      />
    </Popup>
  );
};

const Collections = ({ setCollectionpopup, Collectionpopup, userinfo, getTopDynamicDocketid }) => {
  return (
    <Popup
      visible={Collectionpopup}
      onHiding={() => {
        setCollectionpopup(false);
      }}
      showTitle={false}
      width={1250}
      height={650}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <Collection
        setCollectionpopup={setCollectionpopup}
        userinfo={userinfo}
        Collectionpopup={Collectionpopup}
        getTopDynamicDocketid={getTopDynamicDocketid}
      />
    </Popup>
  );
};

const Tablecoloum = [
  { title: "", moreoption: true, customwidth: "50px" },
  {
    title: "Obligation Start",
    date: true,
  },
  { title: "Pay Plan Start", date: true },
  {
    title: "Obligation Type",
  },
  { title: "Fin Status" },
  { title: "!", warrningIcon: true },
  {
    title: "Recipient Category",
  },
  {
    title: "Fee Code",
  },
  {
    title: "Priority",
  },
  {
    title: "Amount",
    currency: true,
  },
  {
    title: "Paid",
    currency: true,
  },
  {
    title: "On Hand",
    currency: true,
  },
  {
    title: "Written Off",
    currency: true,
  },
  {
    title: "Balance Owed",
    currency: true,
  },
  {
    title: "Disbursed",
    currency: true,
  },
  {
    title: "Obligation Ref#",
  },
  {
    title: "Arrears",
    currency: true,
  },
  {
    title: "DOE",
    date: true,
  },
  {
    title: "Entered by",
  },
];

export const Obligation = ({
  obligations,
  callObligationApi,
  setCallObligationApi,
  dropdownData,
  loading,
  getTopDynamicDocketid,
  header,
  errorhandling,
  userinfo,
}) => {
  const [openGeneratePaymentPlanpopup, setopenGeneratePaymentPlanpopup] = useState(false);
  const dispatch = useDispatch();
  const [WrieOffpopup, setWrieOffpopup] = useState(false);
  const [Collectionpopup, setCollectionpopup] = useState(false);
  const [obligationData, setObligationData] = useState([]);
  const { resetReportDates } = useSelector((store) => store.participateNotes);
  const [getQuickaddObligationPopup, setgetQuickaddObligationPopup] = useState(false);
  const [generatePaymenttogal, setgeneratePaymenttogal] = useState(false);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const navigate = useNavigate();

  return (
    <>
      <GeneratePaymentPlan
        openGeneratePaymentPlanpopup={openGeneratePaymentPlanpopup}
        setopenGeneratePaymentPlanpopup={setopenGeneratePaymentPlanpopup}
        getTopDynamicDocketid={getTopDynamicDocketid}
        obligationData={obligationData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        generatePaymenttogal={generatePaymenttogal}
        setCallObligationApi={setCallObligationApi}
        callObligationApi={callObligationApi}
      />
      <WrieOff
        WrieOffpopup={WrieOffpopup}
        setWrieOffpopup={setWrieOffpopup}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        callObligationApi={callObligationApi}
        setCallObligationApi={setCallObligationApi}
        obligationData={obligationData}
      />
      <Collections
        setCollectionpopup={setCollectionpopup}
        Collectionpopup={Collectionpopup}
        userinfo={userinfo}
        getTopDynamicDocketid={getTopDynamicDocketid}
      />
      <QuickaddObligation
        setgetQuickaddObligationPopup={setgetQuickaddObligationPopup}
        getQuickaddObligationPopup={getQuickaddObligationPopup}
        dropdownData={dropdownData}
        getTopDynamicDocketid={getTopDynamicDocketid}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        header={header}
        callObligationApi={callObligationApi}
        setCallObligationApi={setCallObligationApi}
      />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="overviewbottomtabbutton">
        <button
          style={{
            background: "var(--button-lightblack-lightblue)",
          }}
          onClick={() => {
            setCollectionpopup(true);
          }}
        >
          <p
            style={{
              color: "var(--TextColor)",
            }}
          >
            Collections
          </p>
          <svg
            className="svg-blue-white"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="payments" clipPath="url(#clip0_5501_11449)">
              <g id="Group">
                <path
                  id="Vector"
                  d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z"
                  fill="#424167"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_5501_11449">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          style={{
            background: "var(--button-lightblack-lightblue)",
          }}
          onClick={() => {
            dispatch(setresetReportDates(!resetReportDates));
            dispatch(setAddObligation(true));

            dispatch(
              setgotooblogationValue({
                userinfo: {
                  ...userinfo,
                  sReferenceNumberV2: header?.sReferenceNumber,
                  getTopDynamicDocketid: getTopDynamicDocketid,
                },
              })
            );

            dispatch(
              set_dynamicParticipatTab({
                Offender: `/obligation/add/addObligation`,
                title: `Obligation : [ New ]`,
                userinfo: {
                  ...userinfo,
                  sReferenceNumberV2: header?.sReferenceNumber,
                },
              })
            );
            navigate(`/obligation/add/addObligation`);

            // setgetQuickaddObligationPopup(true);
          }}
        >
          <p
            style={{
              color: "var(--TextColor)",
            }}
          >
            Add obligation
          </p>
          <svg
            className="svg-blue-white"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="add_task" clipPath="url(#clip0_5501_7881)">
              <path
                id="Vector"
                d="M21.2931 5.89019L11.2931 15.8902C10.9031 16.2802 10.2731 16.2802 9.88305 15.8902L7.05305 13.0602C6.66305 12.6702 6.66305 12.0402 7.05305 11.6502C7.44305 11.2602 8.07305 11.2602 8.46305 11.6502L10.5831 13.7702L19.8731 4.48019C20.2631 4.09019 20.8931 4.09019 21.2831 4.48019C21.6831 4.87019 21.6831 5.50019 21.2931 5.89019ZM12.0031 20.0002C7.29305 20.0002 3.52305 15.9102 4.05305 11.1002C4.44305 7.58019 7.17305 4.69019 10.6631 4.11019C12.4731 3.81019 14.1931 4.13019 15.6531 4.89019C16.0431 5.09019 16.5131 5.02019 16.8231 4.71019C17.3031 4.23019 17.1831 3.42019 16.5831 3.11019C15.1131 2.36019 13.4531 1.95019 11.6831 2.00019C6.54305 2.16019 2.27305 6.34019 2.01305 11.4702C1.72305 17.2402 6.30305 22.0002 12.0031 22.0002C13.2031 22.0002 14.3431 21.7902 15.4131 21.4002C16.0931 21.1502 16.2831 20.2702 15.7631 19.7502C15.4931 19.4802 15.0831 19.3802 14.7231 19.5202C13.8731 19.8302 12.9531 20.0002 12.0031 20.0002ZM19.0031 15.0002H17.0031C16.4531 15.0002 16.0031 15.4502 16.0031 16.0002C16.0031 16.5502 16.4531 17.0002 17.0031 17.0002H19.0031V19.0002C19.0031 19.5502 19.4531 20.0002 20.0031 20.0002C20.5531 20.0002 21.0031 19.5502 21.0031 19.0002V17.0002H23.0031C23.5531 17.0002 24.0031 16.5502 24.0031 16.0002C24.0031 15.4502 23.5531 15.0002 23.0031 15.0002H21.0031V13.0002C21.0031 12.4502 20.5531 12.0002 20.0031 12.0002C19.4531 12.0002 19.0031 12.4502 19.0031 13.0002V15.0002Z"
                fill="#424167"
              />
            </g>
            <defs>
              <clipPath id="clip0_5501_7881">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      {errorhandling ? (
        <ApiError />
      ) : (
        <div className="overviewbottomtable">
          <Table2
            Tablecoloum={Tablecoloum}
            tableRow={obligations}
            editTable={false}
            load={loading}
            coloumWidthText={"Doctetss"}
            height={420}
            mandatoryColoum={"DOE"}
            showTotalBlock={true}
            DocketObligationTableSum={true}
            DocketObligationTableRightClick={true}
            setopenGeneratePaymentPlanpopup={setopenGeneratePaymentPlanpopup}
            setWrieOffpopup={setWrieOffpopup}
            setObligationData={setObligationData}
            setgeneratePaymenttogal={setgeneratePaymenttogal}
          />
        </div>
      )}
    </>
  );
};
