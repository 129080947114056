import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../components/Logout";
import { Table2 } from "../../../../components/Table2/Table2";
import { handleGetLocal } from "../../../../services/auth-service";
import "./Task.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Popup } from "devextreme-react";
import { QuickAddTaskPopup } from "../../HeaderPopupComponent/AddQuickTask/QuickAddTaskPopup";
import { Toster } from "../../../../components/Toster/Toster";
import { useSelector } from "react-redux";
import { PendingPOpup } from "./Pending";
import { setaddquickadd } from "../../../../reducer/ParticipateReducer";

const Tablecoloum = [
  {title:"", moreoption:true, customwidth:"50px"},
  {
    title: "Active",
    bool: true,
  },
  { title: "Date Entered", date: true },
  {
    title: "Due Date",
    date: true,
  },
  { title: "Days Till Due" },
  {
    title: "Assigned To",
  },
  {
    title: "Category",
  },
  {
    title: "Status",
  },
  {
    title: "Title",
  },
  {
    title: "Note",
  },
  {
    title: "Entered By",
  },
  {
    title: "Date Completed",
    date: true,
  },
];

const setTableData = (dataa) => {
  const arr = [];
  dataa.map((val, i) => {
    arr.push({
      Active: val?.active,
      "Date Entered": val?.dDoe,
      "Due Date": val?.dueDate,
      "Days Till Due": val?.daysTillDue,
      "Assigned To": val?.assignedToName,
      Category: val?.categoryName,
      Status: val?.statusName,
      Title: val?.title,
      Note: val?.note,
      "Entered By": val?.enteredBy,
      "Date Completed": val?.dateCompleted,
      ID: i,
      taskId: val?.taskId,
      assignedToId: val?.assignedToId,
      categoryId: val?.categoryId,
      statusId: val?.statusId,
    });
  });

  return arr;
};

const EditTask = ({
  setAddQuickPopup,
  addQuickPopup,
  Dropdowndata,
  errorhandling2,
  loading2,
  toastConfig,
  setToastConfig,
  userinfo,
  reset,
  setreset,
  gettaskEditDAta,
}) => {
  return (
    <>
      <Popup
        visible={gettaskEditDAta && addQuickPopup}
        onHiding={() => {
          setAddQuickPopup(false);
        }}
        showTitle={false}
        width={720}
        height={830}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <QuickAddTaskPopup
          setAddQuickPopup={setAddQuickPopup}
          errorhandling={errorhandling2}
          loading={loading2}
          Dropdowndata={Dropdowndata}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          userinfo={userinfo}
          reset={reset}
          setreset={setreset}
          gettaskEditDAta={gettaskEditDAta}
        />
      </Popup>
    </>
  );
};

const Pending = ({
  PendingPOpuptogal,
  setPendingPOpuptogal,
  setToastConfig,
  toastConfig,
  reset,
  setreset,
  gettaskEditDAta,
}) => {
  return (
    <>
      <Popup
        visible={gettaskEditDAta?.taskId && PendingPOpuptogal}
        onHiding={() => {
          setPendingPOpuptogal(false);
        }}
        showTitle={false}
        width={720}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <PendingPOpup
          setPendingPOpuptogal={setPendingPOpuptogal}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          reset={reset}
          setreset={setreset}
          gettaskEditDAta={gettaskEditDAta}
        />
      </Popup>
    </>
  );
};

export const Task = ({ userinfo }) => {
  const pathname = handleGetLocal("pathURL");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [errorhandling, seterrorhandling] = useState(false);
  const [errorhandling2, seterrorhandling2] = useState(false);
  const [data, setdata] = useState();
  const [Dropdowndata, setDropdowndata] = useState({});
  const [addQuickPopup, setAddQuickPopup] = useState(false);
  const navigate = useNavigate();
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [reset, setreset] = useState(false);
  const [PendingPOpuptogal, setPendingPOpuptogal] = useState(false);
  const [gettaskEditDAta, setgettaskEditDAta] = useState();
  const { addquickadd } = useSelector((store) => store.participate);

  const getTableData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/task/get-tasks?personId=${userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setdata(setTableData(val.data.data));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
  const getDropdown = async () => {
    setLoading2(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/task/get-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading2(false);
        if (val?.data?.isSuccess) {
          setDropdowndata(val?.data?.data);
          seterrorhandling2(false);
        } else {
          seterrorhandling2(true);
        }
      })
      .catch((val) => {
        setLoading2(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling2(true);
        }
      });
  };

  const getCompletTask = async () => {
    gettaskEditDAta?.taskId && setLoading2(true);
    gettaskEditDAta?.taskId &&
      (await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/task/complete-task?taskId=${gettaskEditDAta?.taskId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        )
        .then((val) => {
          setLoading2(false);
          getTableData();
        })
        .catch((val) => {
          setLoading2(false);
          if (!val?.response) {
            navigate(`/error/500`);
          } else if (val?.response?.status == 401) {
            logOut();
          }
        }));
  };

  useEffect(() => {
    if (userinfo?.gPersonId) {
      getTableData();
      getDropdown();
    }
  }, [userinfo, addquickadd]);

  return (
    <>
      <EditTask
        setAddQuickPopup={setAddQuickPopup}
        addQuickPopup={addQuickPopup}
        Dropdowndata={Dropdowndata}
        errorhandling2={errorhandling2}
        loading2={loading2}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        userinfo={userinfo}
        reset={reset}
        setreset={setreset}
        gettaskEditDAta={gettaskEditDAta}
      />
      <Pending
        PendingPOpuptogal={PendingPOpuptogal}
        setPendingPOpuptogal={setPendingPOpuptogal}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        reset={reset}
        gettaskEditDAta={gettaskEditDAta}
        setreset={setreset}
      />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <div style={{ height: "660px" }}>
        <div className="tasktable">
          <Table2
            Tablecoloum={Tablecoloum}
            tableRow={data}
            editTable={false}
            load={loading}
            coloumWidthText={"Taskstable"}
            height={520}
            mandatoryColoum={"Date Entered"}
            showTotalBlock={false}
            taskcontextmenu={true}
            setAddQuickPopup={setAddQuickPopup}
            setresettask={setreset}
            resettask={reset}
            setgettaskEditDAta={setgettaskEditDAta}
            setPendingPOpuptogal={setPendingPOpuptogal}
            getCompletTask={getCompletTask}
          />
        </div>
      </div>
    </>
  );
};
