import { ContextMenu, DataGrid, LoadPanel, Popup } from "devextreme-react";
import { useEffect, useState } from "react";
import { Toster } from "../../../../../components/Toster/Toster";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { FinancialFunction } from "../../../../Financial/FinancialFunction";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../../Error/ApiError";
import { Column, HeaderFilter, Lookup, Paging } from "devextreme-react/cjs/data-grid";
import "./financialtab.css";
import ThreeDotMenu from "../../../../../components/Common/Icons/ThreeDotMenu";
import AddEditObligationDetail from "./ObligationDetail/AddEditObligationDetail";
import { confirm } from "devextreme/ui/dialog";

const ObligationDetail = ({ userinfo }) => {
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const ApiClass = new FinancialFunction();
  const navigate = useNavigate();

  const [table, setTable] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [errorHandling, setErrorHandling] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});

  const [obligationDetail, setObligationDetail] = useState({});

  const [showPopup, setShowPopup] = useState(false);

  const fetchObligationDetailData = () => {
    ApiClass.populateObligationDetailData(setLoading, navigate, setTable, setErrorHandling, userinfo.gPersonId);
  };

  const getTransactionTypes = () => {
    ApiClass.populateObligationDetailTypes(navigate, setTransactionTypes);
  };

  useEffect(() => {
    fetchObligationDetailData();
    getTransactionTypes();
  }, []);

  const voidObligationDetail = (gObligationDetailId) => {
    let result = confirm("Do you really want to void this record?", "Void Obligation Detail?");
    result.then((dialogResult) => {
      if (dialogResult) {
        ApiClass.voidObligationDetail(
          setLoading,
          navigate,
          setErrorHandling,
          gObligationDetailId,
          setToastConfig,
          fetchObligationDetailData
        );
      }
    });
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <Popup
        visible={showPopup}
        onHiding={() => {
          setShowPopup(false);
        }}
        showTitle={false}
        height={550}
        width={600}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddEditObligationDetail
          obligationDetail={obligationDetail}
          setShowPopup={setShowPopup}
          modalType={"edit"}
          reloadObligationDetails={fetchObligationDetailData}
          setToastConfig={setToastConfig}
        />
      </Popup>
      <div className="financial_overview_tab">
        <div className="financial_tab_table">
          {errorHandling ? (
            <ApiError />
          ) : (
            <>
              <DataGrid
                dataSource={table}
                onContextMenuPreparing={(e) => {
                  setSelectedRow(e.row?.data);
                }}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                onCellPrepared={(e) => {
                  if (e.isAltRow) {
                    e.cellElement.style.backgroundColor = "var(--table-second-row-light-black)";
                    e.cellElement.style.color = "var(--white-black)";
                  }
                }}
                columnAutoWidth
              >
                <HeaderFilter visible={true} allowSearch />
                <Column
                  cellRender={(rowData) => {
                    return (
                      <div
                        id="threedots"
                        className="add_navBar_hamburger"
                        onClick={() => {
                          setSelectedRow(rowData?.data);
                        }}
                        style={{ margin: "-7px" }}
                      >
                        <ThreeDotMenu />
                        <ContextMenu
                          showEvent="dxclick"
                          target="#threedots"
                          dataSource={[
                            {
                              text: "Edit",
                              icon: "edit",
                            },
                            {
                              text: "Void",
                              icon: "remove",
                            },
                          ]}
                          onItemClick={(e) => {
                            if (e.itemData.text === "Edit") {
                              setObligationDetail(selectedRow);
                              setShowPopup(true);
                            }
                            if (e.itemData.text === "Void") {
                              voidObligationDetail(selectedRow?.gObligationDetailId);
                            }
                          }}
                        />
                      </div>
                    );
                  }}
                />
                <Column dataField="dDoe" dataType="date" caption="Date" />
                <Column dataField="gTransactionTypeId" caption="Type">
                  <Lookup dataSource={transactionTypes} valueExpr={"gTransactionTypeID"} displayExpr="sName" />
                </Column>
                <Column dataField="iQuantity" caption="Quantity" />
                <Column
                  dataField="fRate"
                  caption="Rate"
                  cellRender={(rowData) => {
                    return `$${rowData?.data?.fRate}`;
                  }}
                />
                <Column
                  dataField="fAmount"
                  caption="Amount"
                  cellRender={(rowData) => {
                    return `$${rowData?.data?.fAmount}`;
                  }}
                />
                <Column dataField="sFeeName" caption="Fee Name" />
                <Column dataField="sLastFirst" caption="Person Name" />
                <Column dataField="sNote" caption="Note" />
              </DataGrid>
              <ContextMenu
                target=".dx-data-row"
                dataSource={[
                  {
                    text: "Edit",
                    icon: "edit",
                  },
                  {
                    text: "Void",
                    icon: "remove",
                  },
                ]}
                onItemClick={(e) => {
                  if (e.itemData.text === "Edit") {
                    setObligationDetail(selectedRow);
                    setShowPopup(true);
                  }
                  if (e.itemData.text === "Void") {
                    voidObligationDetail(selectedRow?.gObligationDetailId);
                  }
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ObligationDetail;
