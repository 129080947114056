import { useState } from "react";
import { Table2 } from "../../../../../../components/Table2/Table2";
import { GoToSupervisorFunction } from "./GoToSupervisorFunction";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LoadPanel, TextBox } from "devextreme-react";

const SupervisionTableColumn = [{ title: "Type" }, { title: "Supervisor" }];
const SupervisionTableColumn2 = [
  { title: "Select", Selectionbool3: true },
  {
    title: "Type",
    editable: true,
    dropdown4: true,
  },
  { title: "Supervisor" },
];

export const GoToDocketSupervision = ({
  userinfo,
  dockertDetail,
  EditTogel,
  DocketSupervisionDropdowndata,
  setdata2deopdown,
  data2deopdown,
  Docketid,
  refreshsupervision,
}) => {
  const cls = new GoToSupervisorFunction();
  const [load, setLoad] = useState(false);
  const [errorhandling, seterrorhandling] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [search, setsearch] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    cls.goToSupervisorApiCall(
      setLoad,
      seterrorhandling,
      setData,
      navigate,
      dockertDetail
    );
  }, [dockertDetail?.getTopDynamicDocketid, refreshsupervision]);

  const setSupervisorData2 = (data) => {
    const arr = [];

    data.map((val, i) => {
      arr.push({
        Type: val?.Type,

        Supervisor: val?.value,
        gDocketSupervisorId: val?.gDocketSupervisorId,
        ID: val?.key,
      });
    });

    return arr;
  };
  useEffect(() => {
    if (DocketSupervisionDropdowndata?.supervisor?.length) {
      let data23 = DocketSupervisionDropdowndata?.supervisor?.map((val) => {
        for (let i = 0; i < data?.length; i++) {
          if (val?.key?.toLowerCase() == data[i]?.ID?.toLowerCase()) {
            return {
              ...val,
              Type: data[i]?.Type,
              gDocketSupervisorId: data[i]?.gDocketSupervisorId,
            };
          }
        }
        return { ...val, Type: "" };
      });
      setData2(setSupervisorData2(data23));
      setData3(setSupervisorData2(data23));
    }
  }, [DocketSupervisionDropdowndata, data]);

  function findByTypeOrSupervisor(searchTerm) {
    searchTerm = searchTerm.toLowerCase(); // convert to lowercase for case-insensitive search
    return data2.filter(
      (item) =>
        item.Type.toLowerCase().includes(searchTerm) ||
        item.Supervisor.toLowerCase().includes(searchTerm)
    );
  }
  return (
    <>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      <div style={{ marginBottom: "20px", marginTop: "10px" }}>
        {EditTogel && (
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <div>
              <TextBox
                style={{ marginTop: "3%" }}
                onValueChange={(e) => {
                  setsearch(e);
                }}
                isValid={true}
                // value={fields.firstName}
              />
            </div>
            <button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (search) {
                  setData2(findByTypeOrSupervisor(search));
                } else {
                  setData2(data3);
                }
              }}
            >
              Find
            </button>
          </div>
        )}
        {!EditTogel ? (
          <Table2
            Tablecoloum={SupervisionTableColumn}
            tableRow={data}
            editTable={false}
            load={false}
            coloumWidthText={"GoToDocketSupervision"}
            height={400}
            mandatoryColoum={"Supervisor"}
            showTotalBlock={false}
            GoToDocketSupervisiontable={true}
          />
        ) : (
          <Table2
            Tablecoloum={SupervisionTableColumn2}
            tableRow={data2}
            editTable={false}
            load={false}
            coloumWidthText={"GoToDocketSupervision"}
            height={400}
            mandatoryColoum={"Supervisor"}
            showTotalBlock={false}
            GoToDocketSupervisiontable={true}
            DocketSupervisionDropdowndata={
              DocketSupervisionDropdowndata?.supervisorType
            }
            DocketSupervisioncheckdata={data}
            setdata2deopdown={setdata2deopdown}
          />
        )}
      </div>
    </>
  );
};
