import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { removeProntactEntitiesdynamicTab } from "../../../reducer/AdminReadReducer";
import { setsetChangesavedatapopup } from "../../../reducer/ParticipateReducer";

export const ProntactEntitiesTopDynamicTab = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const {
    Detail_Notification_Tab_getData,
    Detail_Tab_Name_getData,
    Detail_Tab_address_getData,
    Detail_co_obligor_Tab_getData,
    Detail_Payment_Tab_getData,
    headerCheckBoxChange,
    GoToDocketIsChange,
    GoToobligationIsChange,
  } = useSelector((store) => store.participate);

  const { ProntactEntitiesdynamicTab, ProntactEntitieschange } = useSelector((store) => store.AdminTable);
  const dispatch = useDispatch();

  const [select, setselect] = useState(ProntactEntitiesdynamicTab[ProntactEntitiesdynamicTab.length - 1]?.Offender);

  useEffect(() => {
    setselect(ProntactEntitiesdynamicTab[ProntactEntitiesdynamicTab.length - 1]?.Offender);
    if (ProntactEntitiesdynamicTab.length == 0) {
      if (
        !Detail_Tab_Name_getData.isChange &&
        !Detail_Tab_address_getData.isChange &&
        !Detail_co_obligor_Tab_getData.isChange &&
        !Detail_Payment_Tab_getData.isChange &&
        !Detail_Notification_Tab_getData.isChange &&
        !headerCheckBoxChange &&
        !GoToobligationIsChange &&
        !GoToDocketIsChange
      ) {
        navigate("/home");
      } else {
        dispatch(setsetChangesavedatapopup(true));
      }
    } else {
      if (data !== undefined && data > ProntactEntitiesdynamicTab) {
        {
          navigate(ProntactEntitiesdynamicTab[ProntactEntitiesdynamicTab.length - 1]?.Offender);
        }
      }
    }
  }, [ProntactEntitiesdynamicTab]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="participate">
          <div className="participate_tab">
            {ProntactEntitiesdynamicTab.map((val) => {
              return (
                <>
                  <div
                    className={`participate_tab_tab_block ${val?.Offender === "/prontact/entity" && "active__"}`}
                    style={{
                      borderBottom: (() => {
                        if (select == val?.Offender) {
                          return "4px solid rgb(66, 65, 103)";
                        } else {
                          return "";
                        }
                      })(),
                    }}
                  >
                    <p
                      style={{
                        color: (() => {
                          if (select == val?.Offender) {
                            return "var(--dynamicTabHeadertext1)";
                          } else {
                            return "var(--dynamicTabHeadertext2)";
                          }
                        })(),
                        padding: "0 1px",
                        margin: "16px",
                      }}
                      className="TabName"
                      onClick={() => {
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !GoToDocketIsChange
                        ) {
                          {
                            setselect(val?.Offender);
                            navigate(val?.Offender);
                          }
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      {val.title}
                    </p>
                    <svg
                      className="svg-blue-white"
                      style={{
                        marginTop: "3px",
                        marginRight: "9px",
                        marginLeft: "-6px",
                      }}
                      width="12"
                      height="12"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !GoToDocketIsChange
                        ) {
                          dispatch(removeProntactEntitiesdynamicTab(val.Offender));
                          setData(ProntactEntitiesdynamicTab);
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      <path
                        d="M9.19656 0.807136C8.93656 0.547136 8.51656 0.547136 8.25656 0.807136L4.99656 4.06047L1.73656 0.800469C1.47656 0.540469 1.05656 0.540469 0.796562 0.800469C0.536563 1.06047 0.536563 1.48047 0.796562 1.74047L4.05656 5.00047L0.796562 8.26047C0.536563 8.52047 0.536563 8.94047 0.796562 9.20047C1.05656 9.46047 1.47656 9.46047 1.73656 9.20047L4.99656 5.94047L8.25656 9.20047C8.51656 9.46047 8.93656 9.46047 9.19656 9.20047C9.45656 8.94047 9.45656 8.52047 9.19656 8.26047L5.93656 5.00047L9.19656 1.74047C9.4499 1.48714 9.4499 1.06047 9.19656 0.807136Z"
                        fill={val?.Offender === "/prontact/entity" ? "rgb(66, 65, 103)" : "rgba(0, 0, 0, 0.5)"}
                      />
                    </svg>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
