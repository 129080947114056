import { DateBox, LoadPanel } from "devextreme-react";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./goToCasePlanNew.css";
import { Toster } from "../../../../../../components/Toster/Toster";
import { ApiError } from "../../../../../Error/ApiError";
import { GoTOCaseApiFunction } from "./GoTOCaseApiFunction";
import AccordionItem from "./AccordionItem";
import axios from "axios";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { ReportFunction } from "../../../../../Report/REportFunction";

const GoToCasePlanNew = (Docketid) => {
    const [loading, setLoading] = useState(false);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: "",
        message: "",
    });
    const apiClass = new GoTOCaseApiFunction();
    const reportFunction = new ReportFunction();
    const navigate = useNavigate();
    const [errorhandling, seterrorhandling] = useState(false);
    const [criminogenicNeeds, setCriminogenicNeeds] = useState([]);
    const [responsivityFactors, setResponsivityFactors] = useState([]);    
    const [gCriminogenicNeedID, setgCriminogenicNeedID] = useState();
    const [fields, setFields] = useState({
        gCasePlanID: "",
        overallRiskLevel: "",
        dateInitialAssessment: "",
        reassessmentDate: "",
        criminogenicNeed: [{}],
        responsivityFactor: [{}],
        responsivityFactorList: [{}],
        goals: [{}],
        actionSteps: [{}],
    });
    const [criminogenicData, setCriminogenicData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [goals, setGoals] = useState([]);
    const pathname = handleGetLocal("pathURL");
    const [EditTogel, setEditTogel] = useState(false);
    const [editAccess, setEditAccess] = useState(false);

    console.log('criminogenicData', criminogenicData)

    // handle Change Function
    const handleOverallRiskChange = useCallback((value) => {
        setFields((prev) => ({
            ...prev,
            overallRiskLevel: value,
        }));
    }, []);
    // handle for active accordion item
    const handleItemClick = (index) => {
        const item = criminogenicData[index];
        if (item.onClick) {
            item.onClick();
        } else {
            setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
        }
    };
    // handle for update check values

    // Api Function
    const getCheckboxValues = () => {
        apiClass.getCheckboxValues(setLoading, seterrorhandling, setCriminogenicNeeds, setResponsivityFactors, navigate);
    };
    const getCasePlanData = () => {
        if (Docketid.Docketid) {
            apiClass.getCasePlanData(setLoading, Docketid, seterrorhandling, setFields, navigate);
        }
    };
    const getCasePlanInterventionsData = () => {
        apiClass.getCasePlanInterventionsData(setLoading, Docketid, seterrorhandling, setCriminogenicData, navigate);
    };

    const getDistinctItems = (items) => {
        const uniqueIds = new Set();
        return items.filter((item) => {
            if (!uniqueIds.has(item.id)) {
                uniqueIds.add(item.id);
                return true;
            }
            return false;
        });
    };
    const body = {
        gCasePlanID: fields.gCasePlanID ? fields.gCasePlanID : "00000000-0000-0000-0000-000000000000",
        gEntryUserId: localStorage.getItem("gUserId"),
        gDocketID: Docketid.Docketid,
        sRiskLevel: fields.overallRiskLevel,
        dInitialAssessment: fields.dateInitialAssessment,
        dReassessment: fields.reassessmentDate,
        criminogenicNeed: fields.criminogenicNeed,
        responsivityFactors: getDistinctItems(fields.responsivityFactor),
        rFactors: fields.responsivityFactorList,
        interventions: criminogenicData,
    };

    const handleSave = (body) => {
        console.log('body', body)
        return
        apiClass.SaveCasePlan(
            setLoading,
            seterrorhandling,
            navigate,
            body,
            setToastConfig,
            toastConfig,
            getCasePlanData,
            getCasePlanInterventionsData
        );
    };

    const handleSaveNew = () => {
        console.log('body', body)
        apiClass.SaveCasePlanAll(
            setLoading,
            seterrorhandling,
            navigate,
            body,
            setToastConfig,
            toastConfig,
            getCasePlanData,
            getCasePlanInterventionsData
        );
    };

    const handlePrint = () => {
        const printPayload = [];
        printPayload.push({
            sParameterName: "CasePlanId",
            sParameterValue: fields.gCasePlanID,
        });

        reportFunction.print(
            navigator,
            setLoading,
            {
                sReportName: "CP1_CasePlanSummary.rpt",
                UserName: localStorage.getItem("username"),
                // Domain: "tci",
                param: printPayload,
            },
            setToastConfig,
            toastConfig
        );
        // axios
        //   .get(`${process.env.REACT_APP_CRYSTAL_API_URL}/reports-api/reports/get-report-CP1?val=${value}`, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        //       Domain: `${pathname}`,
        //     },
        //     responseType: "blob",
        //   })
        //   .then((val) => {
        //     setLoading(false);
        //     if (val?.data?.size > 20) {
        //       const pdfBlob = new Blob([val.data], {
        //         type: "application/pdf",
        //       });
        //       const pdfUrl = URL.createObjectURL(pdfBlob);
        //       const downloadLink = document.createElement("a");
        //       downloadLink.href = pdfUrl;
        //       downloadLink.download = "CP1_CasePlanSummary" + ".pdf";
        //       downloadLink.style.display = "none";
        //       document.body.appendChild(downloadLink);
        //       downloadLink.click();
        //       document.body.removeChild(downloadLink);
        //     }
        //   })
        //   .catch((val) => {
        //     setLoading(false);
        //   });
    };
    const print = () => {
        handlePrint();
    };

    const save = () => {
        handleSaveNew();
        setEditTogel(false);
    };

    const oncancel = () => {
        setEditTogel(false);   
      };

    const handleFactorChange = (e, index) => {
        const { name, value } = e.target;
        setFields(prevState => {
            const updatedList = [...prevState.responsivityFactorList];
            updatedList[index] = {
                ...updatedList[index],
                sFactor: value,
            };
            return {
                ...prevState,
                responsivityFactorList: updatedList,
            };
        });
    };

    const handleSolutionChange = (e, index) => {
        const { name, value } = e.target;
        setFields(prevState => {
            const updatedList = [...prevState.responsivityFactorList];
            updatedList[index] = {
                ...updatedList[index],
                sSolution: value,
            };
            return {
                ...prevState,
                responsivityFactorList: updatedList,
            };
        });
    };
    const handleDateChange = (value, index) => {
        setFields(prevState => {
            const updatedList = [...prevState.responsivityFactorList];
            updatedList[index] = {
                ...updatedList[index],
                dCompleteBy: value,
            };
            return {
                ...prevState,
                responsivityFactorList: updatedList,
            };
        });
    };

    useEffect(() => {
        getCheckboxValues();
        getCasePlanData();
        getCasePlanInterventionsData();
    }, []);
    console.log('fields', fields)
    return (
        <>
            <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
            <LoadPanel shadingColor="true" visible={loading} delay={10} />
            {errorhandling ? (
                <ApiError />
            ) : (
                <>
                    <section className="case-plan-section">
                        <div className="case-plan-container">
                            <div className="case-plan-left">
                                <div className="flex-container">
                                    <p className="main-para">Overall Risk Level:</p>
                                    <div className="flex-container">
                                        <div className="flex-div">
                                            <input
                                                type="checkbox"
                                                name="low"
                                                id="low"
                                                checked={fields.overallRiskLevel?.toLocaleLowerCase() === "low"}
                                                onChange={() => {
                                                    handleOverallRiskChange("Low");
                                                }}
                                                disabled={!EditTogel}
                                            />
                                            <label htmlFor="low" className="label">
                                                Low
                                            </label>
                                        </div>
                                        <div className="flex-div">
                                            <input
                                                type="checkbox"
                                                name="medium"
                                                id="medium"
                                                checked={fields.overallRiskLevel?.toLocaleLowerCase() === "medium"}
                                                onChange={() => {
                                                    handleOverallRiskChange("Medium");
                                                }}
                                                disabled={!EditTogel}
                                            />
                                            <label htmlFor="medium" className="label">
                                                Medium
                                            </label>
                                        </div>
                                        <div className="flex-div">
                                            <input
                                                type="checkbox"
                                                name="high"
                                                id="high"
                                                checked={fields.overallRiskLevel?.toLocaleLowerCase() === "high"}
                                                onChange={() => {
                                                    handleOverallRiskChange("High");
                                                }}
                                                disabled={!EditTogel}
                                            />
                                            <label htmlFor="high" className="label">
                                                High
                                            </label>
                                        </div>
                                        <div className="flex-div">
                                            <input
                                                type="checkbox"
                                                name="very High"
                                                id="very High"
                                                checked={fields.overallRiskLevel?.toLocaleLowerCase() === "very high"}
                                                onChange={() => {
                                                    handleOverallRiskChange("Very High");
                                                }}
                                                disabled={!EditTogel}
                                            />
                                            <label htmlFor="very High" className="label">
                                                Very High
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-field">
                                    <label htmlFor="" className="go-to-label-filed">
                                        Date of Initial Assessment
                                    </label>
                                    <DateBox
                                        useMaskBehavior={true}
                                        value={fields.dateInitialAssessment}
                                        onValueChange={(e) => {
                                            setFields((prev) => ({
                                                ...prev,
                                                dateInitialAssessment: e,
                                            }));
                                        }}
                                        disabled={!EditTogel}
                                    />
                                </div>
                                <div className="inputs-field edit-content-space">
                                    <label htmlFor="" className="go-to-label-filed">
                                        Reassessment Date
                                    </label>
                                    <DateBox
                                        useMaskBehavior={true}
                                        value={fields.reassessmentDate}
                                        onValueChange={(e) => {
                                            setFields((prev) => ({
                                                ...prev,
                                                reassessmentDate: e,
                                            }));
                                        }}
                                        disabled={!EditTogel}
                                    />
                                </div>
                            </div>
                            <div className="case-plan-right">
                                <div className="flex-container">
                                    <p className="main-para">Identified Criminogenic Needs:</p>
                                    <div className="flex-container">
                                        {criminogenicNeeds.length >= 1 &&
                                            criminogenicNeeds?.map((item, index) => {
                                                return (
                                                    <div className="flex-div" key={index}>
                                                        {/* <input
                                                            type="checkbox"
                                                            name=""
                                                            id={item.value}
                                                            checked={fields.criminogenicNeed.includes(item.key.toLowerCase())}
                                                            onClick={() => {
                                                                if (fields.criminogenicNeed.includes(item.key.toLowerCase())) {
                                                                    setFields({
                                                                        ...fields, criminogenicNeed: fields.criminogenicNeed.filter((val) => val != item.key.toLowerCase())
                                                                    })
                                                                }
                                                                else {
                                                                    setFields({ ...fields, criminogenicNeed: [...fields.criminogenicNeed, item.key.toLowerCase()] })
                                                                }
                                                            }}
                                                        /> */}
                                                        <input
                                                            type="checkbox"
                                                            name=""
                                                            id={item.value}
                                                            checked={fields?.criminogenicNeed?.some((need) => need.id === item.key.toLowerCase() && need.active)}
                                                            onClick={() => {
                                                                const updatedCriminogenicNeed = fields.criminogenicNeed.map((need) =>
                                                                    need.id === item.key.toLowerCase() ? { ...need, active: !need.active } : need
                                                                );

                                                                const itemExists = fields?.criminogenicNeed?.some((need) => need.id === item.key.toLowerCase());

                                                                if (!itemExists) {
                                                                    updatedCriminogenicNeed.push({ id: item.key.toLowerCase(), active: true });
                                                                }

                                                                setFields({ ...fields, criminogenicNeed: updatedCriminogenicNeed });
                                                            }}
                                                            disabled={!EditTogel}
                                                        />
                                                        <label htmlFor={item.value}>{item.value}</label>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div className="flex-container">
                                    <p className="main-para">Responsivity Factors:</p>
                                    <div className="flex-container">
                                        {responsivityFactors?.map((item, index) => {
                                            return (
                                                <div className="flex-div" key={index}>
                                                    {/* <input
                                                        type="checkbox"
                                                        name=""
                                                        id={item.value}
                                                        checked={fields.responsivityFactor.includes(item.key.toLowerCase())}                                                        
                                                        onChange={() => {
                                                            if (fields.responsivityFactor.includes(item.key.toLocaleLowerCase())) {
                                                                setFields({
                                                                    ...fields, responsivityFactor: fields.responsivityFactor.filter((val) => val != item.key.toLowerCase())
                                                                })
                                                            }
                                                            else {
                                                                setFields({ ...fields, responsivityFactor: [...fields.responsivityFactor, item.key.toLocaleLowerCase()] })
                                                            }

                                                        }}
                                                    /> */}
                                                    <input
                                                        type="checkbox"
                                                        name=""
                                                        id={item.value}
                                                        checked={fields?.responsivityFactor?.some((factor) => factor.id === item.key.toLowerCase() && factor.active)}
                                                        onChange={() => {
                                                            { console.log('fields inside onchange', fields) }
                                                            const updatedResponsivityFactor = fields.responsivityFactor.map((factor) =>
                                                                factor.id === item.key.toLowerCase() ? { ...factor, active: !factor.active } : factor
                                                            );
                                                            { console.log('item', item) }
                                                            { console.log('updatedResponsivityFactor', updatedResponsivityFactor) }
                                                            const itemExists = fields?.responsivityFactor?.some((factor) => factor.id === item.key.toLowerCase());
                                                            { console.log('itemExists', itemExists) }
                                                            if (!itemExists) {
                                                                updatedResponsivityFactor.push({ id: item.key.toLowerCase(), active: true });
                                                            }

                                                            setFields({ ...fields, responsivityFactor: updatedResponsivityFactor });
                                                        }}
                                                        disabled={!EditTogel}
                                                    />
                                                    <label htmlFor={item.value}>{item.value}</label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                {/* <button
                                    onClick={() => {
                                        save();
                                    }}
                                    className="btn"
                                >
                                    Save
                                </button> */}
                                {EditTogel ? (
                                    <>
                                        <div className="buttons">
                                            <button
                                                onClick={() => {
                                                    oncancel();
                                                }}
                                                className="btn"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => {
                                                    // if (fields?.["Create Date"] !== data?.[0]?.dCreate) {
                                                    //     setAlertDAteCHangepopup(true);
                                                    //     return;
                                                    // }
                                                    save();
                                                }}
                                                className="primary_btn"
                                            >
                                                Save
                                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.5 10V15C16.5 15.55 16.05 16 15.5 16H3.5C2.95 16 2.5 15.55 2.5 15V10C2.5 9.45 2.05 9 1.5 9C0.95 9 0.5 9.45 0.5 10V16C0.5 17.1 1.4 18 2.5 18H16.5C17.6 18 18.5 17.1 18.5 16V10C18.5 9.45 18.05 9 17.5 9C16.95 9 16.5 9.45 16.5 10ZM10.5 9.67L12.38 7.79C12.77 7.4 13.4 7.4 13.79 7.79C14.18 8.18 14.18 8.81 13.79 9.2L10.2 12.79C9.81 13.18 9.18 13.18 8.79 12.79L5.2 9.2C4.81 8.81 4.81 8.18 5.2 7.79C5.59 7.4 6.22 7.4 6.61 7.79L8.5 9.67V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V9.67Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <button
                                        className="primary_btn"
                                        onClick={() => {
                                            setEditTogel(true);
                                        }}
                                        // disabled={!editAccess}
                                    >
                                        Edit
                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006V5.04006Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </button>
                                )}
                                <button
                                    onClick={() => {
                                        print();
                                    }}
                                    className="btn"
                                >
                                    Print
                                    <svg
                                        className="svg-blue-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <g clipPath="url(#clip0_5985_7014)">
                                            <path
                                                d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                                                fill="#424167"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_5985_7014">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="border-bottom"></div>
                        <div className="container-data">
                            <div className="container-heading">Responsivity Factors</div>
                            <div className="multiple-columns">
                                <p>Problem</p>
                                <p>Solution</p>
                                <p>Complete By Date</p>
                            </div>
                            {fields.responsivityFactorList?.map((item, index) => {
                                console.log('fields.responsivityFactorList', fields.responsivityFactorList)
                                return (
                                    <>
                                        <div>{item.factorName}</div>
                                        <div className="container-inputs-fields" key={index}>

                                            <input
                                                type="text"
                                                value={item.sFactor}
                                                name={item.sFactor}
                                                id={item.gCaseResponsivityFactorId}
                                                // onChange={(e) => {

                                                //    // handleFactorChange(e, parentIndex, index);
                                                // }}
                                                onChange={(e) => handleFactorChange(e, index)}
                                                className="input-field"
                                                disabled={!EditTogel}
                                            />
                                            <input
                                                type="text"
                                                value={item.sSolution}
                                                name={item.sSolution}
                                                id={item.gCaseResponsivityFactorId}
                                                onChange={(e) => {
                                                    handleSolutionChange(e, index);
                                                }}
                                                className="input-field"
                                                disabled={!EditTogel}
                                            />
                                            <DateBox
                                                useMaskBehavior={true}
                                                value={item.dCompleteBy}
                                                // disabled={item.dCompleteBy}
                                                onValueChange={(value) => handleDateChange(value, index)}
                                                disabled={!EditTogel}
                                            />
                                        </div>
                                    </>

                                );
                            })}
                            <div className="border-bottom"></div>
                        </div>
                        <div className="criminogenic-section">
                            {criminogenicData &&
                                criminogenicData?.map((item, index) => (                               
                                    <AccordionItem
                                        key={index}
                                        parentIndex={index}
                                        name={item?.criminogenicNeed}
                                        isOpen={activeIndex === index}
                                        onClick={() => handleItemClick(index)}
                                        goals={item?.goals}
                                        factors={item?.factors}
                                        actionStep={item?.actionStep}
                                        criminogenicData={criminogenicData}
                                        setCriminogenicData={setCriminogenicData}
                                        setGoals={setGoals}
                                        EditTogel={EditTogel}
                                        gCriminogenicNeedID={item.gCriminogenicNeedID}
                                    />
                                ))}
                        </div>
                    </section>
                </>
            )}
        </>
    );
};

export default GoToCasePlanNew;
