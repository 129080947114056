import { useState } from "react";
import { TopDynamicTab } from "../../TopDynamicTab";
import { useSelector } from "react-redux";
import { GoTOObligationHeader } from "./GoTOObligationHeader";

export const G0ToObligation = () => {
  const { dynamicParticipatTab } = useSelector((store) => store.participate);

  return (
    <>
      <div className="particcipentPage">
        <TopDynamicTab text={dynamicParticipatTab} />
        <GoTOObligationHeader />
      </div>
    </>
  );
};
