import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { logOut } from "../../../components/Logout";
import { set_dynamicParticipatTab } from "../../../reducer/ParticipateReducer";
import { setdockerValue } from "../../../reducer/participantNoteReducer";

export class AdDocketFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async getDropdownApi(setLoading, navigate, setDropdownData, text) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=${text}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getDropDown(setLoading, navigate, settypedropdown, setOffensedropdown) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/docket-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          val?.data?.data?.docketTypes?.length &&
            settypedropdown(
              val?.data?.data?.docketTypes.map((vall) => {
                return { name: vall?.value, id: vall?.key };
              })
            );

          val?.data?.data?.offenses?.length &&
            setOffensedropdown(
              val?.data?.data?.offenses.map((vall) => {
                return {
                  name: vall?.value,
                  id: vall?.key,
                  sConvictionType: vall?.sConvictionType,
                  sOffenseType: vall?.sOffenseType,
                };
              })
            );
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async addDocket(
    setLoading,
    navigate,
    body,
    toastConfig,
    setToastConfig,
    setaddDocketPopupshow,
    dispatch,
    next,
    updateDocketManagement
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/add-docket`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setaddDocketPopupshow(false);
          if (next) {
            updateDocketManagement();
          } else {
            dispatch(
              set_dynamicParticipatTab({
                Offender: `/docket/${`${val?.data?.data?.sDocketNumber}-${val?.data?.data?.iCourtCounts}`?.replace(
                  "/",
                  "-"
                )}`,
                title: `Docket ${`${val?.data?.data?.sDocketNumber}-${val?.data?.data?.iCourtCounts}`}`,
                sDocketNumberCourtCounts: `${val?.data?.data?.sDocketNumber}-${val?.data?.data?.iCourtCounts}`,
                userinfo: {
                  ...val?.data?.data,
                  gPersonId: val?.data?.data?.gPersonId,
                },
                getTopDynamicDocketid: val?.data?.data?.gDocketId,
              })
            );
            dispatch(
              setdockerValue({
                getTopDynamicDocketid: val?.data?.data?.gDocketId,
                userinfo: {
                  ...val?.data?.data,
                  gPersonId: val?.data?.data?.gPersonId,
                },
              })
            );
            navigate(
              `/docket/${`${val?.data?.data?.sDocketNumber}-${val?.data?.data?.iCourtCounts}`?.replace(/\//g, "-")}`
            );
          }

          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });

          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Added!",
              type: "success",
            });
          }, 1400);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "DocketNumber and Count already exists!",
            type: "error",
          });

          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "DocketNumber and Count already exists!",
              type: "error",
            });
          }, 1400);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "DocketNumber and Count already exists!",
            type: "error",
          });

          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "DocketNumber and Count already exists!",
              type: "error",
            });
          }, 1400);
        }
      });
  }

  async getJugge(setLoading, id, setJudgedropdown, setfields, fields) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/judges-dropdown?entityId=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          val?.data?.data?.length
            ? setJudgedropdown(
                val?.data?.data?.map((vall) => {
                  return { name: vall?.sContactLastName, id: vall?.gContactID };
                })
              )
            : setJudgedropdown([]);

          setfields({
            ...fields,
            Judge: {
              name: val?.data?.data[0]?.sContactLastName,
              id: val?.data?.data[0]?.gContactID,
            },
          });
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          this.navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getDefaultDocketType(setLoading, navigate, setDefaultDocketTypeName) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=Default Docket Type`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setDefaultDocketTypeName(val?.data?.data);
        }
        return null;
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          this.navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
}
