import React from "react";
import { set_dynamicParticipatTab } from "../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../Error/ApiError";
import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";

export default function CookieCrumbs(props) {
  const closeWidgets = (name) => {
      return props.hideWidgets(name);
    },
    userWidget = () => {
      const element = document.getElementById("CookieCrumb");
      props.saveHeightWidth({
        name: "cookieCrumbs",
        width: element.offsetWidth,
        height: element.offsetHeight,
      });
    };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");

  const saveCookieCrum = async (perdonId) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        //console.log(val.data.data);
      })
      .catch(() => {
        console.log("kjh");
      });
  };

  return (
    <div
      id="CookieCrumb"
      className="tableWrapNew"
      style={{ width: props.width, height: props.height }}
      onMouseUp={() => userWidget()}
    >
      <div className="headingSection">
        <h3>
          <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.87 9.5C20.6 9 20 9 20 9H18V8C18 7 17 7 17 7H15V6C15 5 14 5 14 5H13V3C13 2 12 2 12 2C7.03 2 3 6.03 3 11C3 15.97 7.03 20 12 20C16.97 20 21 15.97 21 11C21 10.5 20.96 10 20.87 9.5ZM6.5 12C5.67 12 5 11.33 5 10.5C5 9.67 5.67 9 6.5 9C7.33 9 8 9.67 8 10.5C8 11.33 7.33 12 6.5 12ZM8 6.5C8 5.67 8.67 5 9.5 5C10.33 5 11 5.67 11 6.5C11 7.33 10.33 8 9.5 8C8.67 8 8 7.33 8 6.5ZM11 18C10.17 18 9.5 17.33 9.5 16.5C9.5 15.67 10.17 15 11 15C11.83 15 12.5 15.67 12.5 16.5C12.5 17.33 11.83 18 11 18ZM11.5 13C10.67 13 10 12.33 10 11.5C10 10.67 10.67 10 11.5 10C12.33 10 13 10.67 13 11.5C13 12.33 12.33 13 11.5 13ZM16.5 15C15.67 15 15 14.33 15 13.5C15 12.67 15.67 12 16.5 12C17.33 12 18 12.67 18 13.5C18 14.33 17.33 15 16.5 15ZM7 22H9V24H7V22ZM11 22H13V24H11V22ZM15 22H17V24H15V22Z"
              fill="white"
            />
          </svg>
          <em>Cookie Crumbs</em>
        </h3>
        <div className="rightSide widget_head">
          <ul>
            <li>
              <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1174_385365)">
                  <path
                    d="M8.25 13.5C8.25 14.325 7.575 15 6.75 15C5.925 15 5.25 14.325 5.25 13.5C5.25 12.675 5.925 12 6.75 12C7.575 12 8.25 12.675 8.25 13.5ZM6.75 7.5C5.925 7.5 5.25 8.175 5.25 9C5.25 9.825 5.925 10.5 6.75 10.5C7.575 10.5 8.25 9.825 8.25 9C8.25 8.175 7.575 7.5 6.75 7.5ZM6.75 3C5.925 3 5.25 3.675 5.25 4.5C5.25 5.325 5.925 6 6.75 6C7.575 6 8.25 5.325 8.25 4.5C8.25 3.675 7.575 3 6.75 3ZM11.25 6C12.075 6 12.75 5.325 12.75 4.5C12.75 3.675 12.075 3 11.25 3C10.425 3 9.75 3.675 9.75 4.5C9.75 5.325 10.425 6 11.25 6ZM11.25 7.5C10.425 7.5 9.75 8.175 9.75 9C9.75 9.825 10.425 10.5 11.25 10.5C12.075 10.5 12.75 9.825 12.75 9C12.75 8.175 12.075 7.5 11.25 7.5ZM11.25 12C10.425 12 9.75 12.675 9.75 13.5C9.75 14.325 10.425 15 11.25 15C12.075 15 12.75 14.325 12.75 13.5C12.75 12.675 12.075 12 11.25 12Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1174_385365">
                    <rect width={18} height={18} fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </li>
            <li>
              <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.2409 2.76249C9.01837 1.53999 7.28587 0.834994 5.38087 1.02999C2.62837 1.30749 0.363373 3.54249 0.0558729 6.29499C-0.356627 9.93249 2.45587 13 6.00337 13C8.39587 13 10.4509 11.5975 11.4109 9.57999C11.6509 9.07749 11.2909 8.49999 10.7359 8.49999C10.4584 8.49999 10.1959 8.64999 10.0759 8.89749C9.22837 10.72 7.19587 11.875 4.97587 11.38C3.31087 11.0125 1.96837 9.65499 1.61587 7.98999C0.985873 5.07999 3.19837 2.49999 6.00337 2.49999C7.24837 2.49999 8.35837 3.01749 9.16837 3.83499L8.03587 4.96749C7.56337 5.43999 7.89337 6.24999 8.56087 6.24999H11.2534C11.6659 6.24999 12.0034 5.91249 12.0034 5.49999V2.80749C12.0034 2.13999 11.1934 1.80249 10.7209 2.27499L10.2409 2.76249Z"
                  fill="white"
                />
              </svg>
            </li>
            <li onClick={() => closeWidgets("cookieCrumbs")}>
              <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.2987 0.709971C12.9087 0.319971 12.2787 0.319971 11.8887 0.709971L6.99875 5.58997L2.10875 0.699971C1.71875 0.309971 1.08875 0.309971 0.69875 0.699971C0.30875 1.08997 0.30875 1.71997 0.69875 2.10997L5.58875 6.99997L0.69875 11.89C0.30875 12.28 0.30875 12.91 0.69875 13.3C1.08875 13.69 1.71875 13.69 2.10875 13.3L6.99875 8.40997L11.8887 13.3C12.2787 13.69 12.9087 13.69 13.2987 13.3C13.6887 12.91 13.6887 12.28 13.2987 11.89L8.40875 6.99997L13.2987 2.10997C13.6787 1.72997 13.6787 1.08997 13.2987 0.709971Z"
                  fill="white"
                  fillOpacity={1}
                />
              </svg>
            </li>
          </ul>
        </div>
      </div>
      {!props.errorHandling ? (
        <table>
          <thead>
            <tr>
              <th>Document</th>
              <th>
                <div className="link">
                  Last Access
                  <svg width={6} height={4} viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.807026 1.80663L2.53369 3.53329C2.79369 3.79329 3.21369 3.79329 3.47369 3.53329L5.20036 1.80663C5.62036 1.38663 5.32036 0.666626 4.72703 0.666626H1.27369C0.68036 0.666626 0.387027 1.38663 0.807026 1.80663Z"
                      fill="black"
                      fillOpacity="0.6"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {props.CookieCrumData.map((val) => {
              return (
                <tr key={val.sDocument}>
                  <td
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => {
                      saveCookieCrum(val?.gRecordId);
                      if(val?.sCategory === 'OBLIGATION_TYPE') {
                        let route = val?.sDocument?.split(":")[1].replace(/ /g,'');
                        navigate(`/obligation/${route}`);
                      }
                      if(val?.sCategory === 'DOCKET_TYPE'){
                        const croppedString = val?.sDocument?.split(" ")[1];
                        navigate(`/docket/${croppedString}`);
                      }
                      if(val?.sCategory === "PERSON_TYPE") {
                        let name = val?.sDocument?.split(":")[1]?.split("(")[0]?.split(",")[0];
                        let len = val?.sDocument?.split(":")[1]?.split(",")[1]?.split("(");
                        let id = len?.[len?.length - 1]?.replace(/\)/g, "");
                        if (!id) {
                          return;
                        }
                        dispatch(
                          set_dynamicParticipatTab({
                            Offender: id,
                            title: name,
                          })
                        );
                        navigate(`/participant/${id}`);
                      }
                    }}
                  >
                    {val.sDocument}
                  </td>
                  <td>
                    {new Date(val.dDoe)
                      .toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                      .replace(/\//g, "/")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <ApiError />
      )}
    </div>
  );
}
