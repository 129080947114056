export const Tablecoloum = [
  { title: " ", moreoption: true },
  { title: "Select", bool: true, DocketManagementSelect: true },
  {
    title: "Active",
    bool: true,
  },
  { title: "Date Entered", date: true },
  {
    title: "Due Date",
    date: true,
  },
  { title: "Days Till Due" },
  {
    title: "Assigned To",
  },
  {
    title: "Category",
  },
  {
    title: "Status",
  },
  {
    title: "Title",
  },
  {
    title: "Note",
  },
  {
    title: "Entered By",
  },
  {
    title: "Date Completed",
    date: true,
  },
];

export const setTableData = (dataa) => {
  const arr = [];
  dataa.map((val, i) => {
    let match = val?.sLastFirst?.match(/\((.*?)\)/);
    let name = val?.sLastFirst?.match(/([^()])+|(\(.*\))/g);
    arr.push({
      Select: false,
      Active: val?.active,
      "Date Entered": val?.dDoe,
      "Due Date": val?.dueDate,
      "Days Till Due": val?.daysTillDue,
      "Assigned To": val?.assignedToName,
      Category: val?.categoryName,
      Status: val?.statusName,
      Title: val?.title,
      Note: val?.note,
      "Entered By": val?.enteredBy,
      "Date Completed": val?.dateCompleted,
      ID: val?.taskId,
      taskId: val?.taskId,
      assignedToId: val?.assignedToId,
      categoryId: val?.categoryId,
      statusId: val?.statusId,
      offenderNumber: match ? match[1] : "",
      name: name ? name[0] : "",
    });
  });

  return arr;
};
