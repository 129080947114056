import { LoadPanel, Popup } from "devextreme-react";
import { Table2 } from "../../../../../components/Table2/Table2";
import { QuickAddContect } from "./QuickAddContect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProntactEntityFunction } from "../../ProntactEntityFunction";
import { useNavigate } from "react-router-dom";
import { setcontectTableAddDataaa2 } from "../../../../../reducer/ParticipateReducer";

const Tablecoloum = [
  {
    title: "Contact",
    dropdown2: true,
    editable: true,
    requiredColoumWhileEdit: true,
  },
  {
    title: "Position",
    requiredColoumWhileEdit: true,
    dropdown3: true,
    editable: true,
  },
  {
    title: "Active",

    bool: true,
    editable: true,
  },
  { title: "Start", date: true, editable: true },
  {
    title: "End",
    date: true,
    editable: true,
  },
  {
    title: "Contact Information",
  },
  {
    title: "Contact Type",
  },
];

const QuickAddContectpopup = ({
  setQuickAddContectpopupshow,
  QuickAddContectpopupshow,
  getDRopDown,
}) => {
  return (
    <>
      <Popup
        visible={QuickAddContectpopupshow}
        onHiding={() => {
          setQuickAddContectpopupshow(false);
        }}
        showTitle={false}
        width={560}
        height={310}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <QuickAddContect
          QuickAddContectpopupshow={QuickAddContectpopupshow}
          setQuickAddContectpopupshow={setQuickAddContectpopupshow}
          getDRopDown={getDRopDown}
        />
      </Popup>
    </>
  );
};

const setTableDataa = (dataa) => {
  const arr = [];
  dataa.map((val) => {
    arr.push({
      Contact: val?.Contact,
      Position: val?.Position,
      Active: val?.Active,
      Start: val?.Start,
      End: val?.End,
      "Contact Information": val?.["Contact Information"],
      "Contact Type": val?.["Contact Type"],
      ID: val?.ID,
      gEntityContactId: val?.gEntityContactId,
    });
  });

  return arr;
};

export const ProntactEntitiesSubTabContectLIst = ({
  ContactPositiondropdown,
  Contactdropdown,
  getDRopDown,
  EditTogel,
  addProntactEntities,
  Entitiesid,
  data,
  setdata,
  setloading,
  loading,
}) => {
  const [QuickAddContectpopupshow, setQuickAddContectpopupshow] =
    useState(false);
  const [tabledata, newrowData] = useState({ data: [], name: "" });
  const dispatch = useDispatch();
  const clas = new ProntactEntityFunction();

  const { contectTableAddData2 } = useSelector((store) => store.participate);

  useEffect(() => {
    setdata(setTableDataa(contectTableAddData2));
  }, [contectTableAddData2]);

  return (
    <>
      <QuickAddContectpopup
        setQuickAddContectpopupshow={setQuickAddContectpopupshow}
        QuickAddContectpopupshow={QuickAddContectpopupshow}
        getDRopDown={getDRopDown}
      />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <button
        onClick={() => {
          (addProntactEntities || EditTogel) &&
            setQuickAddContectpopupshow(true);
        }}
        style={{
          margin: "1%",
        }}
        className="btn"
      >
        Quick Add Contact
      </button>
      <Table2
        Tablecoloum={Tablecoloum}
        tableRow={data}
        load={false}
        coloumWidthText={"ProntactEntitiesSubTabContectLIstTab"}
        height={300}
        mandatoryColoum={"Contact"}
        showTotalBlock={false}
        newrowData={newrowData}
        tabledata={tabledata}
        editParticipant={false}
        editTable={(addProntactEntities || EditTogel) && true}
        ContactPositiondropdown={ContactPositiondropdown}
        Contactdropdown={Contactdropdown}
        ProntactEntitiesSubTabContectLIst={true}
      />
    </>
  );
};
