import { DateBox, LoadPanel, ScrollView, TextBox } from "devextreme-react";
import { Fragment, useEffect, useState } from "react";
import { ReportFunction } from "../REportFunction";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { logOut } from "../../../components/Logout";
import { Toster } from "../../../components/Toster/Toster";
import DropDownDataGrid from "./DropDownDataGrid";

export const SelectReportCriteria = ({
  SelectReportCriteriaPopupopen,
  setSelectReportCriteriaPopupopen,
  data,
  sReportPathname,
  sReportName,
  startDate,
  endDate,
  userName,
}) => {
  const [fields, setFields] = useState({});
  const [dropdown, setDropdown] = useState({});
  const [array, setArray] = useState({});
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();
  const reportFunction = new ReportFunction();

  const getCourtValues = async (sParameterType, sParameterName, initialFields) => {
    setLoading(true);
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/ReportDashboard/get-court-values`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: handleGetLocal("pathURL"),
          },
        }
      );
      setLoading(false);
      const courtValues = response?.data?.map((val) => ({
        EntityName: val?.sEntityName,
        id: val?.id,
        CompanyName: val?.sCompanyName,
        RegionName: val?.sRegionName,
      }));
      setDropdown((prev) => ({ ...prev, [sParameterType]: courtValues }));
      if (initialFields["UserName"]) {
        setFields((prev) => ({
          ...prev,
          [sParameterName]: courtValues.map((val) => val?.id),
          UserName: localStorage.getItem("username"),
        }));
      } else {
        setFields((prev) => ({
          ...prev,
          [sParameterName]: courtValues.map((val) => val?.id),
        }));
      }

      setArray((prev) => ({ ...prev, [sParameterName]: courtValues }));
    } catch (error) {
      setLoading(false);
      handleErrorResponse(error);
    }
  };

  const getParameterValues = async (sParameterType, sParameterName, initialFields) => {
    if (sParameterType === "UserId" || sParameterName === "UserId") return;
    setLoading(true);
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/ReportDashboard/get-parameter-values?parameterName=${sParameterType}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: handleGetLocal("pathURL"),
          },
        }
      );
      setLoading(false);
      const parameterData = response?.data;
      const dropdownValues = parameterData?.map((item) => ({
        name: item?.sParameterValue,
        id: item?.gParameterID,
      }));
      const parameterIds = parameterData?.map((val) => val?.gParameterID);
      setDropdown((prev) => ({ ...prev, [sParameterType]: dropdownValues }));
      if (initialFields["UserName"]) {
        setFields((prev) => {
          return {
            ...prev,
            [sParameterName]: parameterIds,
            UserName: localStorage.getItem("username"),
          };
        });
      } else {
        setFields((prev) => {
          return { ...prev, [sParameterName]: parameterIds };
        });
      }
      setArray((prev) => ({ ...prev, [sParameterName]: parameterData }));
    } catch (error) {
      setLoading(false);
      handleErrorResponse(error);
    }
  };

  const handleErrorResponse = (error) => {
    if (!error?.response) {
      navigate(`/error/500`);
    } else if (error?.response?.status === 401) {
      logOut();
    }
  };

  const getDate = (option) => {
    const currentDate = new Date();
    switch (option) {
      case "%SOCM":
        currentDate.setDate(1);
        break;
      case "%EOCM":
        currentDate.setMonth(currentDate.getMonth() + 1, 0);
        break;
      case "%SOCY":
        currentDate.setMonth(0, 1);
        break;
      case "%EOCY":
        currentDate.setMonth(12, 0);
        break;
      default:
        break;
    }
    return currentDate.toISOString().split("T")[0];
  };

  const initializeDropdowns = async () => {
    const initialFields = {};
    const initialArray = {};

    setFields({});

    data?.forEach((parameter) => {
      if (parameter?.sParameterType.toLowerCase().includes("boolean")) {
        initialFields[parameter?.sParameterName] = false;
      } else {
        initialFields[parameter?.sParameterName] = "";
      }
      if (parameter?.sParameterType.toLowerCase().includes("date")) {
        initialFields[parameter?.sParameterName] = getDate(parameter?.sDefaultValue);
      }
      if (parameter?.sParameterName.toLowerCase().includes("username")) {
        initialFields[parameter?.sParameterName] = localStorage.getItem("username");
      }
    });
    setFields((prev) => ({ ...prev, ...initialFields }));
    setArray(initialArray);

    data?.map((parameter) => {
      const { sParameterType, sParameterName, sDefaultValue, sCaption } = parameter;
      if (sParameterType?.toLowerCase().includes("boolean")) return;
      if (sParameterType.toLowerCase().includes("date")) {
        setFields((prev) => ({ ...prev, [sParameterName]: getDate(sDefaultValue) }));
      } else if (sParameterType !== "Court") {
        getParameterValues(sParameterType, sParameterName, initialFields);
      } else {
        getCourtValues(sParameterType, sParameterName, initialFields);
      }
    });
  };

  useEffect(() => {
    setDropdown({});
    initializeDropdowns();
  }, [data]);

  useEffect(() => {
    setDropdown({});
  }, [SelectReportCriteriaPopupopen]);

  const getPrintParameterValue = (name, values) => {
    if (!values?.length) return "";
    if (typeof values === "string") return values;
    const filteredArray = array[name]?.filter((parameter) => {
      return values?.includes(parameter.gParameterID || parameter.id);
    });
    if (name === "CourtName") {
      return filteredArray?.map((parameter) => parameter.EntityName).join("|");
    } else if (name === "UserName") {
      return localStorage.getItem("username");
    }
    return filteredArray?.map((parameter) => parameter.sParameterValue).join("|");
  };
  const printReport = () => {
    const printPayload = Object.entries(fields).map(([key, value]) => ({
      sParameterName: key,
      sParameterValue: getPrintParameterValue(key, value),
    }));

    if (printPayload.length) {
      // printPayload.push({
      //   sParameterName: "UserId",
      //   sParameterValue: localStorage.getItem("username"),
      // });

      reportFunction.print(
        navigate,
        setLoading,
        {
          sReportName: sReportPathname,
          //sUserId: localStorage.getItem("username"),
          param: printPayload,
        },
        setToastConfig,
        toastConfig
      );
    }
  };

  const getDataGridSource = (sParameterType) => {
    if (dropdown?.[sParameterType]) {
      if (sParameterType !== "Court") {
        return dropdown[sParameterType]?.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      }
      return dropdown[sParameterType]?.map((item) => ({
        value: item.id,
        Entity: item.EntityName,
        Region: item.RegionName,
        Company: item.CompanyName,
      }));
    }
  };
  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div style={{ height: "698px" }} className="notes_edit_popup">
        <div className="RecivePaymentModel_header">
          <div style={{ width: "210px" }} className="RecivePaymentModel_header_name">
            <p>Select Report Criteria</p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                CR.8
              </p>
            </span>
          </div>
          <svg
            onClick={() => setSelectReportCriteriaPopupopen(false)}
            style={{ cursor: "pointer", marginTop: "17px", marginRight: "2%" }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ width: "100%", height: "643px" }}>
          <div className="editTable_notes">
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button onClick={printReport} className="btn">
                Print
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5985_7014)">
                    <path
                      d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5985_7014">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <p>{sReportName}</p>
            </div>
            <div>
              {data?.length > 0 &&
                data
                  .filter((parameter) => parameter?.sCaption !== "UserId" && parameter?.sParameterName !== "UserId")
                  .sort((A, B) => A.iParameterSort - B.iParameterSort)
                  .map((parameter, index) => (
                    <Fragment key={index}>
                      <div style={{ marginTop: "3%" }}>
                        <p style={{ fontWeight: "bold" }}>{parameter?.sCaption}</p>
                        {parameter?.sParameterType.toLowerCase().includes("date") ? (
                          <DateBox
                            style={{ width: "60%", marginTop: "2%" }}
                            value={fields[parameter?.sParameterName]}
                            onValueChange={(e) => {
                              const initialDate = new Date(e);
                              const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                              const utcFormatted = new Date(utcTime).toISOString().split("T")[0];
                              setFields((prev) => ({ ...prev, [parameter?.sParameterName]: e ? utcFormatted : null }));
                            }}
                            useMaskBehavior={true}
                          />
                        ) : parameter?.sParameterName.toLowerCase().includes("username") ? (
                          <TextBox
                            disabled={true}
                            value={localStorage.getItem("username")}
                            // onValueChange={(e) => setFields((prev) => ({ ...prev, [parameter?.sParameterName]: e }))}
                          />
                        ) : parameter?.sParameterType.toLowerCase().includes("text") ? (
                          <TextBox
                            value={fields[parameter?.sParameterName]}
                            onValueChange={(e) => setFields((prev) => ({ ...prev, [parameter?.sParameterName]: e }))}
                          />
                        ) : !parameter?.sParameterType.toLowerCase().includes("boolean") ? (
                          <DropDownDataGrid
                            gridDataSource={getDataGridSource(parameter?.sParameterType)}
                            initialKeys={fields[parameter?.sParameterName]}
                            setValue={(result) =>
                              setFields((prev) => ({ ...prev, [parameter?.sParameterName]: result }))
                            }
                          />
                        ) : (
                          <input
                            checked={fields[parameter?.sParameterName] ? true : false}
                            onChange={() =>
                              setFields((prev) => ({
                                ...prev,
                                [parameter?.sParameterName]: !fields[parameter?.sParameterName],
                              }))
                            }
                            type="checkbox"
                          />
                        )}
                      </div>
                    </Fragment>
                  ))}
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
