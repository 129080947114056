
import { logOut } from "../../components/Logout";
import { handleGetLocal } from "../../services/auth-service";
import axios from "axios";

const pathname = handleGetLocal("pathURL");

export const populateObligationDetailTypes = async (navigate) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/obligations/populate-obligation-detail-types`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${window?.location?.pathname}`, // Use window.location.pathname for the current path
        },
      }
    );
    console.log('Response Data:', response);
    return response?.data;
  } catch (error) {
    if (!error?.response) {
      navigate(`/error/500`);
    } else if (error?.response?.status === 401) {
      logOut();
    }
    throw error;
  }
};



// export const addEditObligationDetail = async (navigate, body) => {
//     await axios
//       .post(`${process.env.REACT_APP_API_URL}/api/obligations/add-edit-obligation-detail`, body, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//           Domain: `${this.pathname}`,
//         },
//       })
//       .then((val) => {
//         console.log('addEditObligationDetail', val);
//       })
//       .catch((error) => {
//         if (!error?.response) {
//           navigate(`/error/500`);
//         } else if (error?.response?.status === 401) {
//           logOut();
//         } 
//       });
//   }

  export const addEditObligationDetail = async (navigate, body) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/obligations/add-edit-obligation-detail`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${window.location.pathname}`,
          },
        }
      );
      return response.data; // Ensure this line returns the data correctly
    } catch (error) {
      if (!error.response) {
        navigate(`/error/500`);
      } else if (error.response.status === 401) {
        logOut();
      }
      throw error; // Ensure the error is thrown to be caught in the component
    }
  };