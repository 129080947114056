import React, { useEffect, useState } from "react";
import "./personlist.css";
import { Table2 } from "../../components/Table2/Table2";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { Popup } from "devextreme-react/popup";
import { Tablecoloum, Tablecoloumemtrue } from "./Data";
import { AddFilterPopup } from "../../components/EditfilterPopup.js/AddFilterPopup";
import { setTableData, PersonListBody } from "./Functions";
import { logOut } from "../../components/Logout";
import { useNavigate } from "react-router-dom";
import { SendEmail } from "./SendEmail";
import { Toster } from "../../components/Toster/Toster";

export const PersonList = () => {
  const pathname = handleGetLocal("pathURL");
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [getCustomFilterValue, setgetCustomFilterValue] = useState([]);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [sendEmailPopup, setSendEmailPopup] = useState(false);
  const [selectedPersonIds, setSelectedPersonIds] = useState([]);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const navigate = useNavigate();

  const getPersonList = async () => {
    setloading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Person/personListByAllFilters`,
        PersonListBody(getCustomFilterValue),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setdata(setTableData(val.data.data));
        setloading(false);
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const showSendEmailPopup = () => {
    if (selectedPersonIds?.length) {
      setSendEmailPopup(true);
    } else {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "No Person Selected!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "No Person Selected!",
          type: "error",
        });
      }, 1900);
    }
  };

  useEffect(() => {
    getPersonList();
  }, [getCustomFilterValue]);

  return (
    <div>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        showTitle={false}
        width={700}
        height={400}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddFilterPopup
          setIsPopupVisible={setIsPopupVisible}
          Tablecoloum={localStorage.getItem("emperiod") == "true" ? Tablecoloumemtrue : Tablecoloum}
          setgetCustomFilterValue={setgetCustomFilterValue}
        />
      </Popup>

      <Popup
        visible={sendEmailPopup}
        onHiding={() => setSendEmailPopup(false)}
        showTitle={false}
        width={1000}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SendEmail setSendEmailPopup={setSendEmailPopup} personIds={selectedPersonIds} />
      </Popup>

      <div className="personMainTabWrapper">
        <div className="tabwapper">
          <DynamicTabb />
          <div className="tab-main-box">
            <div className="tab-box" id="tab-1">
              <p>Participants tab content/design will be here</p>
            </div>

            <div className="tab-box" id="tab-2" style={{ display: "block" }}>
              <div className="tabcontent">
                <div className="screen_num">
                  <p>PS.3</p>
                </div>
                <div className="interaction flex">
                  <div className="leftAction leftAlign">
                    <button
                      onClick={() => {
                        setexportPopupOpen(true);
                      }}
                      id="myBtn"
                      className="btn btnlightblue"
                    >
                      Export
                      <svg
                        className="svg-blue-white"
                        width="14"
                        height="17"
                        viewBox="0 0 14 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                          fill="#424167"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        getPersonList();
                      }}
                      id="myBtn"
                      className="btn btnlightblue"
                    >
                      Refresh
                      <svg
                        className="svg-blue-white"
                        width="16"
                        height="21"
                        viewBox="0 0 16 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.99905 4.00003V1.21003C7.99905 0.760031 7.45905 0.540031 7.14905 0.860031L3.34905 4.65003C3.14905 4.85003 3.14905 5.16003 3.34905 5.36003L7.13905 9.15003C7.45905 9.46003 7.99905 9.24003 7.99905 8.79003V6.00003C11.7291 6.00003 14.6791 9.42003 13.8591 13.29C13.3891 15.56 11.5491 17.39 9.28905 17.86C5.71905 18.61 2.53905 16.16 2.05905 12.85C1.98905 12.37 1.56905 12 1.07905 12C0.479051 12 -0.000948563 12.53 0.0790514 13.13C0.699051 17.52 4.87905 20.77 9.60905 19.85C12.7291 19.24 15.2391 16.73 15.8491 13.61C16.8391 8.48003 12.9391 4.00003 7.99905 4.00003Z"
                          fill="#424167"
                        />
                      </svg>
                    </button>
                    <button
                      id="myBtn"
                      onClick={() => {
                        setIsPopupVisible(!isPopupVisible);
                      }}
                      style={{
                        color: getCustomFilterValue.length >= 1 && "white",
                      }}
                      className={getCustomFilterValue.length >= 1 ? "btn blue" : "btn btnlightblue"}
                    >
                      {getCustomFilterValue.length >= 1 ? "Edit Filter" : "Add Filter"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="Detail_Contact_Tab">
              <div style={{ marginTop: "1.5%" }}>
                <Table2
                  Tablecoloum={localStorage.getItem("emperiod") == "true" ? Tablecoloumemtrue : Tablecoloum}
                  tableRow={data}
                  load={loading}
                  coloumWidthText={"PersonListTableWidth"}
                  height={620}
                  editTable={false}
                  mandatoryColoum={"Offender Number"}
                  showTotalBlock={false}
                  exportInDifferentFormate={true}
                  exportPopupOpen={exportPopupOpen}
                  setexportPopupOpen={setexportPopupOpen}
                  exportwidth={17.4}
                  exporttitle={"person list"}
                  selectedPersonIds={selectedPersonIds}
                  setSelectedPersonIds={setSelectedPersonIds}
                  showSendEmailPopup={showSendEmailPopup}
                  personListControl={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
