import axios from "axios";
import { logOut } from "../../components/Logout";
import { handleGetLocal } from "../../services/auth-service";
import { setDisbursementCheckObligationlistTableData, setDisbursementChecklistTableData } from "./Data";

export class FinancialFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async getPreDisbursementsReport(setLoading, seterrorhandler, navigate, setData, date) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/disbursement/get-pre-disbursement?dEffective=${date}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.getPreDisbursementsReportTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }
  getPreDisbursementsReportTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "Recipient Category": val?.sRecipientCategoryName,
        "Court Name": val?.sCourtName,
        "Disburse Frequency": val?.sDisburseFrequencyName,
        Alert: val?.bPaymentAlert,
        Amount: val?.fAmountToDisburse,
        "Added Disbursement": val?.fDisbursementsToAdd,
        "Sub'd Payment": val?.fPaymentsToSubtract,
        "Bank Acct": val?.sBankAccountName,
        "Recipient Name": val?.sRecipientDisbursementName,
        Address: val?.bAddress,
        "Offender #": val?.sOffenderNumber,
        "Person Name": val?.sLastFirst,
        "Fee Code": val?.sDDSLegalCode,
        "Fee Name": val?.sDDSViolationDescription,
        "Person Status": val?.sPersonSupervisionStatusName,
        gPersonID: val?.gPersonID,
        sReference: val?.sReference,
        userinfo: val,
        gObligationID: val?.gObligationID,
        bSelect: val?.bSelect,
        ID: i,
      });
    });
    return arr;
  };
  async getDisbursementChecklistData(
    setLoading,
    navigate,
    setDisbursementCheckData,
    setDisbursementErrorHandling,
    date
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/disbursement/get-disbursement-checklist?dStart=${date.startDate}&dEnd=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setDisbursementCheckData(setDisbursementChecklistTableData(val?.data?.data));
        } else {
          setDisbursementErrorHandling(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setDisbursementErrorHandling(true);
        }
      });
  }
  async getDisbursementCheckObligationlistData(
    setLoading,
    navigate,
    setDisbursementObligationListData,
    setDisbursementErrorHandling,
    date
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/disbursement/get-disbursement-obligationlist?dStart=${date.startDate}&dEnd=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setDisbursementObligationListData(setDisbursementCheckObligationlistTableData(val?.data?.data));
        } else {
          setDisbursementErrorHandling(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setDisbursementErrorHandling(true);
        }
      });
  }

  async populateObligationDetailData(setLoading, navigate, setData, setErrorHandling, gPersonId) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/populate-obligation-detail-data?gPersonId=${gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setData(val?.data?.data);
        } else {
          setErrorHandling(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorHandling(true);
        }
      });
  }

  async populateObligationDetailTypes(navigate, setData) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/populate-obligation-detail-types`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setData(val?.data?.data);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  }

  async addEditObligationDetail(navigate, setToastConfig, body, reloadApi, setShowPopup) {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/add-edit-obligation-detail`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess === false) {
          setToastConfig({
            isVisible: true,
            type: "error",
            message: "Unknown Error!",
          });
          setTimeout(
            () =>
              setToastConfig({
                isVisible: false,
                type: "error",
                message: "Unknown Error!",
              }),
            1000
          );
        } else {
          setToastConfig({
            isVisible: true,
            type: "success",
            message: "Accrual Updated!",
          });
          setTimeout(
            () =>
              setToastConfig({
                isVisible: false,
                type: "success",
                message: "Accrual Updated!",
              }),
            1000
          );
          if (reloadApi) reloadApi();
          if (setShowPopup) setShowPopup(false);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setToastConfig({
            isVisible: true,
            type: "error",
            message: "Network Error!",
          });
          setTimeout(
            () =>
              setToastConfig({
                isVisible: false,
                type: "error",
                message: "Network Error!",
              }),
            1000
          );
        }
      });
  }

  async voidObligationDetail(
    setLoading,
    navigate,
    setErrorHandling,
    gObligationDetailId,
    setToastConfig,
    fetchObligationDetailData
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/obligations/void-obligation-detail?gObligationDetailId=${gObligationDetailId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setToastConfig({
            isVisible: true,
            type: "success",
            message: "Accrual Voided!",
          });
          setTimeout(
            () =>
              setToastConfig({
                isVisible: false,
                type: "success",
                message: "Accrual Voided!",
              }),
            1000
          );
          fetchObligationDetailData();
        } else {
          setErrorHandling(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorHandling(true);
        }
      });
  }
}
