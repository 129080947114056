import React, { useState } from "react";
import AdminTabs from "./components/AdminTabs";
import "./style/userList.css";
import AdminCrumbs from "./components/AdminCrumbs";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../services/auth-service";
import httpService from "../../services/http-service";
import { useEffect } from "react";
import { Table1 } from "./Table1/Table1";
import { setAdmionTabs } from "../../reducer/AdminReadReducer";
import { useDispatch } from "react-redux";
import { Tablecoloumn } from "./Data";
import { logOut } from "../../components/Logout";
import { ApiError } from "../Error/ApiError";

const setTableData = (data) => {
  const arr = [];
  data.map((val) => {
    arr.push({
      "User ID": val.sUserId,
      Name: val?.sFirstName + ' ' + val?.sLastName,
      Role: val.roleName,
      "Employee ID": val.sEmployeeId,
      Active: val.bActive,
      Resource: val.bResource,
      Email: val.sEmail,
      "Default Location": val.defaultLocation,
      GuserID: val.gUserId,
    });
  });
  return arr;
};

const UserList = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [userDataErrorHandling, setUserDataErrorHandling] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const title = "User List";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let domainName = (url) =>
    url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^.]+)/i)[1];
  let domailURL = window.location.href;
  let pathURL = domainName(domailURL);
  const token = getAuthToken();

  localStorage.removeItem("entitiesList");
  localStorage.removeItem("groupsList");

  useEffect(() => {
    userList();
  }, []);

  const userList = async () => {
    setLoad(true);
    await httpService
      .get("api/User/GetUserDetailList", {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          localStorage.removeItem("adminbasicediterTable");
          setData(setTableData(val?.data.data));
          setLoad(false);
        } else {
          setUserDataErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setUserDataErrorHandling(true);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("user_designation") !== "Administrator") {
      navigator("/");
    }
  }, []);

  return (
    <>
      <AdminCrumbs title={title} user={"User"} />
      <div className="admin_userlist">
        <AdminTabs title={title} />
        <div className="admin_userlist_content">
          <div className="utf">
            <p className="utf_heading">US.8</p>
          </div>
          <div className="buttons">
            <button
              className="btn"
              onClick={() => {
                navigate(`/administration/users`);
                dispatch(
                  setAdmionTabs({
                    title: "Users",
                    link: "/administration/users",
                  })
                );
              }}
            >
              Add New
              <svg
                className="svg-blue-dark"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#424167"
                />
              </svg>
            </button>
            <button
              className="btn"
              onClick={() => {
                setexportPopupOpen(true);
              }}
            >
              Export
              <svg
                className="svg-blue-dark"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
              >
                <path
                  d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                  fill="#424167"
                />
              </svg>
            </button>
            <button className="btn" onClick={userList}>
              Refresh
              <svg
                className="svg-blue-dark"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="21"
                viewBox="0 0 17 21"
                fill="none"
              >
                <path
                  d="M7.99905 3.99857V1.20857C7.99905 0.758566 7.45905 0.538566 7.14905 0.858566L3.34905 4.64857C3.14905 4.84857 3.14905 5.15857 3.34905 5.35857L7.13905 9.14857C7.45905 9.45857 7.99905 9.23857 7.99905 8.78857V5.99857C11.7291 5.99857 14.6791 9.41857 13.8591 13.2886C13.3891 15.5586 11.5491 17.3886 9.28905 17.8586C5.71905 18.6086 2.53905 16.1586 2.05905 12.8486C1.98905 12.3686 1.56905 11.9986 1.07905 11.9986C0.479051 11.9986 -0.000948563 12.5286 0.0790514 13.1286C0.699051 17.5186 4.87905 20.7686 9.60905 19.8486C12.7291 19.2386 15.2391 16.7286 15.8491 13.6086C16.8391 8.47857 12.9391 3.99857 7.99905 3.99857Z"
                  fill="#424167"
                />
              </svg>
            </button>
          </div>
          {userDataErrorHandling ? (
            <ApiError />
          ) : (
            <div className="userlist_table">
              <Table1
                editTable={false}
                height={800}
                Tablecoloum={Tablecoloumn}
                tableRow={data.map((val, i) => {
                  return { ...val, ID: i };
                })}
                load={load}
                coloumWidthText={"userList"}
                mandatoryColoum={"Name"}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={27}
                exporttitle={"UserList"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserList;
