const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const schedulingTableTitle = [
  { moreoption: true, customwidth: 50 },
  { title: "Select", bool: true, customwidth: 50, schedulingListControl: true },
  { title: "Offender Number" },
  { title: "Offender Name" },
  { title: "Report Date", date: true },
  { title: "Status" },
  { title: "Warrant", bool: true },
  { title: "Task" },
  { title: "Report Type" },
  { title: "Pre-Report Note" },
  { title: "Post-Report Note" },
  { title: "Attendance" },
  { title: "Resource" },
  { title: "Location" },
  { title: "Void", bool: true },
  { title: "Check In" },
  { title: "Report Location" },
];

export const schedulingDateRanges = [
  { name: "Last 3 Days" },
  { name: "Last 7 Days" },
  { name: "Month to Date" },
  { name: "Previous Month" },
  { name: "Next Month from Date" },
  { name: "Next 3 Month from Date" },
  { name: "last 3 Month to Date" },
  { name: "last 6 Month to Date" },
  { name: "Year to Date" },
  { name: "Previous Year" },
  { name: "Today" },
];

export const setSchedulingTableColumnData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Offender Number": val?.sOffenderNumber,
      "Offender Name": val?.sPersonLastFirst,
      "Report Date": convertDateFormat(val?.dReport),
      Status: val?.s,
      Warrant: val?.bWarrant,
      Task: val?.iTasks,
      "Report Type": val?.sReportDateTypeName,
      "Pre-Report Note": val?.sPreReportNote,
      "Post-Report Note": val?.sPostReportnote,
      Attendance: val?.sAttendanceName,
      Resource: val?.s,
      Location: val?.s,
      Void: val?.bVoid,
      "Check In": val?.dCheckIn,
      "Report Location": val?.sReportLocation,
      gPersonId: val?.gPersonId,
      gEntityId: val?.gEntityId,
      gUserId: val?.gUserId,
      gReportDateScheduleId: val?.gReportDateScheduleId,
      ID: i,
    });
  });
  return arr;
};

export const daysOfMonthsData = [
  { value: 1, key: 1 },
  { value: 2, key: 2 },
  { value: 3, key: 3 },
  { value: 4, key: 4 },
  { value: 5, key: 5 },
  { value: 6, key: 6 },
  { value: 7, key: 7 },
  { value: 8, key: 8 },
  { value: 9, key: 9 },
  { value: 10, key: 10 },
  { value: 11, key: 11 },
  { value: 12, key: 12 },
  { value: 13, key: 13 },
  { value: 14, key: 14 },
  { value: 15, key: 15 },
  { value: 16, key: 16 },
  { value: 17, key: 17 },
  { value: 18, key: 18 },
  { value: 19, key: 19 },
  { value: 20, key: 20 },
  { value: 21, key: 21 },
  { value: 22, key: 22 },
  { value: 23, key: 23 },
  { value: 24, key: 24 },
  { value: 25, key: 25 },
  { value: 26, key: 26 },
  { value: 27, key: 27 },
  { value: 28, key: 28 },
];
