import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_data: {},
  user_name: "",
  user_img: "",
  DynamicTab: [{ title: "Dashboard", link: "/home" }],
  wigitArr: 0,
  isProdDB: true,
  userAccessData: [],
};

const slice = createSlice({
  name: "HomePageReducer",
  initialState,
  reducers: {
    set_user_data(state, action) {
      state.user_data = action.payload;
    },
    set_user_name(state, action) {
      state.user_name = action.payload;
    },
    set_user_img(state, action) {
      state.user_img = action.payload;
    },
    setDynamicTab(state, action) {
      if (!state.DynamicTab.some((obj) => obj?.title === action.payload.title)) {
        state.DynamicTab = [...state.DynamicTab, action.payload];
      }
    },
    updatedynamicTab(state, action) {
      state.DynamicTab = action.payload;
    },
    removeDynamicTab(state, action) {
      state.DynamicTab = state.DynamicTab.filter((item) => item.title !== action.payload);
    },
    setwigitArr(state, action) {
      state.wigitArr = action.payload;
    },
    setIsProdDB(state, action) {
      state.isProdDB = action.payload;
    },
    setUserAccessData(state, action) {
      state.userAccessData = action.payload;
    },
  },
});

export const {
  set_user_data,
  set_user_name,
  set_user_img,
  setDynamicTab,
  removeDynamicTab,
  Resetfilter,
  setwigitArr,
  updatedynamicTab,
  setIsProdDB,
  setUserAccessData,
} = slice.actions;

export default slice.reducer;
