import React from "react";
import { Popup } from "devextreme-react/popup";
import PersonModal from "./PersonModal";

export const PersonFind = ({ isPopupVisible, setIsPopupVisible, nojump }) => {
  const HandleClose = () => setIsPopupVisible(false);

  return (
    <Popup
      visible={isPopupVisible}
      onHiding={HandleClose}
      showTitle={false}
      width="90%"
      height={634}
      showCloseButton={true}
      hideOnOutsideClick={true}
      onShown={() => {
        document.querySelector("#TESTE").focus();
      }}
    >
      <PersonModal setShowPersonModal={setIsPopupVisible} nojump={nojump} />
    </Popup>
  );
};
