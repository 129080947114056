import React, { useCallback, useState } from "react";
import "./drugTest.css";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import DataGrid, { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { DropDownBox, ScrollView } from "devextreme-react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { randomTestingTablecoloumn, setRandomTestingTableData } from "../Data";
import { useEffect } from "react";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../../Error/ApiError";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

export const RandomTesting = ({
  randomTesting,
  setRandomTesting,
  toastConfig,
  setToastConfig,
  docketDropdownData,
  drugDropdownData,
  userinfo,
  gotoDrugTest,
  dockertDetail,
}) => {
  const [loading, setLoading] = useState(false);
  const [randomTestingData, setRandomTestingData] = useState([]);
  const [fields, setFields] = useState({
    drugTestFrequency: { name: "", id: "" },
    drugTestLocation: { name: "", id: "" },
    includesWebUploads: false,
  });
  const [drugTestLocationErorr, setDrugTestLocationErorr] = useState("");
  const [docketErorr, setDocketErorr] = useState("");
  const [selectedValue, setSelectedValue] = useState({
    Court: "",
    Docket: "",
    "Sentence Date": "",
    "Supervision Status": "",
    gDocketId: "",
  });
  const [dataSource, setDataSource] = useState([]);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [randomTestingErrorHandling, setRandomTestingErrorHandling] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const navigate = useNavigate();
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  useEffect(() => {
    setDrugTestLocationErorr("");
    setDocketErorr("");
    setFields({
      drugTestFrequency: { name: "", id: "" },
      drugTestLocation: { name: "", id: "" },
      includesWebUploads: false,
    });
    setSelectedValue({
      Court: "",
      Docket: "",
      "Sentence Date": "",
      "Supervision Status": "",
      gDocketId: "",
    });
    const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
    gotoDrugTest && setSelectedValue(selectedDocket);
    const fetchedDataSource = docketDropdownData?.map((val) => {
      const convertDateFormat = (dateString) => {
        const dateObj = new Date(dateString);
        return dateObj.toLocaleDateString("en-US");
      };
      return {
        Court: val?.sCourtName,
        "Docket #": val?.sDocketNumberCourtCounts,
        "Sentence Date": convertDateFormat(val?.dSentence),
        "Supervision Status": val?.sSupervisionStatusName,
        gDocketId: val?.gDocketId,
      };
    });
    setDataSource(fetchedDataSource);
  }, [randomTesting]);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const getRandomTestingData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/DrugTest/getRandomTestingGridData?OffenderNumber=${userinfo?.sOffenderNumber}&IncludeWebUploads=${fields?.includesWebUploads}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setLoading(false);
          setRandomTestingData(setRandomTestingTableData(val?.data?.data));
        } else {
          setRandomTestingErrorHandling(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setRandomTestingErrorHandling(true);
        }
      });
  };

  const data = {
    docketId: selectedValue?.gDocketId,
    locationId: fields?.drugTestLocation?.id,
    frequencyId: fields?.drugTestFrequency?.id,
  };

  const handelSave = async () => {
    let isValid = true;
    if (fields.drugTestLocation.id === "") {
      setDrugTestLocationErorr("Please select Drug Test Location");
      isValid = false;
    }
    if (selectedValue.gDocketId === "") {
      setDocketErorr("Please select Docket");
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/DrugTest/save-random-drug-test`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoading(false);
          setRandomTesting(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Added!",
              type: "success",
            });
          }, 1900);
        })
        .catch((error) => {
          setLoading(false);
          if (!error?.response) {
            navigate(`/error/500`);
          } else if (error?.response?.status === 401) {
            logOut();
          }
        });
    }
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <div className="random_testing">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g id="medical_services" clipPath="url(#clip0_5919_9156)">
                  <g id="Group">
                    <g id="Group_2">
                      <path
                        id="Vector"
                        d="M20 6H16V4C16 2.9 15.1 2 14 2H10C8.9 2 8 2.9 8 4V6H4C2.9 6 2 6.9 2 8V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V8C22 6.9 21.1 6 20 6ZM10 4H14V6H10V4ZM15 15H13V17C13 17.55 12.55 18 12 18C11.45 18 11 17.55 11 17V15H9C8.45 15 8 14.55 8 14C8 13.45 8.45 13 9 13H11V11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11V13H15C15.55 13 16 13.45 16 14C16 14.55 15.55 15 15 15Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <p>Random Testing</p>
            </div>
            <p class="popup_header_title_para">DT.2</p>
          </div>
          <svg
            onClick={() => {
              setRandomTesting(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="random_tesing_container">
          <ScrollView style={{ height: "450px" }}>
            <div className="add_community_service_content" style={{ padding: "20px" }}>
              <div className="edit_container_first">
                <div className="edit_content_flex">
                  <p className="edit_container_label">
                    Docket <span className="marks_mandatary">*</span>
                  </p>
                  <DropDownBox
                    placeholder="Select..."
                    disabled={gotoDrugTest ? true : false}
                    dataSource={dataSource}
                    opened={isGridBoxOpened}
                    onOptionChanged={onGridBoxOpened}
                    value={selectedValue?.[`Docket #`]}
                    valueExpr={"Docket #"}
                    displayExpr={"Docket #"}
                    contentRender={(value, element) => (
                      <DataGrid
                        dataSource={dataSource}
                        hoverStateEnabled={true}
                        height={180}
                        onRowClick={(e) => {
                          setSelectedValue(e?.data);
                          setDocketErorr("");
                          setIsGridBoxOpened(false);
                        }}
                      >
                        <Selection mode="single" />
                        <FilterRow visible={true} />
                        <Column dataField="Court" caption="Court" width={"auto"} />
                        <Column dataField="Docket #" caption="Docket #" width={"auto"} />
                        <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                        <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                      </DataGrid>
                    )}
                  />
                  {docketErorr && (
                    <p className="error_validation" style={{ position: "absolute", top: "92px" }}>
                      {docketErorr}
                    </p>
                  )}
                </div>
                <div className="edit_content_flex edit_content_space">
                  <p className="edit_container_label">
                    Drug Test Location
                    <span className="marks_mandatary">*</span>
                  </p>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"drugTestLocation"}
                    dropDownData={drugDropdownData?.location?.map((vall) => {
                      return {
                        name: vall?.value,
                        id: vall?.key,
                      };
                    })}
                    reset={setDrugTestLocationErorr}
                  />
                  {drugTestLocationErorr && (
                    <p className="error_validation" style={{ position: "absolute", top: "92px" }}>
                      {drugTestLocationErorr}
                    </p>
                  )}
                </div>
              </div>
              <div className="edit_container_first">
                <div className="edit_content_flex edit_content_space">
                  <p className="edit_container_label">Drug Test Frequency</p>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"drugTestFrequency"}
                    dropDownData={drugDropdownData?.drugTestFrequency?.map((vall) => {
                      return {
                        name: vall?.value,
                        id: vall?.key,
                      };
                    })}
                  />
                </div>
              </div>
              <div className="add_drug_flex">
                <input
                  type="checkbox"
                  name="includesWebUploads"
                  id="includesWebUploads"
                  checked={fields.includesWebUploads}
                  onChange={(e) => {
                    handleInputChange(e);
                    getRandomTestingData();
                  }}
                />
                <label htmlFor="includesWebUploads" className="edit_container_label">
                  Includes Web Uploads
                </label>
              </div>
              {randomTestingErrorHandling ? (
                <ApiError />
              ) : (
                <div className="random_testing_table">
                  <Table1
                    editTable={false}
                    height={400}
                    exportPopupOpen={exportPopupOpen}
                    setexportPopupOpen={setexportPopupOpen}
                    Tablecoloum={randomTestingTablecoloumn}
                    tableRow={randomTestingData?.map((val, i) => {
                      return { ...val, ID: i };
                    })}
                    load={loading}
                    coloumWidthText={"RandomTesting"}
                    mandatoryColoum={"Log Date"}
                    exportInDifferentFormate={true}
                    exportwidth={27}
                    exporttitle={"RandomTesting"}
                  />
                </div>
              )}
              <div className="popup_buttons">
                <button className="btn primary_btn" onClick={handelSave}>
                  Save
                </button>
                <button
                  className="btn primary_btn"
                  onClick={() => {
                    setexportPopupOpen(true);
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Export
                  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                      fill="#fff"
                    />
                  </svg>
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    setRandomTesting(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ScrollView>
        </div>
      </div>
    </>
  );
};
