import { Popup } from "devextreme-react";
import React, { useState } from "react";
import { GoToObligationClone } from "./GoToObligationClone";
import { GoToObligationFind } from "./GoToObligationFind";
import { addnewObligationDynamicTab } from "../../../../reducer/ParticipateReducer";
import { useDispatch, useSelector } from "react-redux";

const ClonObligation = ({
  setcloneObligation,
  cloneObligation,
  RecipientCategory,
  setToastConfig,
  toastConfig,
  gotooblogationValue,
}) => {
  return (
    <>
      <Popup
        visible={cloneObligation}
        onHiding={() => {
          setcloneObligation(false);
        }}
        showTitle={false}
        width={480}
        height={340}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <GoToObligationClone
          setcloneObligation={setcloneObligation}
          cloneObligation={cloneObligation}
          RecipientCategory={RecipientCategory}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          gotooblogationValue={gotooblogationValue}
        />
      </Popup>
    </>
  );
};

const FIndObligation = ({ setfindObligation, findObligation }) => {
  return (
    <>
      <Popup
        visible={findObligation}
        onHiding={() => {
          setfindObligation(false);
        }}
        showTitle={false}
        width={560}
        height={610}
        showCloseButton={true}
        hideOnOutsideClick={true}
        onShown={() => {
          document.querySelector("#TESTE").focus();
        }}
      >
        <GoToObligationFind setfindObligation={setfindObligation} findObligation={findObligation} />
      </Popup>
    </>
  );
};

export const GoToObligationTopButton = ({
  RecipientCategory,
  setToastConfig,
  toastConfig,
  gotooblogationValue,
  getObligation,
  setaddobligation,
  data,
  setEditTogel,
  refreshApiButton,
  setRefreshApiButton,
  addAccess,
}) => {
  const [cloneObligation, setcloneObligation] = useState(false);
  const [findObligation, setfindObligation] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <ClonObligation
        setcloneObligation={setcloneObligation}
        cloneObligation={cloneObligation}
        RecipientCategory={RecipientCategory}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        gotooblogationValue={gotooblogationValue}
      />
      <FIndObligation setfindObligation={setfindObligation} findObligation={findObligation} />
      <div className="buttons">
        <button
          className="btn"
          onClick={() => {
            dispatch(
              addnewObligationDynamicTab({
                sDocketNumberCourtCounts: data?.sDocketNumberCourtCounts,
                sReference: data?.sReference,
              })
            );
            setEditTogel(true);
            setaddobligation(true);
          }}
          disabled={!addAccess}
        >
          Add
          <svg
            className="svg-blue-white"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_6247_15083)">
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                fill="#424167"
              />
            </g>
            <defs>
              <clipPath id="clip0_6247_15083">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          className="btn"
          onClick={() => {
            setcloneObligation(true);
          }}
          disabled={!addAccess}
        >
          Clone
          <svg
            className="svg-blue-white"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_6247_15083)">
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                fill="#424167"
              />
            </g>
            <defs>
              <clipPath id="clip0_6247_15083">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          className="btn"
          onClick={() => {
            setfindObligation(true);
          }}
        >
          Find
          <svg
            className="svg-blue-white"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_6247_15088)">
              <path
                d="M15.5006 14.0006H14.7106L14.4306 13.7306C15.6306 12.3306 16.2506 10.4206 15.9106 8.39063C15.4406 5.61063 13.1206 3.39063 10.3206 3.05063C6.09063 2.53063 2.53063 6.09063 3.05063 10.3206C3.39063 13.1206 5.61063 15.4406 8.39063 15.9106C10.4206 16.2506 12.3306 15.6306 13.7306 14.4306L14.0006 14.7106V15.5006L18.2506 19.7506C18.6606 20.1606 19.3306 20.1606 19.7406 19.7506C20.1506 19.3406 20.1506 18.6706 19.7406 18.2606L15.5006 14.0006ZM9.50063 14.0006C7.01063 14.0006 5.00063 11.9906 5.00063 9.50063C5.00063 7.01063 7.01063 5.00063 9.50063 5.00063C11.9906 5.00063 14.0006 7.01063 14.0006 9.50063C14.0006 11.9906 11.9906 14.0006 9.50063 14.0006Z"
                fill="#424167"
              />
            </g>
            <defs>
              <clipPath id="clip0_6247_15088">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          onClick={() => {
            if (data?.gObligationId) {
              getObligation();
              setRefreshApiButton(!refreshApiButton);
            }
          }}
          className="btn"
        >
          Refresh
          <svg
            className="svg-blue-white"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_6247_15093)">
              <path
                d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                fill="#424167"
              />
            </g>
            <defs>
              <clipPath id="clip0_6247_15093">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </>
  );
};
