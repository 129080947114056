import { DateBox, LoadPanel, TextBox } from "devextreme-react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { useEffect, useState } from "react";
import { GoToDocketHeaderFunction } from "./GoToDocketHeaderFunction";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setaddworentREfresh } from "../../../../reducer/ParticipateReducer";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        // reset={reset}
        margin={"7px"}
      />
    </>
  );
};

export const ChangeSuperVisorDate = ({
  setChangesuperviserDatePOpup,
  data,
  SupervisionStatusdropdown,
  resetPOpup,
  changeReasonDRopDown,
  setToastConfig,
  toastConfig,
  getDcket,
  refreshUserInfo,
}) => {
  const isMultipleDocket = !(data?.length === 1);
  const clas = new GoToDocketHeaderFunction();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    "Docket Reference": { name: "", id: "" },
    "Supervision Status": { name: "", id: "" },
    "Effective Date": isMultipleDocket
      ? new Date().toISOString().split("T")[0] + "T00:00:00"
      : data?.[0]?.dSupervisionStatus
      ? data?.[0]?.dSupervisionStatus
      : new Date().toISOString().split("T")[0] + "T00:00:00",
    "Change Reason": { name: "", id: "" },
    comment: "",
  });
  const navigate = useNavigate();
  const [commentValidation, setcommentValidation] = useState("");
  const [changeValidation, setChangeValidation] = useState("");
  const [dateValidation, setdateValidation] = useState("");
  const [enablePeriod, setEnablePeriod] = useState(false);
  const { user_data } = useSelector((store) => store.Home);
  const currentDate = new Date(),
    year = currentDate.getFullYear(),
    month = currentDate.getMonth();

  const dispatch = useDispatch();

  useEffect(() => {
    clas.getSupervisionAdjustmentPeriod(setLoading, navigate, setEnablePeriod);
  }, []);

  const Done = () => {
    if (!fields?.["Effective Date"]) {
      return;
    }
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const startDate = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const endDate = new Date(year, month, lastDay.getDate());

    const inputDate = new Date(fields?.["Effective Date"]);

    const isValid = inputDate >= startDate && inputDate <= endDate;
    // const isValid = true;

    if (isValid || !enablePeriod) {
      setdateValidation("");
      if (fields?.comment && fields?.["Change Reason"].id) {
        setcommentValidation("");
        setChangeValidation("");
        const recordId = data.map((docket) => docket.gDocketId);
        clas.SaveChangeSuperVisorDate(
          {
            recordId,
            obligationReference: data?.[0]?.sReferenceNumber,
            statusId: fields?.["Supervision Status"].id,
            effectiveDate: fields?.["Effective Date"],
            statusChangeReasonId: fields?.["Change Reason"].id,
            comment: fields?.comment,
            isDocket: true,
          },
          navigate,
          setLoading,
          setChangesuperviserDatePOpup,
          setToastConfig,
          toastConfig,
          getDcket
        );
        if (refreshUserInfo) {
          dispatch(setaddworentREfresh((prev) => !prev));
        }
      } else {
        !fields?.comment && setcommentValidation("Please Enter The Comment");
        !fields?.["Change Reason"].id && setChangeValidation("Please Select the Change Reason");
      }
    } else {
      setdateValidation("Date is outside the range.");
      !fields?.comment && setcommentValidation("Please Enter The Comment");
    }
  };

  useEffect(() => {
    clas.ChangeSuperVisorDateresetValue(setFields, data, setcommentValidation);
    setChangeValidation("");
  }, [resetPOpup]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div style={{ height: "598px" }} className="create-concurrent-docket-container">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_974_363096)">
                  <path d="M0 0H24V24H0V0Z" fill="var(--button2)" />
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_974_363096">
                    <rect width="24" height="24" fill="rgb(67, 66, 102)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Change Supervision Status</p>
            </div>
            <p class="popup_header_title_para">DK.7</p>
          </div>
          <svg
            onClick={() => {
              setChangesuperviserDatePOpup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div style={{ width: "94%", marginLeft: "3%" }}>
          <div className="quickAddObligationn">
            {!isMultipleDocket && (
              <div>
                <p>Docket #</p>
                <p
                  style={{
                    marginLeft: "15px",
                    color: "var(--TextColor)",
                  }}
                >
                  {data?.[0]?.sDocketNumberCourtCounts}
                </p>
              </div>
            )}
            <div>
              <p>Person</p>
              <p
                style={{
                  marginLeft: "15px",
                  color: "var(--TextColor)",
                }}
              >
                {data?.[0]?.sPersonNameLastFirst}
              </p>
            </div>
          </div>
          <div className="collectionpopupBLOCK2">
            {!isMultipleDocket && (
              <div className="collectionpopupBLOCK2inputt">
                <p>
                  Docket Reference <span>*</span>
                </p>
                <div>
                  <TextBox
                    disabled={true}
                    style={{ marginTop: "3%" }}
                    value={data?.[0]?.sReferenceNumber}
                    isValid={true}
                    placeholder={"Docket Number"}
                  />
                </div>
              </div>
            )}
            <div className="collectionpopupBLOCK2inputt">
              <p>
                Supervision Status <span>*</span>
              </p>
              <div>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"Supervision Status"}
                  dropDownData={SupervisionStatusdropdown}
                  // reset={setObligationtypevalidaton}
                />
              </div>
            </div>
          </div>
          <div className="collectionpopupBLOCK2">
            <div className="collectionpopupBLOCK2inputt">
              <p>
                Effective Date <span>*</span>
              </p>
              <div>
                <DateBox
                  style={{ marginTop: "2%" }}
                  value={fields?.["Effective Date"]}
                  onValueChange={(e) => {
                    setdateValidation("");
                    const utcFormatted = new Date(e).toISOString();
                    setFields({
                      ...fields,
                      "Effective Date": e ? utcFormatted : null,
                    });
                  }}
                  useMaskBehavior={true}
                />
                <p
                  style={{
                    color: "red",
                    position: "absolute",
                  }}
                >
                  {dateValidation}
                </p>
              </div>
            </div>
            <div className="collectionpopupBLOCK2inputt">
              <p>Change Reason</p>
              <div>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"Change Reason"}
                  dropDownData={changeReasonDRopDown}
                  reset={setChangeValidation}
                />
              </div>
              <p style={{ color: "red", position: "absolute", fontWeight: "400" }}>{changeValidation}</p>
            </div>
          </div>
          {enablePeriod && (
            <div className="quickAddObligationn">
              <div>
                <p>Valid Dates : </p>
                <p
                  style={{
                    marginLeft: "15px",
                    color: "var(--TextColor)",
                  }}
                >
                  {`${month + 1}/1/${year}`}
                </p>
              </div>

              <div>
                <p>through :</p>
                <p
                  style={{
                    marginLeft: "15px",
                    color: "var(--TextColor)",
                  }}
                >
                  {`${month + 1}/${new Date(year, month + 1, 0).getDate()}/${year}`}
                </p>
              </div>
            </div>
          )}

          <div style={{ marginTop: "4%" }}>
            <p style={{ color: "var(--TextColor)", fontWeight: "400" }}>
              Please add a detailed comment <span>*</span>
            </p>
            <textarea
              value={fields?.comment}
              onChange={(e) => {
                setcommentValidation("");
                setFields({ ...fields, comment: e.target.value });
              }}
              style={{ width: "100%", height: "150px", marginTop: "1.4%" }}
            />
            <p style={{ color: "red", position: "absolute", fontWeight: "400" }}>{commentValidation}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          >
            <button
              style={{
                backgroundColor: "var(--button2)",
                color: "white",
                width: "15%",
                cursor: "pointer",
              }}
              onClick={() => {
                Done();
              }}
            >
              Done
            </button>
            <button
              style={{
                width: "15%",
                marginLeft: "1%",
                cursor: "pointer",
                background: "var(--button-lightblack-lightblue)",
                color: "var(--TextColor)",
              }}
              onClick={() => {
                setChangesuperviserDatePOpup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
