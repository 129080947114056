import React, { useState, useEffect, useRef } from "react";
import { LoadPanel } from "devextreme-react";
import "../financialPop.css";
import { handleGetLocal } from "../../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../../components/Logout";
import { useNavigate } from "react-router-dom";

export const RefundCCPayment = ({
  setrefundCCPaymentPopup,
  gPaymentId,
  setCallApi,
  callApi,
  rowInfoData,
  userinfo,
  toastConfig,
  setToastConfig,
}) => {
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState({ comment: "", amountToRefund: "" });
  const [errors, setErrors] = useState({
    amountToRefund: "",
    comment: "",
  });
  const componentRef = useRef(null);

  const [approver, setApprover] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setField((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setField((prev) => ({
          ...prev,
          comment: "",
          amountToRefund: "",
        }));
        setErrors({ comment: "", amountToRefund: "" });
      }
    };

    document.addEventListener("click", handleOutsideClick);

    const getApprover = async () => {
      const pathname = handleGetLocal("pathURL");

      const result = await axios.get(
        `${process.env.REACT_APP_API_URL
        }/api/User/get-company-options?sName=${"Credit Card Refund Approver"}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );

      if (result.data.data.length) setApprover(result.data.data[0]?.sValue);
      else setApprover(null);
    };

    getApprover();

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const navigate = useNavigate();

  const validation = () => {
    let isValid = true;
    if (field.amountToRefund === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        amountToRefund: "Please enter the amount to refund.",
      }));
      isValid = false;
    }
    if (field.amountToRefund > rowInfoData?.Amount) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        amountToRefund:
          "You Cannot Refund More Than The Original Payment Amount.",
      }));
      isValid = false;
    }
    if (field.comment === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        comment: "Please provide a reason for void or refund.",
      }));
      isValid = false;
    }

    return isValid;
  };

  const handleVoid = async () => {
    if (validation()) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .get(
          `${process.env.REACT_APP_API_URL
          }/api/Payment/VoidCreditCardPayment?paymentId=${rowInfoData?.gPaymentId
          }&transactionID=${rowInfoData?.[`Transaction ID`]}&amountToRefund=${field?.amountToRefund
          }&comment=${field?.comment}&IsSuccess=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        )
        .then((val) => {
          setLoading(false);
          setrefundCCPaymentPopup(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: val?.data?.responseMessage,
            type: val?.data?.responseIcon.toLowerCase(),
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: val?.data?.responseMessage,
              type: val?.data?.responseIcon.toLowerCase(),
            });
          }, 1900);
          setCallApi(!callApi);
        })
        .catch((val) => {
          setLoading(false);
          if (val?.response?.status == 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  };

  const handleRefund = async () => {
    if (validation()) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      let body = {
        taskId: null,
        active: true,
        dueDate: currentDate,
        dDoe: new Date(),
        daysTillDue: null,
        assignedToName: null,
        categoryName: null,
        note: `Refund for Payment ${rowInfoData['Receipt #']}`,
        title: "Refund Request",
        statusName: null,
        enteredBy: userinfo?.gEntryUserId,
        dateCompleted: null,
        assignedToId: localStorage.getItem("gUserId"),
        categoryId: "9178E13F-C688-4BC4-A0BE-AEF7A6621CDA",
        statusId: "45270F21-49C4-425C-B0BB-D679DCF0E630",
        personId: userinfo?.gPersonId,
        sLastFirst: null
      }

      await saveTask(body);

      await axios
        .get(
          `${process.env.REACT_APP_API_URL
          }/api/Payment/RefundCreditCardPayment?paymentId=${rowInfoData?.gPaymentId
          }&transactionID=${rowInfoData?.[`Transaction ID`]}&amountToRefund=${field?.amountToRefund
          }&comment=${field?.comment}&IsSuccess=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        )
        .then((val) => {

          setLoading(false);
          setrefundCCPaymentPopup(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: val?.data?.responseMessage,
            type: val?.data?.responseIcon.toLowerCase(),
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: val?.data?.responseMessage,
              type: val?.data?.responseIcon.toLowerCase(),
            });
          }, 1900);
          setCallApi(!callApi);
        })
        .catch((val) => {
          setLoading(false);
          if (val?.response?.status == 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  };

  const saveTask = async (body) => {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/task/save-task`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status == 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="void_cc_payment" ref={componentRef}>
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Void/Refund Credit Card Payment</p>
            </div>
            <p class="popup_header_title_para">PY.9</p>
          </div>
          <svg
            onClick={() => {
              setrefundCCPaymentPopup(false);
              setField((prev) => ({
                ...prev,
                comment: "",
                amountToRefund: "",
              }));
              setErrors({ comment: "", amountToRefund: "" });
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="void_cc_payment_container">
          <p className="alert_para">
            WARNING - This action is irreversible and will refund the payment to
            the client.
          </p>
          <div>
            <p className="void_cc_payment_label">PAYMENT INFORMATION</p>
            <div className="void_cc_payment_first_textarea">
              <p>{userinfo?.sOffenderNumber}</p>
              <p>{userinfo?.sFirstLast}</p>
              <p>Payment Date: {rowInfoData?.[`Date of Receipt`]}</p>
              <p>Payment Amount: {formattedPrice(rowInfoData?.Amount)}</p>
              <p>
                Transaction Id:
                {rowInfoData?.[`Transaction ID`]}
              </p>
            </div>
          </div>
          <p className="alert_para">
            CLIENT BALANCE IS: {formattedPrice(userinfo?.fBalance)}
          </p>
          <div className="void_cc_payment_container_field">
            <p className="void_cc_payment_label">
              AMOUNT TO REFUND: <span className="marks_mandatary">*</span>
            </p>
            <div>
              <input
                type="number"
                name="amountToRefund"
                id="amountToRefund"
                className="void_cc_payment_input"
                placeholder="Amount to refund"
                value={field.amountToRefund}
                onChange={handleInputChange}
              />
              {errors.amountToRefund && (
                <p className="error_validation">{errors.amountToRefund}</p>
              )}
            </div>
          </div>
          <div>
            <p className="void_cc_payment_label">
              REASON FOR VOID OR REFUND
              <span className="marks_mandatary">*</span>
            </p>
            <textarea
              name="comment"
              id="comment"
              className="void_cc_payment_second_textarea"
              value={field.comment}
              onChange={handleInputChange}
              placeholder="Enter reason for void or refund"
            ></textarea>
            {errors.comment && (
              <p className="error_validation">{errors.comment}</p>
            )}
          </div>

          <div className="void_cc_payment_container_field">
            <p className="void_cc_payment_label">Approver:</p>
            <div>
              <input
                type="text"
                name="approver"
                id="approver"
                className="void_cc_payment_input"
                placeholder="Approver"
                value={approver}
                disabled
              />
            </div>
          </div>

          <p className="void_cc_payment_label">
            There is no Transaction Id associated with this payment.
          </p>
          <div className="void_buttons">
            <button
              className="void_cc_button void_cc_button_color"
              onClick={() => {
                handleVoid();
              }}
            >
              Process Void
            </button>
            <button
              className="void_cc_button void_cc_button_color"
              onClick={() => {
                handleRefund();
              }}
            >
              {approver ? "Request" : "Process"} Refund
            </button>
            <button
              className="btn edit_payment_btn"
              onClick={() => {
                setrefundCCPaymentPopup(false);
                setField((prev) => ({
                  ...prev,
                  comment: "",
                  amountToRefund: "",
                }));
                setErrors({ comment: "", amountToRefund: "" });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
