import { useState } from "react";
import { ReportDashboard } from "./ReportDashboard/ReportDashboard";
import { ReportingTopDynamicTab } from "./ReportingTopDynamicTab";
import './report.css'

export const Report = () => {
  const [ReportDynamicTab, setReportDynamicTab] = useState([
    { title: "Report Dashboard", Offender: "Report-Dashboard" },
  ]);

  const [tab, setTab] = useState(1);
  return (
    <>
      <div className="breadCrumb">
        <ul style={{ width: "250px" }}>
          <li>Report</li>
          <li className="active"> Report Dashboard</li>
        </ul>
      </div>
      <div className="ReportWizard">
        <ReportingTopDynamicTab
          ReportDynamicTab={ReportDynamicTab}
          setTab={setTab}
          tab={tab}
        />
        {tab == 1 && <ReportDashboard />}
      </div>
    </>
  );
};
