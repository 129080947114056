import { useEffect, useState } from "react";
import "./ReportWizard.css";
import { ReportingWizardTopDynamicTab } from "./ReportingWizardTopDynamicTab";
import { ActivePersonList } from "./ActivePersonList/ActivePersonList";
import { Paymentlist } from "./Paymentlist/Paymentlist";
import { ActiveLIst } from "./ActiveLIst/ActiveLIst";
import { DocketLIst } from "./DocketLIst/DocketLIst";
import { ObligationLIst } from "./ObligationLIst/ObligationLIst";
import { ReportDate } from "./ReportDate/ReportDate";
import { Disbursement } from "./Disbursement/Disbursement";
import { DrugTestREportWizard } from "./DrugTest/DrugTestREportWizard";
import { ReportSchedule } from "./ReportSchedule/ReportSchedule";
import { Popup } from "devextreme-react";
import { headersidebarfunction } from "../HeaderSideBar/headersidebarfunction";
import { useNavigate } from "react-router-dom";
import HelpPopup from "../HeaderSideBar/HelpPopup";
import question from "./../../assets/images/question.png";

export const ReportWizard = () => {
  const [ReportWizardDynamicTab, setReportWizardDynamicTab] = useState([
    { title: "Active Person List", Offender: "Active-Person-List" },
    { title: "Payment List", Offender: "Payment-List" },
    { title: "Activity Notes", Offender: "Activity-List" },
    { title: "Docket List", Offender: "Docket-List" },
    { title: "Obligation List", Offender: "Obligation-List" },
    { title: "Report Date", Offender: "Report-Date" },
    { title: "Disbursement", Offender: "Disbursement" },
    { title: "Drug Test", Offender: "Drug-Test" },
    { title: "Report Schedule", Offender: "Report-Schedule" },
  ]);

  const [tab, setTab] = useState(1);

  const clas2 = new headersidebarfunction();
  const [markupid, setmarkupid] = useState("");
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  useEffect(() => {
    clas2.getHelp(
      setLoading,
      navigate,
      setmarkup,
      setmarkupid,
      "MainReportWizard",
      setmarkupissScreenNamu,
      setmarkupisCaption
    );
  }, []);
  return (
    <>
      {markupid && (
        <Popup
          visible={markupid && helpPopUp}
          onHiding={() => {
            setHelpPopUp(false);
          }}
          showTitle={false}
          width={1200}
          height={670}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <HelpPopup
            markup={markup}
            markupid={markupid}
            helpPopUp={helpPopUp}
            setHelpPopUp={setHelpPopUp}
            markupsCaption={markupsCaption}
            markupsScreenName={markupsScreenName}
          />
        </Popup>
      )}

      <div className="breadCrumb">
        <ul style={{ width: "250px" }}>
          <li>Report Wizard</li>
          <li className="active"> Active Person List</li>
        </ul>
      </div>
      {markupid && (
        // <img
        //   style={{
        //     width: "25px",
        //     height: "25px",
        //     cursor: "pointer",
        //   }}
        //   src={question}
        //   onClick={() => {
        //     setHelpPopUp(true);
        //   }}
        // />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28px"
          height="28px"
          viewBox="0 0 24 24"
          fill="none"
          style={{ cursor: "pointer" }}
          className="svg-blue-black"
          onClick={() => {
            setHelpPopUp(true);
          }}
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
            fill="white"
          />
        </svg>
      )}
      <div className="ReportWizard">
        <ReportingWizardTopDynamicTab
          ReportWizardDynamicTab={ReportWizardDynamicTab}
          setTab={setTab}
          tab={tab}
        />
        {tab === 1 && <ActivePersonList />}
        {tab === 2 && <Paymentlist />}
        {tab === 3 && <ActiveLIst />}
        {tab === 4 && <DocketLIst />}
        {tab === 5 && <ObligationLIst />}
        {tab === 6 && <ReportDate />}
        {tab === 7 && <Disbursement />}
        {tab === 8 && <DrugTestREportWizard />}
        {tab === 9 && <ReportSchedule />}
      </div>
    </>
  );
};
