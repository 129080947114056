import { SelectBox } from "devextreme-react";
import { useState } from "react";

const SelectListItem = ({ text, setSelectListItemPopupOpen, list, onChange }) => {
  const [value, setValue] = useState("");
  return (
    <div style={{ border: "3px solid var(--button2)", height: "248px" }} className="PersonModalbody">
      <div className="PersonModal">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: text === "Select Cash Drawer" ? "37%" : "33%",
            margin: ".8%",
          }}
          className="modelHeader"
        >
          <p
            style={{
              marginTop: "3%",
              color: "white",
              fontWeight: "500",
              fontSize: "13px",
            }}
          >
            {text}
          </p>
        </div>
        <span
          style={{
            fontSize: "30px",
            marginRight: "2%",
            cursor: "pointer",
            color: "white",
          }}
          onClick={() => {
            setSelectListItemPopupOpen(false);
          }}
        >
          &times;
        </span>
      </div>
      <div style={{ width: "94%", marginLeft: "3%" }}>
        <div style={{ marginTop: "5%" }}>
          <p className="go-to-label-filed">{text}</p>
          <SelectBox dataSource={list} placeholder={text} onValueChange={(val) => setValue(val)} />
        </div>
        <div className="popup_buttons" style={{ marginTop: "50px" }}>
          <button
            className="btn primary_btn"
            onClick={() => {
              //   Save();
              onChange(value);
              setSelectListItemPopupOpen(false);
            }}
          >
            Done
          </button>
          <button
            onClick={() => {
              setSelectListItemPopupOpen(false);
            }}
            className="btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectListItem;
