import React, { useEffect, useState } from "react";
import "./obligationPop.css";
import "../financialPop.css";
import { ColumnDropdown } from "../../../../../../components/Dropdownn";
import { handleGetLocal } from "../../../../../../services/auth-service";
import axios from "axios";
import { LoadPanel, ScrollView } from "devextreme-react";
import { logOut } from "../../../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  setreasonvalidation,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={setreasonvalidation}
      />
    </>
  );
};

export const WriteOffAmount = ({
  writeOffAmount,
  setWriteOffAmount,
  obligationData,
  financialStatus,
  reasonListData,
  callObligationApi,
  setCallObligationApi,
  toastConfig,
  setToastConfig,
}) => {
  const [loading, setLoading] = useState(false);
  const [WriteOffBalance, setWriteOffBalance] = useState(0);
  const [NewBalance, setNewBalance] = useState(
    obligationData?.Balance ? obligationData?.Balance : 0
  );
  const [fields, setFields] = useState({
    amountWrtieOff: 0,
    reason: {
      name: "",
      id: "",
    },
    newFinancialStatus: {
      name: "",
      id: "",
    },
    comment: "",
  });

  const [errors, setErrors] = useState({
    NewBalance: "",
    comment: "",
  });
  const [reasonvalidation, setreasonvalidation] = useState("");

  useEffect(() => {
    if (writeOffAmount) {
      setFields({
        amountWrtieOff: 0,
        reason: {
          name: "",
          id: "",
        },
        newFinancialStatus: {
          name: "",
          id: "",
        },
        comment: "",
      });
      setErrors({
        NewBalance: "",
        comment: "",
      });
      setreasonvalidation("");
      setWriteOffBalance(0);
      setNewBalance(obligationData?.Balance ? obligationData?.Balance : 0);
    }
  }, [writeOffAmount]);

  const updateNewFinancialStatusId = () => {
    const matchingStatus = financialStatus.find(
      (status) => status.value === obligationData?.sFinancialStatusName
    );
    const updatedId = matchingStatus ? matchingStatus.key : "";
    setFields((prevFields) => ({
      ...prevFields,
      newFinancialStatus: {
        ...prevFields.newFinancialStatus,
        name: obligationData?.sFinancialStatusName,
        id: updatedId,
      },
    }));
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (obligationData?.sFinancialStatusName) {
      updateNewFinancialStatusId();
    }
  }, [obligationData?.sFinancialStatusName, writeOffAmount]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name == "amountWrtieOff") {
      setWriteOffBalance(value);
      setNewBalance(Number(obligationData?.Balance) - Number(value));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const data = {
    ObligationId: obligationData?.gObligationId,
    WriteOffAmount: fields?.amountWrtieOff,
    NewFinancialStatusID: fields.newFinancialStatus?.id,
    WriteOffReasonID: fields?.reason?.id,
    Comment: fields?.comment,
    GUserId: obligationData?.gPersonId,
  };

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  const minValue = -Math.abs(obligationData?.Balance?.toFixed(2) - 0.01);
  const maxValue = Math.abs(obligationData?.Balance?.toFixed(2) - 0.01);

  const handleSave = async () => {
    let isValid = true;
    if (NewBalance === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        NewBalance: "Please Enter Amount to Write Off.",
      }));
      isValid = false;
    }
    if (NewBalance < minValue || NewBalance >= maxValue) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        NewBalance: `Amount to Write Off must be between ($${Math.abs(
          minValue
        )}) and $${maxValue}.`,
      }));
      isValid = false;
    }
    if (fields.comment === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        comment: "Please Provide Comment.",
      }));
      isValid = false;
    }
    if (fields.reason.id === "") {
      setreasonvalidation("Please Select Reason.");
      isValid = false;
    }

    if (isValid) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/obligations/writeOffAmount`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        )
        .then((val) => {
          setLoading(false);
          setWriteOffAmount(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: val.data.responseMessage,
            type: val.data.responseIcon.toLowerCase(),
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: val.data.responseMessage,
              type: val.data.responseIcon.toLowerCase(),
            });
          }, 1900);
          setCallObligationApi(!callObligationApi);
          setFields({
            amountWrtieOff: "",
            reason: {
              name: "",
              id: "",
            },
            newFinancialStatus: {
              name: "",
              id: "",
            },
            comment: "",
          });
        })
        .catch((val) => {
          setLoading(false);
          if (val?.response?.status == 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="write_off_amount">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="warning">
                  <path
                    id="Vector"
                    d="M4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997ZM12.0001 13.9997C11.4501 13.9997 11.0001 13.5497 11.0001 12.9997V10.9997C11.0001 10.4497 11.4501 9.99969 12.0001 9.99969C12.5501 9.99969 13.0001 10.4497 13.0001 10.9997V12.9997C13.0001 13.5497 12.5501 13.9997 12.0001 13.9997ZM13.0001 17.9997H11.0001V15.9997H13.0001V17.9997Z"
                    fill="#ffff"
                  />
                </g>
              </svg>
              <p>Write Off</p>
            </div>
            <p class="popup_header_title_para">WO.1</p>
          </div>
          <svg
            onClick={() => {
              setWriteOffAmount(false);
              setErrors({ comment: "", NewBalance: "", reason: "" });
              setFields({
                amountWrtieOff: 0,
                reason: {
                  name: "",
                  id: "",
                },
                newFinancialStatus: {
                  name: "",
                  id: "",
                },
                comment: "",
              });
              setreasonvalidation("");
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ height: "510px" }}>
          <div className="edit_container custom_margin">
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>Information</p>
            <p className="edit_container_label">
              Obligation {obligationData?.Reference}
            </p>
            <div className="write_off_info">
              <p className="edit_container_label">
                Curent Balance: {formattedPrice(obligationData?.Balance)}
              </p>
              <p className="edit_container_label">
                Write Off Balance:
                {formattedPrice(WriteOffBalance)}
              </p>
              <p className="edit_container_label">
                New Balance: {formattedPrice(NewBalance)}
              </p>
            </div>
            <div className="edit_container_first align_basline">
              <div style={{ position: "relative" }}>
                <p className="edit_container_label">
                  Amount to Write Off <span className="marks_mandatary">*</span>
                </p>
                <input
                  style={{ marginTop: "3.7%" }}
                  id="amountWrtieOff"
                  name="amountWrtieOff"
                  type="number"
                  min={0}
                  placeholder="Enter Amount to Write Off"
                  className="edit_container_input"
                  value={fields.amountWrtieOff}
                  onChange={handleInputChange}
                />
              </div>
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Reason <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"reason"}
                  dropDownData={reasonListData.map((vall) => {
                    return {
                      name: vall?.value,
                      id: vall?.key,
                    };
                  })}
                  setreasonvalidation={setreasonvalidation}
                />
                {reasonvalidation && (
                  <p className="error_validation">{reasonvalidation}</p>
                )}
              </div>
            </div>
            <div className="edit_container_first align_basline">
              <div style={{ position: "relative" }}>
                <p className="edit_container_label">New Financial Status</p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"newFinancialStatus"}
                  dropDownData={financialStatus.map((vall) => {
                    return {
                      name: vall?.value,
                      id: vall?.key,
                    };
                  })}
                />
              </div>

              <div style={{ position: "relative" }}>
                <p className="edit_container_label">New Balance</p>
                <input
                  style={{ marginTop: "3.7%" }}
                  id="NewBalance"
                  name="NewBalance"
                  type="number"
                  placeholder="Enter Amount to Write Off"
                  className="edit_container_input"
                  value={NewBalance}
                  onChange={(e) => {
                    setNewBalance(e.target.value);

                    setFields({
                      ...fields,
                      amountWrtieOff:
                        Number(fields?.amountWrtieOff) +
                        (Number(NewBalance) - Number(e.target.value)),
                    });

                    setWriteOffBalance(
                      Number(fields?.amountWrtieOff) +
                        (Number(NewBalance) - Number(e.target.value))
                    );

                    setErrors({ ...errors, NewBalance: "" });
                  }}
                />
                {errors.NewBalance && (
                  <p className="error_validation">{errors?.NewBalance}</p>
                )}
              </div>
            </div>
            <div className="edit_container_second edit_content_flex">
              <p className="edit_container_label">
                Comment <span className="marks_mandatary">*</span>
              </p>
              <textarea
                name="comment"
                id="comment"
                placeholder="Write a comment here..."
                value={fields.comment}
                onChange={handleInputChange}
              ></textarea>
              {errors.comment && (
                <p className="error_validation">{errors.comment}</p>
              )}
            </div>
            <div className="popup_buttons">
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setWriteOffAmount(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
