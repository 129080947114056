import { DateBox, LoadPanel, NumberBox, ScrollView } from "devextreme-react";
import { useEffect, useState } from "react";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { useDispatch } from "react-redux";
import { setDocketReload } from "../../../../../reducer/ParticipateReducer";

export const ChangeSupervisionPopup = ({
  setChangeSupervision,
  reset,
  getTopDynamicDocketid,
  setToastConfig,
  toastConfig,
}) => {
  const [loading, setLoading] = useState();
  const [errorhandling2, seterrorhandling2] = useState(false);
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");

  const dispatch = useDispatch();

  const [fields, setFields] = useState({
    "Supervision Start Date": "",
    Month: "",
    Days: "",
    "End Date": "",
  });
  const [data, setdata] = useState({});

  const [Supervisionvalidation, setSupervisionvalidation] = useState("");
  const [enddatevalidation, setenddatevalidation] = useState("");

  const getdata = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-supervision-date?docketId=${getTopDynamicDocketid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setdata(val?.data?.data);
          let month;
          if (val?.data?.data?.months) {
            const updatedDate = new Date(val?.data?.data?.supervisionStartDate);
            updatedDate.setMonth(updatedDate.getMonth() + val?.data?.data?.months);
            month = updatedDate.toLocaleDateString("en-US");
          } else if (val?.data?.data?.days) {
            const result = new Date(val?.data?.data?.supervisionStartDate);
            result.setDate(result.getDate() + val?.data?.data?.days);
            month = `${result.getMonth() + 1}/${result.getDate()}/${result.getFullYear()}`;
          } else {
            month = val?.data?.data?.supervisionStartDate;
          }

          setFields({
            "Supervision Start Date": val?.data?.data?.supervisionStartDate,
            Month: val?.data?.data?.months,
            Days: val?.data?.data?.days,
            "End Date": month,
          });
        } else {
          seterrorhandling2(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling2(true);
        }
      });
  };

  const save = async (body) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/change-supervision-date`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Updated!",
            type: "success",
          });
        }, 1400);
        dispatch(setDocketReload((prev) => !prev));
        setChangeSupervision(false);

        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };
  const saveas = () => {
    if (fields?.["Supervision Start Date"] && fields?.["End Date"]) {
      setSupervisionvalidation("");
      setenddatevalidation("");
      save({
        docketId: getTopDynamicDocketid,
        supervisionStartDate: fields?.["Supervision Start Date"],
        days: fields?.Days ? fields?.Days : null,
        months: fields?.Month ? fields?.Month : null,
        endDate: fields?.["End Date"],
        additionalDays: data?.additionalDays ? data?.additionalDays : 0,
        adjustedEndDate: null,
      });
    } else {
      !fields?.["Supervision Start Date"] && setSupervisionvalidation("Please Select Supervision Start Date");
      !fields?.["End Date"] && setenddatevalidation("Please Select End Date");
    }
  };

  useEffect(() => {
    setSupervisionvalidation("");
    setenddatevalidation("");
    setFields({
      "Supervision Start Date": "",
      Month: "",
      Days: "",
      "End Date": "",
    });
    getdata();
  }, [reset]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div
        style={{
          width: "100%",
          height: "548px",
          border: "3px solid var(--button2)",
        }}
        className="CreateAppointmentPopup"
      >
        <ScrollView>
          <div className="RecivePaymentModel_header">
            <div style={{ width: "220px" }} className="RecivePaymentModel_header_name">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1331_396199)">
                  <path d="M0 0H24V24H0V0Z" fill="var(--button2)" />
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1331_396199">
                    <rect width="24" height="24" fill="blue" />
                  </clipPath>
                </defs>
              </svg>
              <p style={{ marginLeft: "-10px" }}>Supervision Dates</p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  DK.8
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setChangeSupervision(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div style={{ width: "94%", marginLeft: "3%" }}>
            <div style={{ width: "40%" }} className="ChangeSupervisionPopup">
              <p className="go-to-label-filed">Supervision Start Date</p>
              <div>
                <DateBox
                  style={{ marginTop: "2%" }}
                  value={fields["Supervision Start Date"]}
                  onValueChange={(e) => {
                    setSupervisionvalidation("");

                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();

                    setFields({
                      ...fields,
                      "Supervision Start Date": e ? utcFormatted : null,
                    });
                  }}
                  useMaskBehavior={true}
                />
                <p style={{ color: "red", position: "absolute" }}>{Supervisionvalidation}</p>
              </div>
            </div>
            <div className="ChangeSupervisionPopup" style={{ width: "40%" }}>
              <p className="go-to-label-filed">Month</p>
              <div>
                <NumberBox
                  onKeyDown={(e) => {
                    const { event } = e;
                    const str = event.key || String.fromCharCode(event.which);
                    if (/^[.,e]$/.test(str)) {
                      event.preventDefault();
                    }
                  }}
                  style={{ marginTop: "2%" }}
                  value={fields.Month}
                  min={0}
                  onValueChange={(e) => {
                    const updatedDate = new Date(data?.supervisionStartDate);
                    updatedDate.setMonth(updatedDate.getMonth() + e);
                    const month = updatedDate.toLocaleDateString("en-US");

                    setFields({
                      ...fields,
                      Month: e,
                      Days: 0,
                      "End Date": month,
                    });
                  }}
                />
              </div>
            </div>
            <p className="fee-payment-content-info-para">OR</p>
            <div className="ChangeSupervisionPopup" style={{ width: "40%" }}>
              <p className="go-to-label-filed">Days</p>
              <div>
                <NumberBox
                  onKeyDown={(e) => {
                    const { event } = e;
                    const str = event.key || String.fromCharCode(event.which);
                    if (/^[.,e]$/.test(str)) {
                      event.preventDefault();
                    }
                  }}
                  style={{ marginTop: "2%" }}
                  value={fields.Days}
                  min={0}
                  onValueChange={(e) => {
                    const result = new Date(data?.supervisionStartDate);
                    result.setDate(result.getDate() + e);
                    setFields({
                      ...fields,
                      Days: e,
                      Month: 0,
                      "End Date": `${result.getMonth() + 1}/${result.getDate()}/${result.getFullYear()}`,
                    });
                  }}
                />
              </div>
            </div>
            <p className="fee-payment-content-info-para">OR</p>
            <div className="ChangeSupervisionPopup" style={{ width: "40%" }}>
              <p className="go-to-label-filed">End Date</p>
              <div>
                <DateBox
                  style={{ marginTop: "2%" }}
                  value={fields["End Date"]}
                  onValueChange={(e) => {
                    setenddatevalidation("");

                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();

                    setFields({
                      ...fields,
                      "End Date": e ? utcFormatted : null,
                      Days: e
                        ? Math.ceil(
                            (new Date(new Date(new Date(e).toLocaleDateString()).toLocaleDateString("en-US")) -
                              new Date(fields?.["Supervision Start Date"])) /
                              (1000 * 3600 * 24)
                          )
                        : 0,
                      Month: 0,
                    });
                  }}
                  useMaskBehavior={true}
                />
                <p style={{ color: "red", position: "absolute" }}>{enddatevalidation}</p>
              </div>
            </div>
            <div className="ChangeSupervisionPopupbottom">
              <div className="ChangeSupervisionPopupbottomsub">
                <p className="go-to-label-filed">Additional Days</p>
                <p style={{ marginLeft: "15px" }}>{data?.additionalDays}</p>
              </div>
              <div className="ChangeSupervisionPopupbottomsub">
                <p className="go-to-label-filed">Adjusted End Date</p>
                <p>{data?.adjustedEndDate && new Date(data?.adjustedEndDate).toLocaleDateString("en-US")}</p>
              </div>
            </div>
            <div
              className="popup_buttons"
              style={{
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <button
                className="primary_btn"
                onClick={() => {
                  saveas();
                }}
              >
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setChangeSupervision(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
