import { useEffect, useState } from "react";
import { Table1 } from "../Administration/Table1/Table1";
import { formsTableColumn, setFormsTableColumnData } from "./Data";
import "./forms.css";
import { useNavigate } from "react-router-dom";
import { handleGetLocal } from "../../services/auth-service";
import axios from "axios";
import { logOut } from "../../components/Logout";
import { ApiError } from "../Error/ApiError";
import { Toster } from "../../components/Toster/Toster";
import { LoadPanel } from "devextreme-react";
import { FormsTabs } from "./FormsTabs";
import { Popup } from "devextreme-react";
import PersonModal from "../PersonFind/PersonModal";
import { FormsConfirmation } from "./FormsPopup/FormsConfirmation";
import { NoteConfirmation } from "./FormsPopup/NoteConfirmation";
import { CreateNewPerson } from "./FormsPopup/CreateNewPerson";
import { RemovePersonList } from "./FormsPopup/RemovePersonList";

export const Forms = () => {
  const [loading, setLoading] = useState(false);
  const [formsTableData, setFormsTableData] = useState([]);
  const [formsHandling, setFormsHandling] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const navigate = useNavigate();
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [addExistingPersonPopup, setAddExistingPersonPopup] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [formRowData, setFormRowData] = useState([]);
  const [confirmationData, setConfirmationData] = useState({});
  const [formsPageMenu, setFormsPageMenu] = useState(true);
  const [noteConfirmationPopup, setNoteConfirmationPopup] = useState(false);
  const [createNewPersonPopup, setCreateNewPersonPopup] = useState(false);
  const [formsRowType, setFormsRowType] = useState("");
  const [removePersonListPopup, setRemovePersonListPopup] = useState(false);
  const HandleClose = () => {
    if (setFormsPageMenu) {
      setFormsPageMenu(true);
    }
    setAddExistingPersonPopup(false);
  };

  const getFeeScheduleTableDataApi = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Forms/get-formdata`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setFormsTableData(setFormsTableColumnData(val?.data?.data));
        } else {
          setFormsHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setFormsHandling(true);
        }
      });
  };

  useEffect(() => {
    getFeeScheduleTableDataApi();
  }, []);

  const removeFormsListApi = async (gFormId) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Forms/remove-fromlist?gFormId=${gFormId}`, "", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Deleted!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Deleted!",
            type: "success",
          });
        }, 1900);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const viewFormPdfApi = async (gFormId, fileName) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Forms/view-form?gFormID=${gFormId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
        responseType: "blob",
      })
      .then(async (val) => {
        setLoading(false);
        const pdfBlob = new Blob([val.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdfUrl;
        downloadLink.download = fileName;
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <Popup
        visible={addExistingPersonPopup}
        onHiding={HandleClose}
        showTitle={false}
        width="90%"
        height={634}
        showCloseButton={true}
        hideOnOutsideClick={true}
        onShown={() => {
          document.querySelector("#TESTE").focus();
        }}
      >
        <PersonModal
          setShowPersonModal={setAddExistingPersonPopup}
          nojump={false}
          formjump={true}
          setConfirmationPopup={setConfirmationPopup}
          setConfirmationData={setConfirmationData}
          setFormsPageMenu={setFormsPageMenu}
        />
      </Popup>
      <Popup
        visible={confirmationPopup}
        onHiding={() => {
          setConfirmationPopup(false);
        }}
        showTitle={false}
        width={700}
        height={260}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <FormsConfirmation
          confirmationPopup={confirmationPopup}
          setConfirmationPopup={setConfirmationPopup}
          confirmationData={confirmationData}
          formRowData={formRowData}
          setLoading={setLoading}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setAddExistingPersonPopup={setAddExistingPersonPopup}
          setNoteConfirmationPopup={setNoteConfirmationPopup}
        />
      </Popup>
      <Popup
        visible={noteConfirmationPopup}
        onHiding={() => {
          setNoteConfirmationPopup(false);
        }}
        showTitle={false}
        width={480}
        height={150}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <NoteConfirmation
          noteConfirmationPopup={noteConfirmationPopup}
          setNoteConfirmationPopup={setNoteConfirmationPopup}
          confirmationData={confirmationData}
          formsRowType={formsRowType}
          formRowData={formRowData}
        />
      </Popup>
      <Popup
        visible={createNewPersonPopup}
        onHiding={() => {
          setCreateNewPersonPopup(false);
        }}
        showTitle={false}
        width={700}
        height={200}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <CreateNewPerson
          createNewPersonPopup={createNewPersonPopup}
          setCreateNewPersonPopup={setCreateNewPersonPopup}
          formRowData={formRowData}
          setLoading={setLoading}
          setNoteConfirmationPopup={setNoteConfirmationPopup}
        />
      </Popup>
      <Popup
        visible={removePersonListPopup}
        onHiding={() => {
          setRemovePersonListPopup(false);
        }}
        showTitle={false}
        width={600}
        height={200}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <RemovePersonList
          removePersonListPopup={removePersonListPopup}
          setRemovePersonListPopup={setRemovePersonListPopup}
          setConfirmationPopup={setConfirmationPopup}
          setConfirmationData={setConfirmationData}
          removeFormsListApi={removeFormsListApi}
          getFeeScheduleTableDataApi={getFeeScheduleTableDataApi}
          formRowData={formRowData}
        />
      </Popup>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="page-section">
        <FormsTabs />
        <div className="page-content">
          <p className="page-info-number">MF.1</p>
          <div className="buttons">
            <button
              className="btn"
              onClick={() => {
                getFeeScheduleTableDataApi();
              }}
            >
              Refresh
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_6247_15093)">
                  <path
                    d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6247_15093">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          {formsHandling ? (
            <ApiError />
          ) : (
            <Table1
              editTable={false}
              height={800}
              Tablecoloum={formsTableColumn}
              tableRow={formsTableData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={loading}
              coloumWidthText={"Forms"}
              mandatoryColoum={"Status"}
              formsPageMenu={formsPageMenu}
              setFormsPageMenu={setFormsPageMenu}
              removeFormsListApi={removeFormsListApi}
              getFeeScheduleTableDataApi={getFeeScheduleTableDataApi}
              setAddExistingPersonPopup={setAddExistingPersonPopup}
              formRowData={formRowData}
              setFormRowData={setFormRowData}
              setCreateNewPersonPopup={setCreateNewPersonPopup}
              setFormsRowType={setFormsRowType}
              viewFormPdfApi={viewFormPdfApi}
              setRemovePersonListPopup={setRemovePersonListPopup}
            />
          )}
        </div>
      </div>
    </>
  );
};
