import React, { useCallback, useEffect, useState } from "react";
import "../popup-module.css";
import { DataGrid, DateBox, DropDownBox, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

export const ProgramEditPopup = ({
  programEditPopup,
  setProgramEditPopup,
  setLoad,
  toastConfig,
  setToastConfig,
  userinfo,
  editProgramData,
  dropdownData,
  docketDropdownData,
  getProgramListApi,
  dockertDetail,
  gotoProgram,
  typeProgram,
  dockerHeaderData,
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [fields, setFields] = useState({
    programCode: {
      id: "",
      name: "",
    },
    referralDate: "",
    contactPerson: "",
    initialAppointment: "",
    programStart: "",
    completeBy: "",
    programEnd: "",
    endReason: {
      id: "",
      name: "",
    },
    evaluationDate: "",
    evaluationReceived: "",
    comment: "",
  });
  const [programCodeValidation, setProgramCodeValidation] = useState("");
  const [referralDateValidation, setReferralDateValidation] = useState("");
  const [programStartValidation, setProgramStartValidation] = useState("");
  const [completeByValidation, setCompleteByValidation] = useState("");
  const [endReasonValidation, setEndReasonValidation] = useState("");
  const [contactPersonValidation, setContactPersonValidation] = useState("");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "contactPerson") {
      setContactPersonValidation("");
    }
  };

  const findProgramCode = () => {
    const matchingStatus = dropdownData?.programCode?.find(
      (status) => status.value === editProgramData?.[`Program Code`]
    );
    const programCodeId = matchingStatus ? matchingStatus.key : "";
    setFields((prevFields) => ({
      ...prevFields,
      programCode: {
        ...prevFields.programCode,
        name: editProgramData?.[`Program Code`],
        id: programCodeId,
      },
    }));
  };

  const findEndReason = () => {
    const matchingStatus = dropdownData?.endReason?.find((status) => status.value === editProgramData?.[`End Reason`]);
    const endReasonId = matchingStatus ? matchingStatus.key : "";
    setFields((prevFields) => ({
      ...prevFields,
      endReason: {
        ...prevFields.endReason,
        name: editProgramData?.[`End Reason`],
        id: endReasonId,
      },
    }));
  };

  useEffect(() => {
    // setSelectedValue({
    //   Court: "",
    //   "Docket #": editProgramData?.[`Docket #`],
    //   "Sentence Date": "",
    //   "Supervision Status": "",
    //   gDocketId: editProgramData?.gDocketId,
    // });
    setProgramCodeValidation("");
    setReferralDateValidation("");
    setProgramStartValidation("");
    setCompleteByValidation("");
    setEndReasonValidation("");
    setContactPersonValidation("");
    if (typeProgram === "Add") {
      console.log(dockerHeaderData?.dSentenceAdjustedEnd);
      setFields({
        programCode: {
          id: "",
          name: "",
        },
        referralDate: "",
        contactPerson: "",
        initialAppointment: "",
        programStart: "",
        completeBy: dockerHeaderData?.dSentenceAdjustedEnd,
        programEnd: "",
        endReason: {
          id: "",
          name: "",
        },
        evaluationDate: "",
        evaluationReceived: "",
        comment: "",
      });
      const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
      setSelectedValue(selectedDocket);
    } else {
      const selectedDocket = dataSource?.find((item) => item.gDocketId === editProgramData?.gDocketId);
      setSelectedValue(dataSource[0]);
      setFields((prev) => ({
        ...prev,
        programCode: { name: editProgramData?.[`Program Code`], id: "" },
        referralDate: editProgramData?.[`Referral Date`] ? editProgramData?.[`Referral Date`] : "",
        contactPerson: editProgramData?.[`Contact Person`] || "",
        initialAppointment: editProgramData?.[`Initial Appointment`] ? editProgramData?.[`Initial Appointment`] : "",
        programStart: editProgramData?.[`Program Start`] ? editProgramData?.[`Program Start`] : "",
        completeBy: editProgramData?.[`Complete By`] ? editProgramData?.[`Complete By`] : "",
        programEnd: editProgramData?.[`Program End`] ? editProgramData?.[`Program End`] : "",
        endReason: { name: editProgramData?.[`End Reason`], id: "" },
        evaluationDate: editProgramData?.[`Evaluation Date`] ? editProgramData?.[`Evaluation Date`] : "",
        evaluationReceived: editProgramData?.[`Evaluation Received`] ? editProgramData?.[`Evaluation Received`] : "",
        comment: editProgramData?.Comments,
      }));
      findProgramCode();
      findEndReason();
      const fetchedDataSource = docketDropdownData?.map((val) => {
        const convertDateFormat = (dateString) => {
          const dateObj = new Date(dateString);
          return dateObj.toLocaleDateString("en-US");
        };
        return {
          Court: val?.sCourtName,
          "Docket #": val?.sDocketNumberCourtCounts,
          "Sentence Date": convertDateFormat(val?.dSentence),
          "Supervision Status": val?.sSupervisionStatusName,
          gDocketId: val?.gDocketId,
        };
      });
      setDataSource(fetchedDataSource);
    }
  }, [editProgramData, programEditPopup]);

  const data = {
    GProgramId: editProgramData?.gProgramId,
    GEntryUserId: editProgramData?.gEntryUserId,
    GDocketId: selectedValue?.gDocketId,
    GProgramCodeId: fields.programCode.id,
    DReferral: fields.referralDate,
    SContact: fields.contactPerson,
    DAppointment: fields.initialAppointment,
    DStart: fields.programStart,
    DEnd: fields.programEnd,
    GProgramEndReasonId: fields.endReason.id,
    DEvaluation: fields.evaluationDate,
    DEvaluationReceived: fields.evaluationReceived,
    SComment: fields.comment,
    SPinkey: null,
    GPersonId: null,
    DCompleteBy: fields.completeBy,
  };
  const navigate = useNavigate();

  const handleSave = async () => {
    let isValid = true;
    if (!fields.programCode.id) {
      setProgramCodeValidation("Please Select Program Code");
      isValid = false;
    }
    if (fields.referralDate === "") {
      setReferralDateValidation("Please Select Referral Date");
      isValid = false;
    }
    if (fields.programStart === "") {
      setProgramStartValidation("Please Select Program Start");
      isValid = false;
    }
    if (fields.programStart && fields.completeBy === "") {
      setCompleteByValidation("Please Select Complete By");
      isValid = false;
    }
    if (fields.programEnd && fields.endReason.id === "") {
      setEndReasonValidation("Please Select End Reason");
      isValid = false;
    }
    if (fields.endReason.id && fields.contactPerson === "") {
      setContactPersonValidation("Please Enter Contact Person");
      isValid = false;
    }
    if (fields.programStart && fields.completeBy && new Date(fields.programStart) >= new Date(fields.completeBy)) {
      setCompleteByValidation("Complete By Is Greater Than Program Start");
      isValid = false;
    }
    if (isValid) {
      setLoad(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/Programs/addEditPrograms`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoad(false);
          setProgramEditPopup(false);
          getProgramListApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        })
        .catch((val) => {
          setLoad(false);
          if (val?.response?.status === 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <div className="program_editpopup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              {/* <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 15.4586V18.4986C0 18.7786 0.22 18.9986 0.5 18.9986H3.54C3.67 18.9986 3.8 18.9486 3.89 18.8486L14.81 7.93859L11.06 4.18859L0.15 15.0986C0.0500001 15.1986 0 15.3186 0 15.4586ZM17.71 5.03859C18.1 4.64859 18.1 4.01859 17.71 3.62859L15.37 1.28859C14.98 0.898594 14.35 0.898594 13.96 1.28859L12.13 3.11859L15.88 6.86859L17.71 5.03859Z"
                  fill="white"
                />
              </svg> */}
              <p>Program</p>
            </div>
            <p class="popup_header_title_para">PM.1</p>
          </div>
          <svg
            onClick={() => {
              setProgramEditPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="program_edit_content">
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Docket</p>
              <DropDownBox
                placeholder="Select..."
                disabled={gotoProgram ? true : false}
                dataSource={dataSource}
                value={selectedValue?.[`Docket #`]}
                valueExpr={"Docket #"}
                displayExpr={"Docket #"}
                opened={isGridBoxOpened}
                onOptionChanged={onGridBoxOpened}
                contentRender={(value, element) => (
                  <DataGrid
                    dataSource={dataSource}
                    hoverStateEnabled={true}
                    height={180}
                    onRowClick={(e) => {
                      setSelectedValue(e?.data);
                      setIsGridBoxOpened(false);
                    }}
                  >
                    <Selection mode="single" />
                    <FilterRow visible={true} />
                    <Column dataField="Court" caption="Court" width={"auto"} />
                    <Column dataField="Docket #" caption="Docket #" width={"auto"} />
                    <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                    <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                  </DataGrid>
                )}
              />
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Program Code <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"programCode"}
                dropDownData={dropdownData?.programCode?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setProgramCodeValidation}
              />
              {programCodeValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "102px" }}>
                  {programCodeValidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Referral Date <span className="marks_mandatary">*</span>
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.referralDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    referralDate: e,
                  }));
                  setReferralDateValidation("");
                }}
              />
              {referralDateValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "191px" }}>
                  {referralDateValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Contact Person
                {fields.endReason.id && <span className="marks_mandatary">*</span>}
              </p>
              <input
                id="contactPerson"
                name="contactPerson"
                type="text"
                placeholder="Enter Contact Person"
                className="edit_container_input"
                value={fields.contactPerson}
                onChange={handleInputChange}
              />
              {contactPersonValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "191px" }}>
                  {contactPersonValidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Initial Appointment</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.initialAppointment}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    initialAppointment: e,
                  }));
                }}
              />
            </div>
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Program Start <span className="marks_mandatary">*</span>
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.programStart}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    programStart: e,
                  }));
                  setProgramStartValidation("");
                }}
              />
              {programStartValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "284px" }}>
                  {programStartValidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Complete By
                {fields.programStart && <span className="marks_mandatary">*</span>}
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.completeBy}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    completeBy: e,
                  }));
                  setCompleteByValidation("");
                }}
              />
              {completeByValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "372px" }}>
                  {completeByValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex">
              <p className="edit_container_label">Program End</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.programEnd}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    programEnd: e,
                  }));
                }}
              />
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                End Reason
                {fields.programEnd && <span className="marks_mandatary">*</span>}
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"endReason"}
                dropDownData={dropdownData?.endReason?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setEndReasonValidation}
              />
              {endReasonValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "465px" }}>
                  {endReasonValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex">
              <p className="edit_container_label">Evaluation Date</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.evaluationDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    evaluationDate: e,
                  }));
                }}
              />
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Evaluation Received</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.evaluationReceived}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    evaluationReceived: e,
                  }));
                }}
              />
            </div>
          </div>
          <div className="edit_container_second edit_content_flex">
            <p className="edit_container_label">Comments</p>
            <textarea
              name="comment"
              id="comment"
              value={fields.comment}
              onChange={handleInputChange}
              placeholder="Write Comments"
              className="dark-mode-textarea"
            ></textarea>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setProgramEditPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
