import { useEffect, useState } from "react";
import "./style/administration.css";
import { Popup } from "devextreme-react/popup";
import { Edit } from "./Popup/Edit";
import { Add } from "./Popup/Add";
import { Delete } from "./Popup/Delete";
import { getAuthToken } from "../../services/auth-service";
import httpService from "../../services/http-service";
import { useDispatch, useSelector } from "react-redux";
import {
  reset_table_data,
  set_admin_table,
  set_admin_table_name,
  set_selected_row,
} from "../../reducer/AdminReadReducer";
import { Table2 } from "../../components/Table2/Table2";
import { useNavigate } from "react-router-dom";
import AdminTabs from "./components/AdminTabs";
import { ApiError } from "../Error/ApiError";
import { logOut } from "../../components/Logout";
import { SelectBox } from "devextreme-react";
import AdminCrumbs from "./components/AdminCrumbs";

export const Administration = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popup, setpopUp] = useState(0);
  const { headers_title, table_id, replace_headers_title, transformedData } =
    useSelector((store) => store.AdminTable);
  const title = "Basic Data Editor";
  let headers = headers_title?.map((item) => {
    return {
      title: item,
    };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);

  let domainName = (url) =>
    url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathURL = domainName(domailURL);
  const token = getAuthToken();

  const showTDDropdown = async (e) => {
    try {
      const response = await httpService.get(
        `api/Administration/BDEDropdownValues`,
        {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
        }
      );
      if (response.data.isSuccess) {
        seterrorhandling(false);
        setDropDownData(response.data.data);
      } else {
        seterrorhandling(true);
      }
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      } else {
        seterrorhandling(true);
      }
    }
  };

  const tableDataApi = async (tableId) => {
    try {
      const response = await httpService.get(
        `api/Administration/BDETableDataValuesGet?basicdataid=${tableId}`,
        {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
        }
      );
      if (response.data.isSuccess) {
        setLoading(false);
        dispatch(
          set_admin_table({
            columnHeaders: response.data.columns.split(","),
            replace_headers_title: response.data.caption.split(","),
            data: response?.data?.data,
            table_id: tableId,
            foreignKeyData: response?.data?.foreignKeyData,
          })
        );
      } else {
        setLoading(true);
        dispatch(
          set_admin_table({
            replace_headers_title: [],
          })
        );
      }
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      } else {
        seterrorhandling(true);
      }
    }
  };

  useEffect(() => {
    showTDDropdown();
  }, []);

  useEffect(() => {
    // Clean up the Redux state when the component unmounts
    return () => {
      dispatch(reset_table_data());
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user_designation") !== "Administrator") {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Popup
        visible={isPopupVisible}
        onHiding={() => {
          setIsPopupVisible(false);
          dispatch(
            set_selected_row({
              selected_row: {},
            })
          );
        }}
        showTitle={false}
        width={600}
        height={popup === 2 ? 200 : 350}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        {popup === 1 && (
          <Edit
            setIsPopupVisible={setIsPopupVisible}
            tableDataApi={tableDataApi}
          />
        )}
        {popup === 2 && (
          <Delete
            setIsPopupVisible={setIsPopupVisible}
            tableDataApi={tableDataApi}
          />
        )}
        {popup === 3 && (
          <Add
            setIsPopupVisible={setIsPopupVisible}
            transformedData={transformedData}
            tableDataApi={tableDataApi}
          />
        )}
      </Popup>

      <AdminCrumbs title={"Basic Data Editor"} user={"Data"} />
      <div className="administration">
        <AdminTabs title={title} />
        <div className="screen_num" style={{ margin: "1%" }}>
          <p>UT.8</p>
        </div>
        <div className="administration_block_2">
          <div className="administration_block_2_sub">
            <p className="go-to-label-filed">Table</p>
            <SelectBox
              dataSource={dropDownData.map((vall) => {
                return {
                  name: vall?.sName,
                  id: vall?.gBasicDataId,
                  tableName: vall?.sTableName,
                };
              })}
              placeholder="Select Table"
              valueExpr="name"
              displayExpr="name"
              searchEnabled={true}
              searchExpr={"name"}
              dropDownOptions={{ height: "180px" }}
              onItemClick={(e) => {
                tableDataApi(e?.itemData?.id);
                dispatch(
                  set_admin_table_name({ tableName: e?.itemData?.tableName })
                );
                localStorage.removeItem("adminbasicediterTable");
              }}
              style={{ width: "350px" }}
            />
          </div>
          {table_id ? (
            <>
              <button
                className="add_table_data"
                onClick={() => {
                  setpopUp(3);
                  setIsPopupVisible(true);
                }}
              >
                Add&nbsp;&nbsp;
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                    fill="white"
                  />
                </svg>
              </button>
            </>
          ) : (
            ""
          )}
        </div>
        {errorhandling ? (
          <ApiError />
        ) : (
          <div
            className="admin_tableData"
            style={{ margin: "1%", marginTop: "2%" }}
          >
            {headers.length > 0 ? (
              <>
                {replace_headers_title && (
                  <Table2
                    Tablecoloum={["Actions", ...replace_headers_title].map(
                      (val) => {
                        if (val === "Actions") {
                          return { title: val, action: true };
                        } else {
                          return { title: val };
                        }
                      }
                    )}
                    tableRow={transformedData.map((val, i) => {
                      return { ...val, ID: i };
                    })}
                    load={loading}
                    coloumWidthText={"adminbasicediterTable"}
                    height={610}
                    editTable={false}
                    mandatoryColoum={replace_headers_title[0]}
                    setpopUp={setpopUp}
                    setIsPopupVisible={setIsPopupVisible}
                    set_selected_row={set_selected_row}
                    showTotalBlock={false}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
};
