import axios from "axios";
import { Table2 } from "../../../../components/Table2/Table2";
import { handleGetLocal } from "../../../../services/auth-service";
import "./reportdate.css";
import { useState } from "react";
import { useEffect } from "react";
import { LoadPanel, Popup } from "devextreme-react";
import { CreateAppointment } from "./CreateAppointment";
import { useDispatch } from "react-redux";
import { setresetReportDates } from "../../../../reducer/participantNoteReducer";
import { useSelector } from "react-redux";
import { EnterAttendence } from "./EnterAttendence";
import { VoidPopup } from "./VoidPopup";
import { Toster } from "../../../../components/Toster/Toster";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../Error/ApiError";

const Tablecoloum = [
  { title: "", moreoption: true, customwidth: "50px" },
  {
    title: "Attendance",
  },
  { title: "Void", bool: true },
  {
    title: "FTR",
    bool: true,
  },
  { title: "Report Date", datetime: true },
  {
    title: "Report Type",
  },
  {
    title: "Pre-Report Note",
  },
  {
    title: "Resource",
  },
  {
    title: "Post Report Note",
  },
  {
    title: "Location",
  },
];

const setTableData = (dataa) => {
  const arr = [];
  dataa.map((val) => {
    arr.push({
      Attendance: val?.sAttendanceName,
      Void: val?.bVoid,
      FTR: val?.bShowFtr,
      "Report Date": val?.dReport,
      "Report Type": val?.sReportTypeName,
      "Pre-Report Note": val?.sPreReportNote,
      Resource: val?.sResource,
      "Post Report Note": val?.sPostReportNote,
      Location: val?.sLocation,
      ID: val?.gReportDateScheduleId,
      sOffenderNumber: val?.sOffenderNumber,
      gPreReportActivityNoteId: val?.gPreReportActivityNoteId,
    });
  });

  return arr;
};

const PopUp = ({ setreportingData, reportingData, userinfo, sentanceNote, interval, toastConfig, setToastConfig }) => {
  const dispatch = useDispatch();
  const { resetReportDates } = useSelector((store) => store.participateNotes);
  return (
    <>
      <Popup
        visible={reportingData}
        onHiding={() => {
          dispatch(setresetReportDates(!resetReportDates));
          setreportingData(false);
        }}
        showTitle={false}
        width={700}
        height={650}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <CreateAppointment
          setreportingData={setreportingData}
          userinfo={userinfo}
          sentanceNote={sentanceNote}
          interval={interval}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
    </>
  );
};

const EnterAttendencePopup = ({
  setenterAttendencePopup,
  enterAttendencePopup,
  userinfo,
  reportDataId,
  sentanceNote,
  PostReportNote,
  toastConfig,
  setToastConfig,
}) => {
  const dispatch = useDispatch();
  const { resetReportDates } = useSelector((store) => store.participateNotes);
  return (
    <>
      <Popup
        visible={enterAttendencePopup}
        onHiding={() => {
          dispatch(setresetReportDates(!resetReportDates));
          setenterAttendencePopup(false);
        }}
        showTitle={false}
        width={700}
        height={900}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <EnterAttendence
          setenterAttendencePopup={setenterAttendencePopup}
          userinfo={userinfo}
          reportDataId={reportDataId}
          sentanceNote={sentanceNote}
          PostReportNote={PostReportNote}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
    </>
  );
};

const Void = ({ setvoidPopup, voidPopup, userinfo, reportDataId, toastConfig, setToastConfig }) => {
  const { resetReportDates } = useSelector((store) => store.participateNotes);
  const dispatch = useDispatch();
  return (
    <>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          dispatch(setresetReportDates(!resetReportDates));
          setvoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidPopup
          setvoidPopup={setvoidPopup}
          userinfo={userinfo}
          reportDataId={reportDataId}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
    </>
  );
};

export const ReportDates = ({ userinfo, editParticipant }) => {
  const pathname = handleGetLocal("pathURL");
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportingData, setreportingData] = useState(false);
  const { on_Save_Edit, resetReportDates } = useSelector((store) => store.participateNotes);
  const [enterAttendencePopup, setenterAttendencePopup] = useState(false);
  const [voidPopup, setvoidPopup] = useState(false);
  const [reportDataId, setreportDataId] = useState("");
  const [removeAttendenceStatus, setremoveAttendenceStatus] = useState(true);
  const [sentanceNote, setsentanceNote] = useState("");
  const [interval, setinterval] = useState("");
  const [PostReportNote, setPostReportNote] = useState("");
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);
  const [errorhandling2, seterrorhandling2] = useState(false);
  const [errorhandling3, seterrorhandling3] = useState(false);

  const getTableData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/PersonReportDates/getAllAppointment?gPersonId=${userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        seterrorhandling(false);
        setLoading(false);
        setdata(setTableData(val.data.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const removeAttendenceStatushhit = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/PersonReportDates/remove-attendance-status?reportDateScheduleId=${reportDataId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        seterrorhandling2(false);
        if (val.data.isSuccess) {
          getTableData();
        }
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling2(true);
        }
      });
  };

  const gettimeinterval = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/PersonReportDates/get-notes-interval?personId=${userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        seterrorhandling3(false);
        setsentanceNote(val.data.data.sentenceNotes);
        setinterval(val.data.data.timeInterval);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling3(true);
        }
      });
  };
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    getTableData();
  }, [on_Save_Edit, userinfo]);

  useEffect(() => {
    gettimeinterval();
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    reportDataId && removeAttendenceStatushhit();
  }, [removeAttendenceStatus]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <PopUp
        reportingData={reportingData}
        setreportingData={setreportingData}
        userinfo={userinfo}
        sentanceNote={sentanceNote}
        interval={interval}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
      />
      <EnterAttendencePopup
        enterAttendencePopup={enterAttendencePopup}
        setenterAttendencePopup={setenterAttendencePopup}
        userinfo={userinfo}
        reportDataId={reportDataId}
        sentanceNote={sentanceNote}
        PostReportNote={PostReportNote}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
      />
      <Void
        setvoidPopup={setvoidPopup}
        voidPopup={voidPopup}
        userinfo={userinfo}
        reportDataId={reportDataId}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
      />
      {errorhandling || errorhandling2 || errorhandling3 ? (
        <ApiError />
      ) : (
        <div style={{ height: "720px" }}>
          <div className="createApoimentPopup">
            <button
              onClick={() => {
                editParticipant && dispatch(setresetReportDates(!resetReportDates));
                editParticipant && setreportingData(true);
              }}
            >
              <p style={{ fontWeight: "bold" }}>Create Appointment</p>
              <svg
                className="svg-blue-white"
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 2H17V1C17 0.45 16.55 0 16 0C15.45 0 15 0.45 15 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM17 20H3C2.45 20 2 19.55 2 19V7H18V19C18 19.55 17.55 20 17 20Z"
                  fill="#424167"
                />
              </svg>
            </button>
          </div>
          <div className="ReportDates_table">
            <Table2
              editTable={false}
              Tablecoloum={Tablecoloum}
              tableRow={data}
              load={false}
              coloumWidthText={"ReportDates_table"}
              height={620}
              mandatoryColoum={"Report Date"}
              showTotalBlock={false}
              Contextmenu={editParticipant ? true : false}
              setenterAttendencePopup={setenterAttendencePopup}
              setreportDataId={setreportDataId}
              setvoidPopup={setvoidPopup}
              setremoveAttendenceStatus={setremoveAttendenceStatus}
              removeAttendenceStatus={removeAttendenceStatus}
              editParticipant={editParticipant}
              setPostReportNote={setPostReportNote}
              setVoideFalse={true}
            />
          </div>
        </div>
      )}
    </>
  );
};
