import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { Obligation } from "./Obligation";
import { Block1, Block2, Block3, Block4, HeaderTab, TopDynamicDocket } from "./Components";
import { DataTab } from "./DataTab";
import { SupervisionTab } from "./SupervisionTab";
import { ChecklistTab } from "./ChecklistTab";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { Popup } from "devextreme-react";
import { Drugtextcondition } from "./Drugtextcondition";
import { Toster } from "../../../../../components/Toster/Toster";
import { set_dynamicParticipatTab } from "../../../../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { setdockerValue } from "../../../../../reducer/participantNoteReducer";

const EditDRuogTextPOpup = ({
  EditDRuogTextPOpuptogal,
  setEditDRuogTextPOpuptogal,
  reset,
  toastConfig,
  setToastConfig,
  getTopDynamicDocketid,
  drugDropdownData,
  setreset,
}) => {
  return (
    <>
      <Popup
        visible={EditDRuogTextPOpuptogal}
        onHiding={() => {
          setEditDRuogTextPOpuptogal(false);
        }}
        showTitle={false}
        width={500}
        height={470}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <Drugtextcondition
          setEditDRuogTextPOpuptogal={setEditDRuogTextPOpuptogal}
          reset={reset}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          getTopDynamicDocketid={getTopDynamicDocketid}
          drugDropdownData={drugDropdownData}
          setreset={setreset}
        />
      </Popup>
    </>
  );
};

const setTableData = (dataa) => {
  const arr = [];
  dataa?.map((val, i) => {
    arr.push({
      ID: i,
      "Obligation Start": val?.dDoe,
      "Pay Plan Start": val?.dPaymentPlanStart,
      "Obligation Type": val?.sObligationTypeName,
      "Fin Status": val?.sFinancialStatusName,
      "!": val?.bHasPaymentPlan,
      "Recipient Category": val?.sRecipientCategoryName,
      "Fee Code": val?.sFeeName,
      Priority: val?.iPayPriority,
      Amount: val?.fAmount,
      Paid: val?.fPaid,
      "On Hand": val?.fDisburseEligible,
      "Written Off": val?.fWriteOff,
      "Balance Owed": val?.fBalance,
      Disbursed: val?.fDisburseEligible,
      "Obligation Ref#": val?.sReference,
      Arrears: val?.fArrears,
      DOE: val?.dDoe,
      "Entered by": val?.sEnteredBy,
      gPersonId: val?.gPersonId,
      sFinancialStatusName: val?.sFinancialStatusName,
      gObligationId: val?.gObligationId,
      sDocketNumberCourtCounts: val?.sDocketNumberCourtCounts,
      userinfo: val,
    });
  });

  return arr;
};

const Header = ({ header }) => {
  return (
    <div className="docketoverviewheader">
      <Block1 header={header} />
      <Block2 header={header} />
      <Block3 header={header} />
      <Block4 header={header} />
    </div>
  );
};

export const CommonOverViewTab = ({ docketId, data, userinfo, dropdownData }) => {
  const [Data, setData] = useState(["0bligation", "Dates", "Supervision", "Checklist"]);
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const [getTopDynamicDocket, setTopDynamicDocket] = useState([]);
  const [getTopDynamicDocketid, setTopDynamicDocketid] = useState("");
  const [header, setheader] = useState({});
  const [loading, setLoading] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const [obligations, setobligations] = useState([]);
  const [bottomdynamictab, setbottomdynamictab] = useState();
  const [callObligationApi, setCallObligationApi] = useState(true);
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);
  const [EditDRuogTextPOpuptogal, setEditDRuogTextPOpuptogal] = useState(false);
  const getbottomtabData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-docket-tabs?personId=${userinfo?.gPersonId}&docketId=${getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setobligations(setTableData(val?.data?.data?.obligations));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const [reset, setreset] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const [drugDropdownData, setDrugDropdownData] = useState([]);
  const [text, settext] = useState();

  const getDrugTestDropdownData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/DrugTest/DrugTestDropdownAsync?docketId=${getTopDynamicDocketid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        settext(val?.data?.data?.sConditionText);
        setDrugDropdownData(
          val?.data?.data?.drugTestConditionFrequency?.map((val) => {
            return {
              name: val?.value,
              id: val?.key,
            };
          })
        );
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    if (userinfo?.gPersonId) {
      docketId && getbottomtabData();
    }
  }, [userinfo, getTopDynamicDocketid, callObligationApi]);

  useEffect(() => {
    if (docketId && data.length) {
      const arr = [];
      data.map((val) => {
        if (val.gSupervisionStatusId === docketId) {
          arr.push(val);
        }
      });
      setTab(0);
      setTopDynamicDocket(arr);
      setTopDynamicDocketid(data[0]?.gDocketId);
    }
  }, [docketId, data]);

  useEffect(() => {
    if (getTopDynamicDocket.length) {
      getTopDynamicDocket.map((val) => {
        if (val.gDocketId === getTopDynamicDocketid) {
          setheader(val);
        }
      });
    }
  }, [getTopDynamicDocketid, getTopDynamicDocket]);

  useEffect(() => {
    getTopDynamicDocketid && getDrugTestDropdownData();
  }, [getTopDynamicDocketid, reset]);

  return (
    <>
      <div>
        <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
        <EditDRuogTextPOpup
          EditDRuogTextPOpuptogal={EditDRuogTextPOpuptogal}
          setEditDRuogTextPOpuptogal={setEditDRuogTextPOpuptogal}
          reset={reset}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          getTopDynamicDocketid={getTopDynamicDocketid}
          drugDropdownData={drugDropdownData}
          setreset={setreset}
        />
        <TopDynamicDocket
          setTopDynamicDocketid={setTopDynamicDocketid}
          data={getTopDynamicDocket}
          tab={tab}
          setTab={setTab}
        />
        <button
          onClick={() => {
            dispatch(
              set_dynamicParticipatTab({
                Offender: `/docket/${header?.sDocketNumberCourtCounts?.replace(/\//g, "-")}`,
                title: `Docket ${header?.sDocketNumberCourtCounts}`,
                sDocketNumberCourtCounts: header?.sDocketNumberCourtCounts,
                userinfo: userinfo,
                getTopDynamicDocketid: getTopDynamicDocketid,
              })
            );
            dispatch(
              setdockerValue({
                getTopDynamicDocketid: getTopDynamicDocketid,
                userinfo: userinfo,
              })
            );
            navigate(`/docket/${header?.gDocketId}`);
          }}
          style={{
            width: "auto",
            display: "flex",
            marginTop: "10px",
            cursor: "pointer",
            background: "var(--button-lightblack-lightblue)",
          }}
        >
          <p style={{ color: "var(--TextColor)", fontWeight: "bold" }}>Go to Docket</p>
          <svg
            className="svg-blue-white"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="arrow_forward" clipPath="url(#clip0_5440_11577)">
              <path
                id="Vector"
                d="M5 13.0007H16.17L11.29 17.8807C10.9 18.2707 10.9 18.9107 11.29 19.3007C11.68 19.6907 12.31 19.6907 12.7 19.3007L19.29 12.7107C19.68 12.3207 19.68 11.6907 19.29 11.3007L12.71 4.7007C12.32 4.3107 11.69 4.3107 11.3 4.7007C10.91 5.0907 10.91 5.7207 11.3 6.1107L16.17 11.0007H5C4.45 11.0007 4 11.4507 4 12.0007C4 12.5507 4.45 13.0007 5 13.0007Z"
                fill="#424167"
              />
            </g>
            <defs>
              <clipPath id="clip0_5440_11577">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <Header header={header} />
        <div>
          {text && (
            <p
              style={{
                display: "flex",
                alignItems: "center",
                color: "var(--TextColor)",
                fontWeight: "bold",
              }}
            >
              {text}
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setreset(!reset);
                  setEditDRuogTextPOpuptogal(true);
                }}
              >
                <g clipPath="url(#clip0_3919_390729)">
                  <path
                    d="M2.75 13.093V15.373C2.75 15.583 2.915 15.748 3.125 15.748H5.405C5.5025 15.748 5.6 15.7105 5.6675 15.6355L13.8575 7.45297L11.045 4.64047L2.8625 12.823C2.7875 12.898 2.75 12.988 2.75 13.093ZM16.0325 5.27797C16.325 4.98547 16.325 4.51297 16.0325 4.22047L14.2775 2.46547C13.985 2.17297 13.5125 2.17297 13.22 2.46547L11.8475 3.83797L14.66 6.65047L16.0325 5.27797Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3919_390729">
                    <rect width="18" height="18" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>
            </p>
          )}
        </div>
        <div className="overviewbottomtab">
          <HeaderTab setbottomdynamictab={setbottomdynamictab} data={Data} />
        </div>
        {bottomdynamictab == 0 && (
          <Obligation
            callObligationApi={callObligationApi}
            setCallObligationApi={setCallObligationApi}
            obligations={obligations}
            loading={loading}
            dropdownData={dropdownData}
            getTopDynamicDocketid={getTopDynamicDocketid}
            header={header}
            errorhandling={errorhandling}
            userinfo={userinfo}
          />
        )}
        {bottomdynamictab == 1 && <DataTab userinfo={userinfo} getTopDynamicDocketid={getTopDynamicDocketid} />}
        {bottomdynamictab == 2 && <SupervisionTab getTopDynamicDocketid={getTopDynamicDocketid} />}
        {bottomdynamictab == 3 && (
          <ChecklistTab person={2} getTopDynamicDocketid={getTopDynamicDocketid} AdCHeckLIst={"Docket"} />
        )}
      </div>
    </>
  );
};
