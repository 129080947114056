export const ReportingTopDynamicTab = ({
  ReportDynamicTab,
  setTab,
  tab,
}) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="participate">
          <div className="participate_tab">
            {ReportDynamicTab.map((val, i) => {
              return (
                <>
                  <div
                    className={`participate_tab_tab_block`}
                    style={{
                      borderBottom:
                        tab === i + 1
                          ? "4px solid rgb(66, 65, 103)"
                          : tab === i + 1
                          ? "4px solid rgb(66, 65, 103)"
                          : tab === i + 1
                          ? "4px solid rgb(66, 65, 103)"
                          : tab === i + 1
                          ? "4px solid rgb(66, 65, 103)"
                          : tab === i + 1
                          ? "4px solid rgb(66, 65, 103)"
                          : tab === i + 1
                          ? "4px solid rgb(66, 65, 103)"
                          : "",
                    }}
                  >
                    <p
                      style={{
                        color:
                          tab === i + 1
                            ? "var(--dynamicTabHeadertext1)"
                            : tab === i + 1
                            ? "var(--dynamicTabHeadertext1)"
                            : tab === i + 1
                            ? "var(--dynamicTabHeadertext1)"
                            : tab === i + 1
                            ? "var(--dynamicTabHeadertext1)"
                            : tab === i + 1
                            ? "var(--dynamicTabHeadertext1)"
                            : tab === i + 1
                            ? "var(--dynamicTabHeadertext1)"
                            : "var(--dynamicTabHeadertext2)",
                        padding: "0 1px",
                        margin: "16px",
                      }}
                      className="TabName"
                      onClick={() => {
                        setTab(i + 1);
                      }}
                    >
                      {val.title}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
