import { DateBox } from "devextreme-react";
import { useEffect, useState } from "react";
import { populateObligationDetailTypes } from "../../../../../../services/obligations/populate-obligation-detail-types";
import { addEditObligationDetail } from "../../../../../../services/obligations/populate-obligation-detail-types";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Toster } from "../../../../../../components/Toster/Toster";
import { useDispatch } from "react-redux";
import { setgotooblogationValue } from "../../../../../../reducer/participantNoteReducer";

export const AddObligationDetails = ({ data, setAddObligationDetailPopup }) => {
  const dispatch = useDispatch();
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    participant: "",
    obligation: "",
    quantity: 0,
    date: new Date(),
    rate: 0,
    amount: 0,
    transactionType: "",
    note: "",
  });

 
  const postAddObligationDetail = async () => {
    const body = {
      fRate: fields.rate,
      fAmount: fields.amount,
      iQuantity: fields.quantity,
      sNote: fields.note,
      gTransactionTypeId: fields.transactionType,
      dDoe: fields.date,
      gObligationId: data?.gObligationId,
    };

    console.log("Post Body:", body);
   
    try {
      await addEditObligationDetail(navigate, body);
      dispatch(
        setgotooblogationValue({
          gObligationId: data?.gObligationId,
          amount: fields.amount
        })
      );
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "Successfully Added!",
        type: "success",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: '',
          type: "",
        });
      }, 1900);
      setAddObligationDetailPopup(false);
    } catch (error) {
      console.error("Error:", error); 
    }
  };

  const fetchObligationTypes = async () => {
    try {
      const obligationTypes = await populateObligationDetailTypes(navigate);
      setTransactionTypes(obligationTypes.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownOpen = () => {
    if (transactionTypes.length === 0) {
      fetchObligationTypes();
    }
  };

  const handleInputChange = (field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  useEffect(() => {
      setFields((prevFields) => ({
        ...prevFields,
        amount: fields.quantity * fields.rate,
      }));
  }, [fields.quantity, fields.rate]);

  return (
    <>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="add-obligation-details-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div className="popup_header_title_data">
              <p>Add Obligation Detail</p>
            </div>
            <p className="popup_header_title_para">OB.28</p>
          </div>
          <svg
            onClick={() => setAddObligationDetailPopup(false)}
            style={{ cursor: "pointer" }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="inputs-field-wrapper">
          <div>
            <p className="container-fields-heading" style={{marginBottom:'10px'}}>Participant: {localStorage.getItem('offenderId')} - {data?.sPersonNameLastFirst}</p>
            <p className="container-fields-heading">Obligation: {data?.sFeeName}</p>
          </div>
          <div className="inputs-fields-container">
            <div className="inputs-field-content">
              <p className="edit_container_label">Date</p>
              <DateBox 
                useMaskBehavior={true} 
                onValueChange={(e) => handleInputChange("date", e.value)} 
                value={fields.date}
              />
            </div>
          </div>
          <div className="inputs-field-content">
            <p className="edit_container_label">Quantity</p>
            <input
              className="fee-payment-content-info-input"
              type="number"
              value={fields.quantity}
              onChange={(e) => handleInputChange("quantity", e.target.value)}
            />
          </div>
          <div className="inputs-field-content">
            <p className="edit_container_label">Rate</p>
            <input
              className="fee-payment-content-info-input"
              type="number"
              value={fields.rate}
              onChange={(e) => handleInputChange("rate", e.target.value)}
            />
          </div>
          <div className="inputs-field-content">
            <p className="edit_container_label">Amount</p>
            <input
              className="fee-payment-content-info-input"
              type="number"
              value={fields.amount}
              onChange={(e) => handleInputChange("amount", e.target.value)}
            />
          </div>
          <div className="inputs-field-content">
            <p className="edit_container_label">Transaction Type</p>
            {console.log("Selected", transactionTypes)}
            <select
              className="fee-payment-content-info-input"
              value={fields.transactionType}
              onChange={(e) => {
                const selectedId = e.target.value;
                handleInputChange("transactionType", selectedId);
              }}
              onFocus={handleDropdownOpen}
            >
              <option value="">Select Transaction Type</option>
              {transactionTypes.map((type) => (
                <option key={type.gTransactionTypeID} value={type.gTransactionTypeID}>
                  {type.sName}
                </option>
              ))}
            </select>
          </div>
          <div className="inputs-field-content">
            <p className="edit_container_label">Note</p>
            <textarea
              className="fee-payment-content-info-input"
              value={fields.note}
              onChange={(e) => handleInputChange("note", e.target.value)}
            />
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={postAddObligationDetail}>
              Save
            </button>
            <button className="btn" onClick={() => setAddObligationDetailPopup(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
