import axios from "axios";
import { logOut } from "../../../components/Logout";
import { handleGetLocal } from "../../../services/auth-service";
import {
  removeProntactEntitiesdynamicTab,
  setProntactEntitiesSubTabAddresData,
  setProntactEntitiesdynamicTab,
  setProntactEntitiesSubTabinvoicingData,
  setProntactEntitiesSubTabDockumentWizarddata,
} from "../../../reducer/AdminReadReducer";
import { setcontectTableAddDataaa2 } from "../../../reducer/ParticipateReducer";

export class ProntactEntityFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  getDRopDown = async (
    navigate,
    setRegiondropdown,
    setPaymentAllocationRuledropdown,
    setentitydatadropdown,
    setContactPositiondropdown,
    setContactdropdown,
    setentitydatadropdown2
  ) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Entity/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.region?.length &&
          setRegiondropdown(
            val?.data?.data?.region?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );
        val?.data?.data?.entity?.length &&
          setentitydatadropdown2(
            val?.data?.data?.entity?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );

        val?.data?.data?.entityType?.length &&
          setentitydatadropdown(
            val?.data?.data?.entityType?.map((val, i) => {
              return {
                "Entity Type": val?.value,
                id: val?.key,
                ID: i,
              };
            })
          );

        val?.data?.data?.allocationRule?.length &&
          setPaymentAllocationRuledropdown(
            val?.data?.data?.allocationRule?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );

        val?.data?.data?.contacts?.length &&
          setContactdropdown(
            val?.data?.data?.contacts?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );

        val?.data?.data?.contactPosition?.length &&
          setContactPositiondropdown(
            val?.data?.data?.contactPosition?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  Saveheader = async (
    body,
    navigate,
    setloading,
    toastConfig,
    setToastConfig,
    dispatch,
    Entitiesid,
    setEditTogel,
    setFIelds,
    PaymentAllocationRuledropdown,
    setProntactEntitieschange,
    setentitidata,
    entitydatadropdown,
    Regiondropdown,
    ProntactEntitiesSubTabAddresData,
    ProntactEntitiesSubTabinvoicingData,
    ProntactEntitiesSubTabDockumentWizarddata,
    contectTableAddData2,
    setentityid,
    setcheckispersoncreate,
    setfields2,
    entitydatadropdown2
  ) => {
    setloading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Entity/add-edit`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setloading(false);
        dispatch(setProntactEntitieschange(false));
        if (val?.data?.isSuccess) {
          setEditTogel(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: !Entitiesid ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: !Entitiesid ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
          }, 1400);

          this.subtabsave(
            setloading,
            {
              sAddress1: ProntactEntitiesSubTabAddresData?.HomeAddress?.AddressLine1,
              sAddress2: ProntactEntitiesSubTabAddresData?.HomeAddress?.AddressLine2,
              sState: ProntactEntitiesSubTabAddresData?.HomeAddress?.State,
              sZip: ProntactEntitiesSubTabAddresData?.HomeAddress?.ZIP,
              sZip4: "",
              sCity: ProntactEntitiesSubTabAddresData?.HomeAddress?.City,
              sCounty: ProntactEntitiesSubTabAddresData?.HomeAddress?.Country,
              sAddressComment: ProntactEntitiesSubTabAddresData?.HomeAddress?.Comment,
              sMailingAddress1: ProntactEntitiesSubTabAddresData?.MailingAddress?.AddressLine1,
              sMailingAddress2: ProntactEntitiesSubTabAddresData?.MailingAddress?.AddressLine2,
              sMailingState: ProntactEntitiesSubTabAddresData?.MailingAddress?.State,
              sMailingZip: ProntactEntitiesSubTabAddresData?.MailingAddress?.ZIP,
              sMailingZip4: "",
              sMailingCity: ProntactEntitiesSubTabAddresData?.MailingAddress?.City,
              sMailingCounty: ProntactEntitiesSubTabAddresData?.MailingAddress?.Country,
              sMailingAddressComment: ProntactEntitiesSubTabAddresData?.MailingAddress?.Comment,
              sAcctPayAddress1: ProntactEntitiesSubTabAddresData?.WorkAddress?.AddressLine1,
              sAcctPayAddress2: ProntactEntitiesSubTabAddresData?.WorkAddress?.AddressLine2,
              sAcctPayState: ProntactEntitiesSubTabAddresData?.WorkAddress?.State,
              sAcctPayZip: ProntactEntitiesSubTabAddresData?.WorkAddress?.ZIP,
              sAcctpayZip4: "",
              sAcctPayCity: ProntactEntitiesSubTabAddresData?.WorkAddress?.City,
              sAcctPayCounty: ProntactEntitiesSubTabAddresData?.WorkAddress?.Country,
              sAcctPayAddressComment: ProntactEntitiesSubTabAddresData?.WorkAddress?.Comment,
              sEmail: ProntactEntitiesSubTabinvoicingData?.EMailAddressList
                ? ProntactEntitiesSubTabinvoicingData?.EMailAddressList
                : "",
              sEmailBcc: ProntactEntitiesSubTabinvoicingData?.BCCAddressList
                ? ProntactEntitiesSubTabinvoicingData?.BCCAddressList
                : "",
              sInvoiceTemplate1: ProntactEntitiesSubTabinvoicingData?.InvoiceTemplet1?.name
                ? ProntactEntitiesSubTabinvoicingData?.InvoiceTemplet1?.name
                : "",
              sInvoiceTemplate2: ProntactEntitiesSubTabinvoicingData?.InvoiceTemplet2?.name
                ? ProntactEntitiesSubTabinvoicingData?.InvoiceTemplet2?.name
                : "",
              sInvoiceTemplate3: ProntactEntitiesSubTabinvoicingData?.InvoiceTemplet3?.name
                ? ProntactEntitiesSubTabinvoicingData?.InvoiceTemplet3?.name
                : "",
              sLegalNameLine1: ProntactEntitiesSubTabDockumentWizarddata?.LegalNameLine1
                ? ProntactEntitiesSubTabDockumentWizarddata?.LegalNameLine1
                : "",
              sLegalNameLine2: ProntactEntitiesSubTabDockumentWizarddata?.LegalNameLine2
                ? ProntactEntitiesSubTabDockumentWizarddata?.LegalNameLine2
                : "",
              SName: body?.SName,
              GEntityId: Entitiesid ? Entitiesid : val?.data?.data?.gEntityId,
            },
            navigate
          );
          this.add_entity_contact(
            contectTableAddData2.map((vall) => {
              return {
                gEntityContactId: vall?.gEntityContactId ? vall?.gEntityContactId : "",
                dDoe: new Date().toISOString().slice(0, 19).replace("T", " ") + ".000",
                gEntryUserId: localStorage.getItem("gUserId"),
                gEntityId: Entitiesid ? Entitiesid : val?.data?.data?.gEntityId,
                gContactId: vall?.Contact ? vall?.Contact : "",
                bActive: vall?.Active ? vall?.Active : false,
                dStart: vall?.Start ? vall?.Start : "",
                dEnd: vall?.End ? vall?.End : "",
                gContactPositionId: vall?.Position ? vall?.Position : "",
                bPrimary: true,
              };
            }),
            setloading,
            navigate
          );

          if (Entitiesid) {
            return;
          }
          dispatch(
            setProntactEntitiesdynamicTab({
              title: `Entity : ${val?.data?.data?.sName}`,
              Offender: `/prontact/entity/${val?.data?.data?.gEntityId}`,
              entityid: val?.data?.data?.gEntityId,
            })
          );
          navigate(`/prontact/entity/${val?.data?.data?.gEntityId}`);
          dispatch(removeProntactEntitiesdynamicTab(`/prontact/entity`));
          return;
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network error!",
              type: "error",
            });
          }, 1400);
          Entitiesid &&
            this.getentity(
              setloading,
              setFIelds,
              Entitiesid,
              PaymentAllocationRuledropdown,
              navigate,
              setentitidata,
              entitydatadropdown,
              Regiondropdown,
              dispatch,
              setentityid,
              setcheckispersoncreate,
              setfields2,
              entitydatadropdown2
            );
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network error!",
              type: "error",
            });
          }, 1400);
          Entitiesid &&
            this.getentity(
              setloading,
              setFIelds,
              Entitiesid,
              PaymentAllocationRuledropdown,
              navigate,
              setentitidata,
              entitydatadropdown,
              Regiondropdown,
              dispatch,
              setentityid,
              setcheckispersoncreate,
              setfields2,
              entitydatadropdown2
            );
        }
      });
  };

  Save(
    fields,
    navigation,
    setloading,
    toastConfig,
    setToastConfig,
    Entitiesid,
    dispatch,
    setEditTogel,
    setFIelds,
    PaymentAllocationRuledropdown,
    setProntactEntitieschange,
    entitidata,
    setentitidata,
    entitydatadropdown,
    Regiondropdown,
    ProntactEntitiesSubTabAddresData,
    ProntactEntitiesSubTabinvoicingData,
    ProntactEntitiesSubTabDockumentWizarddata,
    contectTableAddData2,
    setentityid,
    setcheckispersoncreate,
    fields2,
    setfields2,
    entitydatadropdown2
  ) {
    if (!entitidata?.length) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "Please Select Entity Type!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "Please Select Entity Type!",
          type: "error",
        });
      }, 1400);
      return;
    }

    let gContactPositionId = false;
    contectTableAddData2.map((val) => {
      if (!val?.Position.length) {
        gContactPositionId = true;
      }
    });

    if (gContactPositionId && contectTableAddData2.length) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "Please Select Position of Contact List!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "Please Select Position of Contact List!",
          type: "error",
        });
      }, 1400);
      return;
    }
    this.Saveheader(
      {
        GEntityId: Entitiesid ? Entitiesid : "00000000-0000-0000-0000-000000000000",
        GEntryUserId: localStorage.getItem("gUserId"),
        SName: fields?.["Entity Name"],
        SPhone: fields?.["Telephone"] ? fields?.["Telephone"] : null,
        SFax: fields?.["Fax"] ? fields?.["Fax"] : null,
        GPaymentAllocationRuleId: fields?.["Payment Allocation Rule"].id
          ? fields?.["Payment Allocation Rule"].id
          : null,
        GNameOriginId: null,
        IEntitySequence: 12,
        GRegionId: fields?.["Region"].id ? fields?.["Region"].id : null,
        gCompanyEntityID: fields2["Company Entity"]?.id,
        gReportingEntityID: fields2["Reporting Entity"]?.id,
        GEntityTypeId: entitidata?.map((val) => {
          return val.id;
        }),
      },
      navigation,
      setloading,
      toastConfig,
      setToastConfig,
      dispatch,
      Entitiesid,
      setEditTogel,
      setFIelds,
      PaymentAllocationRuledropdown,
      setProntactEntitieschange,
      setentitidata,
      entitydatadropdown,
      Regiondropdown,
      ProntactEntitiesSubTabAddresData,
      ProntactEntitiesSubTabinvoicingData,
      ProntactEntitiesSubTabDockumentWizarddata,
      contectTableAddData2,
      setentityid,
      setcheckispersoncreate,
      setfields2,
      entitydatadropdown2
    );
  }

  async getFIndTable(setLoading, search, setdata, navigate) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Entity/find?search=${search ? search : null}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setdata(this.setentityFindTable(val?.data?.data));
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  setentityFindTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        Entity: val?.sName,
        "Entity Type": val?.sEntityTypeName,
        ID: i,
        gEntityId: val?.gEntityId,
      });
    });
    return arr;
  };

  async subtabsave(setloading, body, navigate) {
    setloading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Entity/add-entity-tabs`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setloading(false);
        if (val?.data?.isSuccess) {
        } else {
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getentity(
    setloading,
    setFIelds,
    Entitiesid,
    PaymentAllocationRuledropdown,
    navigation,
    setentitidata,
    entitydatadropdown,
    Regiondropdown,
    dispatch,
    setentityid,
    setcheckispersoncreate,
    setfields2,
    entitydatadropdown2
  ) {
    setloading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Entity/get?gEntityId=${Entitiesid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setloading(false);
        if (val?.data?.isSuccess) {
          let data = { name: "", id: "" };
          PaymentAllocationRuledropdown?.map((vall) => {
            if (vall?.id.toLocaleLowerCase() == val?.data?.data?.entity?.gPaymentAllocationRuleId.toLocaleLowerCase()) {
              data = vall;
            }
          });

          let data3 = { name: "", id: "" };
          Regiondropdown?.map((vall) => {
            if (vall?.id?.toLocaleLowerCase() == val?.data?.data?.entity?.gRegionId?.toLocaleLowerCase()) {
              data3 = vall;
            }
          });

          let data4 = { name: "", id: "" };
          entitydatadropdown2?.map((vall) => {
            if (vall?.id?.toLocaleLowerCase() == val?.data?.data?.entity?.gReportingEntityId?.toLocaleLowerCase()) {
              data4 = vall;
            }
          });
          let data5 = { name: "", id: "" };
          entitydatadropdown2?.map((vall) => {
            if (vall?.id?.toLocaleLowerCase() == val?.data?.data?.entity?.gCompanyEntityId?.toLocaleLowerCase()) {
              data5 = vall;
            }
          });

          setfields2({
            "Company Entity": data5,
            "Reporting Entity": data4,
          });

          let data2 = [];

          val?.data?.data?.entitytypelist?.length &&
            val?.data?.data?.entitytypelist?.map((val1) => {
              entitydatadropdown?.map((val2, i1) => {
                if (val2.id.toLocaleLowerCase() === val1.gEntityTypeId.toLocaleLowerCase()) {
                  data2.push({
                    "Entity Type": val2?.["Entity Type"],
                    id: val2?.id,
                    ID: i1,
                  });
                }
              });
            });

          setentitidata(data2);

          setentityid(val?.data?.data?.entity?.iEntitySequence);
          setcheckispersoncreate(val?.data?.responseMessage == "true" ? true : false);

          setFIelds({
            "Entity Name": val?.data?.data?.entity?.sName,
            Telephone: val?.data?.data?.entity?.sPhone,
            Fax: val?.data?.data?.entity?.sFax,
            Region: data3,
            "Payment Allocation Rule": data,
          });

          dispatch(
            setProntactEntitiesSubTabAddresData({
              MailingAddress: {
                AddressLine1: val?.data?.data?.entity?.sMailingAddress1
                  ? val?.data?.data?.entity?.sMailingAddress1
                  : "",
                AddressLine2: val?.data?.data?.entity?.sMailingAddress2
                  ? val?.data?.data?.entity?.sMailingAddress2
                  : "",
                City: val?.data?.data?.entity?.sMailingCity ? val?.data?.data?.entity?.sMailingCity : "",
                State: val?.data?.data?.entity?.sMailingState ? val?.data?.data?.entity?.sMailingState : "",
                ZIP: val?.data?.data?.entity?.sMailingZip ? val?.data?.data?.entity?.sMailingZip : "",
                Country: val?.data?.data?.entity?.sMailingCounty ? val?.data?.data?.entity?.sMailingCounty : "",
                Comment: val?.data?.data?.entity?.sMailingAddressComment
                  ? val?.data?.data?.entity?.sMailingAddressComment
                  : "",
              },
              HomeAddress: {
                AddressLine1: val?.data?.data?.entity?.sAddress1 ? val?.data?.data?.entity?.sAddress1 : "",
                AddressLine2: val?.data?.data?.entity?.sAddress2 ? val?.data?.data?.entity?.sAddress2 : "",
                City: val?.data?.data?.entity?.sCity ? val?.data?.data?.entity?.sCity : "",
                State: val?.data?.data?.entity?.sState ? val?.data?.data?.entity?.sState : "",
                ZIP: val?.data?.data?.entity?.sZip ? val?.data?.data?.entity?.sZip : "",
                Country: val?.data?.data?.entity?.sCounty ? val?.data?.data?.entity?.sCounty : "",
                Comment: val?.data?.data?.entity?.sAddressComment ? val?.data?.data?.entity?.sAddressComment : "",
              },
              WorkAddress: {
                AddressLine1: val?.data?.data?.entity?.sAcctPayAddress1
                  ? val?.data?.data?.entity?.sAcctPayAddress1
                  : "",
                AddressLine2: val?.data?.data?.entity?.sAcctPayAddress2
                  ? val?.data?.data?.entity?.sAcctPayAddress2
                  : "",
                City: val?.data?.data?.entity?.sAcctPayCity ? val?.data?.data?.entity?.sAcctPayCity : "",
                State: val?.data?.data?.entity?.sAcctPayState ? val?.data?.data?.entity?.sAcctPayState : "",
                ZIP: val?.data?.data?.entity?.sAcctPayZip ? val?.data?.data?.entity?.sAcctPayZip : "",
                Country: val?.data?.data?.entity?.sAcctPayCounty ? val?.data?.data?.entity?.sAcctPayCounty : "",
                Comment: val?.data?.data?.entity?.sAcctPayAddressComment
                  ? val?.data?.data?.entity?.sAcctPayAddressComment
                  : "",
              },
              isChange: false,
            })
          );

          dispatch(
            setProntactEntitiesSubTabinvoicingData({
              EMailAddressList: val?.data?.data?.entity?.sEmail ? val?.data?.data?.entity?.sEmail : "",
              BCCAddressList: val?.data?.data?.entity?.sEmailBcc ? val?.data?.data?.entity?.sEmailBcc : "",
              InvoiceTemplet1: val?.data?.data?.entity?.sInvoiceTemplate1
                ? { name: val?.data?.data?.entity?.sInvoiceTemplate1, id: "" }
                : { name: "", id: "" },
              InvoiceTemplet2: val?.data?.data?.entity?.sInvoiceTemplate2
                ? { name: val?.data?.data?.entity?.sInvoiceTemplate2, id: "" }
                : { name: "", id: "" },
              InvoiceTemplet3: val?.data?.data?.entity?.sInvoiceTemplate3
                ? { name: val?.data?.data?.entity?.sInvoiceTemplate3, id: "" }
                : { name: "", id: "" },
            })
          );

          dispatch(
            setProntactEntitiesSubTabDockumentWizarddata({
              LegalNameLine1: val?.data?.data?.entity?.sLegalNameLine1 ? val?.data?.data?.entity?.sLegalNameLine1 : "",
              LegalNameLine2: val?.data?.data?.entity?.sLegalNameLine2 ? val?.data?.data?.entity?.sLegalNameLine2 : "",
            })
          );
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigation(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  getattachmentTableData = async (setattachmentTabledata, setLoading, navigate, Entitiesid, setattachmentCount) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/attachments/get-attachments?recordId=${Entitiesid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setattachmentCount(val?.data?.data?.length);
        setattachmentTabledata(this.setTableData(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  setTableData = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Filename: val?.filename,
        Category: val?.attachmentCateogoryName,
        Description: val?.description,
        ID: val?.recordId,
        "Entered By": val?.enteredByUserName,
        DOE: val?.doe,
        attachment: val?.attachment,
        attachmentCategoryId: val?.attachmentCategoryId,
        binaryDataId: val?.binaryDataId,
        image: val?.image,
      });
    });

    return arr;
  };

  async getentityFIndTable(setLoading, search, setdata, navigate) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Entity/entity-type-find?search=${search ? search : null}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setdata(this.getentityFIndTableTable(val?.data?.data));
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  getentityFIndTableTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "Entity Type": val?.sName,
        ID: i,
        id: val?.gEntityTypeId,
      });
    });
    return arr;
  };
  async QuickAddContectapi(
    body,
    setloading,
    navigate,
    setToastConfig,
    toastConfig,
    setQuickAddContectpopupshow,
    getDRopDown,
    dispatch,
    contectTableAddData2,
    field
  ) {
    setloading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Entity/add-contact`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setloading(false);
        if (val?.data?.isSuccess) {
          setQuickAddContectpopupshow(false);
          getDRopDown();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Added!",
              type: "success",
            });
          }, 1400);

          if (field?.check)
            dispatch(
              setcontectTableAddDataaa2([
                ...contectTableAddData2,
                {
                  Contact: val?.data?.data?.gContactId?.toUpperCase(),
                  Position: "",
                  Active: "",
                  Start: "",
                  End: "",
                  "Contact Information": "",
                  "Contact Type": "",
                  ID: contectTableAddData2.length + 1,
                },
              ])
            );
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Contact already exist!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Contact already exist!",
              type: "error",
            });
          }, 1400);
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async add_entity_contact(body, setloading, navigate) {
    setloading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Entity/add-entity-contact`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setloading(false);
        if (val?.data?.isSuccess) {
        } else {
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async get_entity_contact(setloading, navigate, Entitiesid, setdata, dispatch, contectTableAddData2) {
    setloading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Entity/get-entity-contact?entityId=${Entitiesid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setloading(false);
        if (val?.data?.isSuccess) {
          setdata(this.setget_entity_contactTableDataa(val?.data?.data));

          dispatch(setcontectTableAddDataaa2([...this.setget_entity_contactTableDataa(val?.data?.data)]));
        } else {
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  setget_entity_contactTableDataa(dataa) {
    const arr = [];
    dataa.map((val, i) => {
      arr.push({
        Contact: val?.gContactId.toUpperCase(),
        Position: val?.gContactPositionId.toUpperCase(),
        Active: val?.bActive ? val?.bActive : false,
        Start: val?.dStart ? val?.dStart : "",
        End: val?.dEnd ? val?.dEnd : "",
        "Contact Information": val?.sContactInformation,
        "Contact Type": val?.sContactType,
        ID: i,
        gEntityContactId: val?.gEntityContactId ? val?.gEntityContactId : "",
      });
    });

    return arr;
  }

  async create_entity_person(setloading, navigate, Entitiesid, setentityid2, setcheckispersoncreate) {
    setloading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Entity/create-entity-person?entityId=${Entitiesid}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setloading(false);
        if (val?.data?.isSuccess) {
          setentityid2(val?.data?.entitySequence);
          setcheckispersoncreate(true);
        } else {
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getEntityList(setLoading, seterorhandling, navigate, setEntityList) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Entity/entity-find?search=`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setEntityList(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        if (!err?.response) {
          navigate(`/error/500`);
        } else if (err?.response?.status == 401) {
          logOut();
        } else {
          seterorhandling(true);
        }
      });
  }
}
