import { useEffect, useState } from "react";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import MonitoringTabs from "./components/MonitoringTabs";
import { DateBox, LoadPanel, SelectBox } from "devextreme-react";
import { dateRanges } from "../docket-list/Data";
import { ApiError } from "../Error/ApiError";
import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { logOut } from "../../components/Logout";
import { Table2 } from "../../components/Table2/Table2";
import { periodSummaryColumns } from "./Data";
import "./monitoring.css";
import { useNavigate } from "react-router-dom";

const setTableData = (data) => {
    const arr = [];
    data.map((val) => {
        arr.push({
            ID: val.sOffenderNumber,
            "Offender #": val.sOffenderNumber,
            "Offender Name": val.sLastFirst,
            "Payment Type": val.sPaymentTypeName,
            "Start": val.dRateStart,
            "End": val.dRangeEnd,
            "Rate": val.fRate,
            "Billing Agency": val.sBillingAgency,
            "Fee Name": val.sActivity,
            "Court": val.sCourtName,
            "EM Type": val.sEM_TYPE,
            "Days": val.iDays,
            "Due": val.fDue,
            "Collected": val.fCollected,
            "Arrears": val.fArrears,
            "Fee Code": val.sDDSLegalCode,
            "Region": val.sRegionName,
            "Rate Frequency": val.sRateFrequency,
            "Supervisor": val.sPrimarySupervisorName
        });
    });
    return arr;
};

const PeriodSummary = () => {
    const pathname = handleGetLocal("pathURL");
    const today = new Date();
    const [exportPopupOpen, setexportPopupOpen] = useState(false);
    const [quickDatevalue, setquickDatevalue] = useState("Month to Date");
    const [errorhandling, setErrorhandling] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([]);
    const [selectedPersonIds, setSelectedPersonIds] = useState([]);
    const [date, setdate] = useState({
        startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().slice(0, 10),
        endDate: new Date().toISOString().slice(0, 10),
    });
    const navigate = useNavigate();

    const getPeriodSummary = async () => {
        setLoading(true);
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/monitoring/get-period-summary?dStart=${date.startDate}&dEnd=${date.endDate}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        Domain: `${pathname}`,
                    },
                }
            )
            .then((val) => {
                setdata(setTableData(val.data.data));
                setLoading(false);
            })
            .catch((val) => {
                setLoading(false);
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status == 401) {
                    logOut();
                }
            });
    };

    useEffect(() => {
        getPeriodSummary();
    }, []);

    return (
        <>
            <LoadPanel shadingColor="true" visible={loading} delay={10} />
            <MonitoringCrumbs title={"Period Summary"} />
            <div className="financial-page">
                <MonitoringTabs />
                <div className="financial-page-section receive-agency-payment">
                    <p className="page-info-number">EM.15</p>
                    <div className="interaction flex" style={{ gap: "50px", alignItems: "center" }}>
                        <div className="page-details-search-content">
                            <button
                                onClick={() => {
                                    setexportPopupOpen(true);
                                }}
                                id="myBtn"
                                className="btn btnlightblue"
                            >
                                Export
                                <svg
                                    className="svg-blue-white"
                                    width="14"
                                    height="17"
                                    viewBox="0 0 14 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                                        fill="#424167"
                                    />
                                </svg>
                            </button>
                            <button
                                onClick={() => {
                                    getPeriodSummary();
                                }}
                                id="myBtn"
                                className="btn btnlightblue"
                            >
                                Refresh
                                <svg
                                    className="svg-blue-white"
                                    width="16"
                                    height="21"
                                    viewBox="0 0 16 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.99905 4.00003V1.21003C7.99905 0.760031 7.45905 0.540031 7.14905 0.860031L3.34905 4.65003C3.14905 4.85003 3.14905 5.16003 3.34905 5.36003L7.13905 9.15003C7.45905 9.46003 7.99905 9.24003 7.99905 8.79003V6.00003C11.7291 6.00003 14.6791 9.42003 13.8591 13.29C13.3891 15.56 11.5491 17.39 9.28905 17.86C5.71905 18.61 2.53905 16.16 2.05905 12.85C1.98905 12.37 1.56905 12 1.07905 12C0.479051 12 -0.000948563 12.53 0.0790514 13.13C0.699051 17.52 4.87905 20.77 9.60905 19.85C12.7291 19.24 15.2391 16.73 15.8491 13.61C16.8391 8.48003 12.9391 4.00003 7.99905 4.00003Z"
                                        fill="#424167"
                                    />
                                </svg>
                            </button>
                            <div className="display-flex-div">
                                <p className="go-to-label-filed">Quick Date</p>
                                <SelectBox
                                    dataSource={dateRanges}
                                    valueExpr="name"
                                    displayExpr="name"
                                    searchEnabled={true}
                                    searchExpr={"name"}
                                    value={quickDatevalue}
                                    onItemClick={(e) => {
                                        setquickDatevalue(e?.itemData?.name);
                                        let startDate = null;
                                        let endDate = null;
                                        switch (e?.itemData?.name) {
                                            case "Last 3 Days":
                                                startDate = new Date();
                                                startDate.setDate(startDate.getDate() - 2);
                                                endDate = new Date();
                                                break;
                                            case "Last 7 Days":
                                                startDate = new Date();
                                                startDate.setDate(startDate.getDate() - 6);
                                                endDate = new Date();
                                                break;
                                            case "Month to Date":
                                                startDate = new Date();
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                break;
                                            case "Previous Month":
                                                startDate = new Date();
                                                startDate.setMonth(startDate.getMonth() - 1);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                endDate.setDate(0);
                                                break;
                                            case "Next Month from Date":
                                                startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                                                endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
                                                break;
                                            case "Next 3 Month from Date":
                                                startDate = new Date();
                                                endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 3, 0);
                                                break;
                                            case "last 3 Month to Date":
                                                startDate = new Date();
                                                startDate.setMonth(startDate.getMonth() - 2);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                break;
                                            case "last 6 Month to Date":
                                                startDate = new Date();
                                                startDate.setMonth(startDate.getMonth() - 5);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                break;
                                            case "Year to Date":
                                                startDate = new Date();
                                                startDate.setMonth(0);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                break;
                                            case "Previous Year":
                                                startDate = new Date();
                                                startDate.setFullYear(startDate.getFullYear() - 1);
                                                startDate.setMonth(0);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                endDate.setFullYear(endDate.getFullYear() - 1);
                                                endDate.setMonth(11);
                                                endDate.setDate(31);
                                                break;
                                            case "Today":
                                            default:
                                                startDate = new Date();
                                                endDate = new Date();
                                                break;
                                        }
                                        setdate({
                                            startDate: startDate.toISOString().slice(0, 10),
                                            endDate: endDate.toISOString().slice(0, 10),
                                        });
                                    }}
                                />
                            </div>
                            <div className="display-flex-div">
                                <p className="go-to-label-filed">Start Date</p>
                                <DateBox
                                    style={{ height: "35px", width: "180px" }}
                                    useMaskBehavior={true}
                                    value={date.startDate}
                                    onValueChange={(e) => {
                                        if (e) {
                                            setdate({
                                                ...date,
                                                startDate: new Date(new Date(e).toLocaleDateString()).toLocaleDateString("en-CA"),
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="display-flex-div">
                                <p className="go-to-label-filed">End Date</p>
                                <DateBox
                                    useMaskBehavior={true}
                                    style={{ height: "35px", width: "180px" }}
                                    value={date.endDate}
                                    onValueChange={(e) => {
                                        if (e) {
                                            setdate({
                                                ...date,
                                                endDate: new Date(new Date(e).toLocaleDateString()).toLocaleDateString("en-CA"),
                                            });
                                        }
                                    }}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="table-section">
                        {errorhandling ? (
                            <ApiError />
                        ) : (
                            <>
                                {data?.length ? (
                                    <Table2
                                        Tablecoloum={periodSummaryColumns}
                                        tableRow={data}
                                        load={loading}
                                        coloumWidthText={"periodSummary"}
                                        height={620}
                                        editTable={false}
                                        mandatoryColoum={""}
                                        showTotalBlock={false}
                                        exportInDifferentFormate={true}
                                        exportPopupOpen={exportPopupOpen}
                                        setexportPopupOpen={setexportPopupOpen}
                                        exportwidth={16.3}
                                        exporttitle={"monitoring period summary list"}
                                        periodSummaryControl={true}
                                        selectedPersonIds={selectedPersonIds}
                                        setSelectedPersonIds={setSelectedPersonIds}
                                    />
                                ) : (
                                    ""
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PeriodSummary;
