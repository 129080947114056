import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, DateBox, DropDownBox, LoadPanel } from "devextreme-react";
// import { Toster } from "../../components/Toster/Toster";
import { ApiError } from "../../../../../../Error/ApiError";
import { Table1 } from "../../../../../../Administration/Table1/Table1";
import { MonitoringFunction } from "../../../../../../Monitoring/MonitoringFunction";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { ColumnDropdown } from "../../../../../../../components/Dropdownn";
import { useNavigate } from "react-router-dom";
import { emCreateInvoiceTabelColumn } from "../../Data";
import { handleGetLocal } from "../../../../../../../services/auth-service";
import { logOut } from "../../../../../../../components/Logout";
import { getLastDayOfMonth } from "../../../../../../../utils/date-helpers";
import { Toster } from "../../../../../../../components/Toster/Toster";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const Months = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9" },
  { key: "10", value: "10" },
  { key: "11", value: "11" },
  { key: "12", value: "12" },
];
export const generateYearDropdown = (currentYear, numPrevious, numNext) => {
  const years = [];
  const startYear = currentYear - numPrevious;
  const endYear = currentYear + numNext;
  for (let year = startYear; year <= endYear; year++) {
    years.push({
      id: year.toString(),
      name: year.toString(),
    });
  }
  return years;
};

export const CreateInvoice = () => {
  const [paymentAgencyDropdownData, setPaymentAgencyDropdownData] = useState([]);
  const [billingCompanyDropdownData, setBillingCompanyDropdownData] = useState([]);
  const [fields, setFields] = useState({
    paymentagency: {
      id: "",
      name: "",
    },
    year: {
      id: "",
      name: "",
    },
    month: {
      id: "",
      name: "",
    },
    billingcompany: {
      id: "",
      name: "",
    },
    periodstart: "",
    periodend: "",
    invoicedate: "",
    notes: "",
  });
  const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);
  console.log(selectedRowCheckbox);
  const [receiveInvoiceData, setReceiveInvoiceData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [buttonRefreshClicked, setButtonRefreshClicked] = useState(false);

  const handleRefreshButtonClick = () => {
    setButtonRefreshClicked(true);
  };
  const currentYear = new Date().getFullYear();
  const currentMonthID = new Date().getMonth() + 1;
  const numPrevious = 5;
  const numNext = 5;
  const years = generateYearDropdown(currentYear, numPrevious, numNext);

  const navigate = useNavigate();
  const ApiClass = new MonitoringFunction();
  const currentDate = new Date();
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const year = lastDayOfMonth.getFullYear();
  const month = (lastDayOfMonth.getMonth() + 1).toString().padStart(2, "0");
  const day = lastDayOfMonth.getDate().toString().padStart(2, "0");
  const formattedEndDate = `${year}-${month}-${day}`;

  const handleSelectAll = () => {
    console.log("Invoice Data :");
    console.log({ receiveInvoiceData });
    setSelectedRowCheckbox(receiveInvoiceData);
  };

  const handleDeselectAll = () => {
    setSelectedRowCheckbox([]);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateDates = (selectedYear, selectedMonth) => {
    // Logic to calculate start and end dates based on selected year and month
    const startDay = "01";
    const endDay = getLastDayOfMonth(selectedYear, selectedMonth);
    const startDate = selectedYear && selectedMonth ? `${selectedYear}-${selectedMonth}-${startDay}` : "";
    const endDate = selectedYear && selectedMonth ? `${selectedYear}-${selectedMonth}-${endDay}` : "";

    return { startDate, endDate };
  };
  const updateDates = () => {
    const { startDate, endDate } = calculateDates(fields.year.id, fields.month.id);
    setFields((prev) => ({
      ...prev,
      periodstart: startDate,
      periodend: endDate,
    }));
  };

  const data = {
    PaymentAgencyId: fields?.paymentagency.id,
    BillingCompanyId: fields?.billingcompany.id,
    PeriodStart: fields?.periodstart,
    PeriodEnd: fields.periodend,
    InvoiceDate: fields.invoicedate,
    Notes: fields.notes,
    GridData: selectedRowCheckbox,
  };

  const handleCreate = async () => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/monitoring/create-invoice`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${handleGetLocal("pathURL")}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Created!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Created!",
            type: "success",
          });
        }, 1900);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  };

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  useEffect(() => {
    ApiClass.getPaymentAgencyDropdown(setLoading, navigate, setPaymentAgencyDropdownData);
    setFields((prev) => ({
      ...prev,
      year: { name: currentYear.toString(), id: currentYear.toString() },
      month: { name: currentMonthID.toString(), id: currentMonthID },
      periodstart: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
      periodend: formattedEndDate,
      invoicedate: formattedEndDate,
    }));
  }, []);

  useEffect(() => {
    if (fields.paymentagency.id) {
      ApiClass.getInvoiceData(
        setLoading,
        navigate,
        setReceiveInvoiceData,
        setErrorhandling,
        fields.paymentagency.id,
        fields.periodstart,
        fields.periodend
      );
    }
  }, [fields.paymentagency.id, fields.periodstart, fields.periodend, buttonRefreshClicked]);

  useEffect(() => {
    updateDates();
  }, [fields.year.id, fields.month.id]);

  useEffect(() => {
    ApiClass.getBillingCompanyDropdown(setLoading, navigate, setBillingCompanyDropdownData, fields.paymentagency.id);
  }, [fields.paymentagency.id]);

  return (
    <>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="financial-page-section receive-agency-payment">
        <p className="page-info-number">EM.6</p>
        <div className="page-details-search-content">
          <div className="display-flex-div edit_content_space">
            <label className="edit_container_label">Payment Agency (Entity)</label>
            <DropDown
              setfields={setFields}
              fields={fields}
              fieldNmae={"paymentagency"}
              dropDownData={paymentAgencyDropdownData}
            />
          </div>
          <div className="display-flex-div edit_content_space">
            <label className="edit_container_label">Billing Company Entity</label>
            <DropDown
              setfields={setFields}
              fields={fields}
              fieldNmae={"billingcompany"}
              dropDownData={billingCompanyDropdownData}
            />
          </div>
          <div className="display-flex-div edit_content_space">
            <label className="edit_container_label">Invoice Date</label>
            <DateBox
              useMaskBehavior={true}
              value={fields.invoicedate}
              onValueChange={(e) => {
                if (e) {
                  const initialDateString = e;
                  const initialDate = new Date(initialDateString);
                  const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                  const utcFormatted = new Date(utcTime).toISOString();
                  setFields((prev) => ({
                    ...prev,
                    invoicedate: e ? utcFormatted : null,
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="page-details-search-content">
          <div className="display-flex-div edit_content_space">
            <label className="edit_container_label">Year</label>
            <DropDown setfields={setFields} fields={fields} fieldNmae={"year"} dropDownData={years} />
          </div>
          <div className="page-details-search-content">
            <div className="display-flex-div edit_content_space">
              <label className="edit_container_label">Period Start / End</label>
              <DateBox
                useMaskBehavior={true}
                value={fields.periodstart}
                onValueChange={(e) => {
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setFields((prev) => ({
                      ...prev,
                      periodstart: e ? utcFormatted : null,
                    }));
                  }
                }}
              />
              <DateBox
                useMaskBehavior={true}
                value={fields.periodend}
                onValueChange={(e) => {
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setFields((prev) => ({
                      ...prev,
                      periodend: e ? utcFormatted : null,
                    }));
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="page-details-search-content">
          <div className="display-flex-div edit_content_space">
            <label className="edit_container_label">Month</label>
            <DropDown
              setfields={setFields}
              fields={fields}
              fieldNmae={"month"}
              dropDownData={Months?.map((vall) => {
                return {
                  name: vall?.value,
                  id: vall?.key,
                };
              })}
            />
          </div>
          <div className="display-flex-div edit_content_space">
            <textarea
              style={{
                width: "300px",
                resize: "none",
                height: "50px",
              }}
              name="notes"
              id="notes"
              value={fields?.notes}
              className="dark-mode-textarea"
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>
        <div className="table-section">
          {errorhandling ? (
            <ApiError />
          ) : (
            <>
              {receiveInvoiceData?.length ? (
                <Table1
                  editTable={false}
                  height={300}
                  Tablecoloum={emCreateInvoiceTabelColumn}
                  tableRow={receiveInvoiceData}
                  load={loading}
                  coloumWidthText={"Court"}
                  mandatoryColoum={"Offender #"}
                  setSelectedRowCheckbox={setSelectedRowCheckbox}
                  selectedRowCheckbox={selectedRowCheckbox}
                  createInvoiceMenu={true}
                />
              ) : (
                ""
              )}
            </>
          )}
        </div>
        <div className="page-info-buttons">
          <div className="buttons" style={{ alignItems: "baseline" }}>
            <div className="agency-column-fields">
              <button className="btn" onClick={handleSelectAll}>
                Select All
              </button>
            </div>
            <div className="agency-column-fields">
              <button className="btn" onClick={handleDeselectAll}>
                Deselect All
              </button>
            </div>
            <div className="agency-column-fields">
              <button className="btn" onClick={handleCreate}>
                Create
              </button>
            </div>
            <div className="agency-column-fields">
              <button className="btn">Import</button>
            </div>
            <div className="agency-column-fields">
              <button className="btn">Print</button>
            </div>
            {/* <div className="agency-column-fields">
                            <button className="btn">
                                Cancel
                            </button>
                        </div> */}
            <div className="agency-column-fields">
              <button className="btn" onClick={handleRefreshButtonClick}>
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <p className="edit_container_label">
              INVOICE AMOUNT:{" "}
              {formattedPrice(selectedRowCheckbox?.reduce((sum, item) => sum + item["Agency Amount"], 0))}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
