import { useNavigate, useParams } from "react-router-dom";
import AdminCrumbs from "../components/AdminCrumbs";
import AdminTabs from "../components/AdminTabs";
import "./feeSchedule.css";
import { useCallback, useEffect, useState } from "react";
import { FeeScheduleSubTab } from "./FeeScheduleSubTab";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { DateBox, DropDownBox, LoadPanel, Popup } from "devextreme-react";
import DataGrid, { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { Toster } from "../../../components/Toster/Toster";
import { FeeScheduleFind } from "./FeeSchedulePopup/FeeScheduleFind";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../../components/Logout";
import { handleGetLocal } from "../../../services/auth-service";
import { ApiError } from "../../Error/ApiError";
import { feeshudulefunctions } from "./feeshudulefunctions";
import { useDispatch } from "react-redux";
import {
  removeAdminTab,
  setAdminSavePopup,
  setAdmionTabs,
  setresetAdmionTabs,
  setresetbackAdmionTabs,
} from "../../../reducer/AdminReadReducer";
import { daysOfMonthsData } from "../../Scheduling/Data";
import { getAccessData } from "../../../utils/getAccessPermission";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const FeeSchedule = () => {
  const clas = new feeshudulefunctions();
  const { userAccessData } = useSelector((store) => store.Home);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const [entitidata, setentitidata] = useState([]);
  const [EntitiesTabledata2, setEntitiesTabledata2] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [fields, setFields] = useState({
    feeCode: "",
    feeCodeName: "",
    ddsLegalCode: "",
    acdCode: "",
    activeDate: "",
    inactiveBeginning: "",
    feeSchType: { name: "", id: "" },
    depositBankAccount: { name: "", id: "" },
    feeCodeDesc: "",
    ddsviolationDesc: "",
    glAccountNo: { name: "", id: "" },
    paymentPalnRecurring: false,
    paymentPalnMonthStart: { name: "", id: "" },
    paymentPalnDayOfMonth: { name: "", id: "" },
    paymentPalnPeriod: { name: "", id: "" },
    paymentPlanNoAdvancePayment: false,
    paymentPlanRoundUpNearestDollar: false,
    paymentPlanPayPriority: 0,
    paymentPlanInUseCount: "",
    quickAddName: "",
    quickAddObligationType: { name: "", id: "" },
    quickAddRecipientCategory: { name: "", id: "" },
    quickAddDisburseFrequency: { name: "", id: "" },
    quickAddDisburseMethod: { name: "", id: "" },
    quickAddDisburse: 0,
  });
  const [selectedValue, setSelectedValue] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [addobligation, setaddobligation] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditableButton, setIsEditableButton] = useState(false);
  const [feecodeValidation, setFeecodeValidation] = useState("");
  const [feecodeDescValidation, setFeecodeDescValidation] = useState("");
  const [findFeeSchedulePopup, setFindFeeSchedulePopup] = useState(false);
  const [feeScheduleDropdownData, setFeeScheduleDropdownData] = useState([]);
  const [quickAddRecipientCategoryValidation, setquickAddRecipientCategoryValidation] = useState("");
  const [feeSchTypeValidation, setFeeSchTypeValidation] = useState("");
  const [activeDateValidation, setActiveDateValidation] = useState("");
  const [depositBankAccountValidation, setDepositBankAccountValidation] = useState("");
  const [aftersave, setAftersave] = useState(false);
  const [feeScheduleResponseData, setFeeScheduleResponseData] = useState([]);
  const [feeScheduleHandling, setFeeScheduleHandling] = useState(false);
  const [typeFeeSchedule, setTypeFeeSchedule] = useState("Add");
  const pathname = handleGetLocal("pathURL");
  const params = useParams();
  const [hideAdd, SetHideAdd] = useState(false);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("user_designation") !== "Administrator") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const getUserAccessPermissions = () => {
      const accessData = getAccessData(userAccessData, "BN1");
      setAddAccess(accessData?.Add);
      setEditAccess(accessData?.Edit);
      setViewAccess(accessData?.View);
    };
    getUserAccessPermissions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    dispatch(setAdminSavePopup({ adminSavePopup: true }));
    if (name === "feeCode") {
      if (fields?.feeCode.charAt(0) === " ") {
        setFeecodeValidation("Please Enter Fee Code");
      } else {
        setFeecodeValidation("");
      }
    }
    if (name === "feeCodeDesc") {
      if (fields?.feeCodeDesc.charAt(0) === " ") {
        setFeecodeDescValidation("Please Enter Fee Code Desc");
      } else {
        setFeecodeDescValidation("");
      }
    }
  };

  const emptyField = () => {
    setFields({
      feeCode: "",
      feeCodeName: "",
      ddsLegalCode: "",
      acdCode: "",
      activeDate: "",
      inactiveBeginning: "",
      feeSchType: { name: "", id: "" },
      depositBankAccount: { name: "", id: "" },
      feeCodeDesc: "",
      ddsviolationDesc: "",
      glAccountNo: { name: "", id: "" },
      paymentPalnRecurring: false,
      paymentPalnMonthStart: { name: "", id: "" },
      paymentPalnDayOfMonth: { name: "", id: "" },
      paymentPalnPeriod: { name: "", id: "" },
      paymentPlanNoAdvancePayment: false,
      paymentPlanRoundUpNearestDollar: false,
      paymentPlanPayPriority: 0,
      paymentPlanInUseCount: "",
      quickAddName: "",
      quickAddObligationType: { name: "", id: "" },
      quickAddRecipientCategory: { name: "", id: "" },
      quickAddDisburseFrequency: { name: "", id: "" },
      quickAddDisburseMethod: { name: "", id: "" },
      quickAddDisburse: 0,
    });
    setSelectedValue();
  };

  const emptyValidation = () => {
    setFeecodeValidation("");
    setFeecodeDescValidation("");
    setquickAddRecipientCategoryValidation("");
    setFeeSchTypeValidation("");
    setActiveDateValidation("");
    setDepositBankAccountValidation("");
  };

  const oncancel = () => {
    setIsEditableButton(!isEditableButton);
    setIsEditable(false);
    emptyField();
    emptyValidation();
    dispatch(
      setresetbackAdmionTabs({
        title: feeScheduleResponseData?.[0]?.sLocalLegalCode
          ? feeScheduleResponseData?.[0]?.sLocalLegalCode
          : feeScheduleResponseData?.sLocalLegalCode,
        link: `/administration/users/feeschedule/${params.id}`,
      })
    );
    if (params?.id) {
      getFeeScheduleDropdownData();
      getFeeScheduleData(params?.id);
      setTypeFeeSchedule("Edit");
    }
    dispatch(setAdminSavePopup({ adminSavePopup: false }));
    SetHideAdd(false);
  };

  const OnAdd = () => {
    dispatch(setresetAdmionTabs(`/administration/users/feeschedule/${params.id}`));
    setIsEditableButton(true);
    setTypeFeeSchedule("Add");
    setIsEditable(true);
    emptyField();
    setSelectedValue();
  };

  const OnClone = () => {
    SetHideAdd(true);
    dispatch(setresetAdmionTabs(`/administration/users/feeschedule/${params.id}`));
    setIsEditableButton(true);
    setTypeFeeSchedule("Add");
    setIsEditable(true);
    setFields((prev) => ({
      ...prev,
      activeDate: "",
    }));
  };

  const getFeeScheduleDropdownData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/feeschedule/feeschedule-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setFeeScheduleDropdownData(val?.data?.data);
        const multiDropdownData = val?.data?.data?.entity?.map((item) => {
          return {
            Entity: item?.sName,
            Type: item?.x,
            Address: item?.sAddress1,
            gEntityId: item.gEntityId,
            gEntityTypeId: item.gEntityTypeId,
          };
        });
        setDataSource(multiDropdownData);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getFeeScheduleDropdownData();
  }, []);

  const date = new Date();
  const inputDate = new Date(date);
  const formattedDate = inputDate.toISOString();

  const data = {
    gFeeScheduleId: typeFeeSchedule === "Add" ? "" : feeScheduleResponseData?.gFeeScheduleId,
    dDoe: formattedDate,
    gEntryUserId: localStorage.getItem("gUserId"),
    sDdslegalCode: fields?.ddsLegalCode,
    sLocalLegalCode: fields?.feeCode,
    sAcdcode: fields?.acdCode,
    sLocalViolationDescription: fields?.feeCodeDesc,
    sDdsviolationDescription: fields?.ddsviolationDesc,
    bSuspendLicense: true,
    bSuspendLicenseU21: true,
    tNotes: "",
    dActive: fields?.activeDate,
    sRangeType: "",
    bOccurrenceBased: true,
    bDui: true,
    bSpeeding: true,
    bRoundUpDollar: fields?.paymentPlanRoundUpNearestDollar,
    bSubmitIfCmvonly: true,
    gCommercialDqid: "3fa85f64-5717-4562-b3fc-2c363f66afa6",
    gGlaccountId: fields?.glAccountNo?.id,
    bReportGecps: true,
    bGecpsNoFta: true,
    gFeeScheduleTypeId: fields?.feeSchType?.id,
    iLicensePoints: 0,
    sLocalLegalCodeExtra: fields?.feeCodeName,
    dInactive: fields?.inactiveBeginning,
    gFeeScheduleCategoryId: fields?.quickAddRecipientCategory?.id,
    iPayPriority: fields?.paymentPlanPayPriority,
    iPaymentPlanStartMonthOffset: fields?.paymentPalnMonthStart?.id,
    iPaymentPlanStartDay: fields?.paymentPalnDayOfMonth?.id,
    bPaymentPlanRecurring: fields?.paymentPalnRecurring,
    iPaymentPlanRecurringMonths: fields?.paymentPalnPeriod?.id,
    bNoAdvancedPayment: fields?.paymentPlanNoAdvancePayment,
    gDisburseFrequencyId: fields?.quickAddDisburseFrequency?.id,
    gDisburseMethodId: fields?.quickAddDisburseMethod?.id,
    gDisburseEntityId: selectedValue?.gEntityId,
    iDisbursePercent: fields?.quickAddDisburse,
    gObligationTypeId: fields?.quickAddObligationType?.id,
    sQuickAddName: fields?.quickAddName,
    gBankAccountId: fields?.depositBankAccount?.id,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!fields?.feeCode || fields.feeCode.charAt(0) === " ") {
      setFeecodeValidation("Enter Fee Code");
      isValid = false;
    }
    if (!fields?.feeCodeDesc || fields?.feeCodeDesc.charAt(0) === " ") {
      setFeecodeDescValidation("Please Enter Fee Code Desc");
      isValid = false;
    }
    if (!fields?.quickAddRecipientCategory?.id) {
      setquickAddRecipientCategoryValidation("Please Select Recipient Category");
      isValid = false;
    }
    if (!fields?.feeSchType?.id) {
      setFeeSchTypeValidation("Please Select Fee Sch Type");
      isValid = false;
    }
    if (!fields?.activeDate) {
      setActiveDateValidation("Please Select Active Date");
      isValid = false;
    }
    if (!fields?.depositBankAccount?.id) {
      setDepositBankAccountValidation("Please Select Deposit Bank Account");
      isValid = false;
    }
    if (isValid) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/feeschedule/addEditFeeSchedule`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoading(false);
          if (val?.data?.isSuccess) {
            dispatch(removeAdminTab("Fee Schedule"));
            dispatch(setAdminSavePopup({ adminSavePopup: false }));
            navigate(`/administration/users/feeschedule/${val?.data?.data?.gFeeScheduleId}`);
            dispatch(
              setAdmionTabs({
                title: `Fee Schedule: ${val?.data?.data?.sLocalLegalCode}`,
                link: `/administration/users/feeschedule/${val?.data?.data?.gFeeScheduleId}`,
              })
            );
            setIsEditableButton(!isEditableButton);
            getFeeScheduleData(val?.data?.data?.gFeeScheduleId);
            clas.saveentity(navigate, setLoading, val?.data?.data, entitidata, setEntitiesTabledata2, setentitidata);
            setIsEditable(false);
            setToastConfig({
              ...toastConfig,
              isVisible: true,
              message: typeFeeSchedule === "Add" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
            setTimeout(() => {
              setToastConfig({
                ...toastConfig,
                isVisible: false,
                message: typeFeeSchedule === "Add" ? "Successfully Added!" : "Successfully Updated!",
                type: "success",
              });
            }, 1900);
          } else {
            console.error(val?.data?.errors);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const getFeeScheduleData = async (gFeeScheduleId) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/feeschedule/get-feeschedule?gFeeScheduleId=${gFeeScheduleId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setFeeScheduleHandling(false);
          setFeeScheduleResponseData(val?.data?.data);
          setFindFeeScheduleData(val?.data?.data);
          setLoading(false);
        } else {
          setFeeScheduleHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setFeeScheduleHandling(true);
        }
      });
  };

  function findDropdown(array, keyToFind) {
    const foundItem = array?.find((item) => item?.key?.toLowerCase() === keyToFind);
    return foundItem ? { name: foundItem.value, id: foundItem.key } : { name: "", id: "" };
  }

  function findDropdownByNumber(array, keyToFind) {
    keyToFind = String(keyToFind);
    const foundItem = array?.find((item) => item?.key === keyToFind);
    return foundItem ? { name: foundItem.value, id: foundItem.key } : { name: "", id: "" };
  }

  const setFindFeeScheduleData = (data) => {
    setFields({
      feeCode: data?.sLocalLegalCode,
      feeCodeName: data?.sLocalLegalCodeExtra,
      ddsLegalCode: data?.sDdslegalCode,
      acdCode: data?.sAcdcode,
      activeDate: data?.dActive,
      inactiveBeginning: data?.dInactive,
      feeSchType: findDropdown(feeScheduleDropdownData?.feeScheduleType, data?.gFeeScheduleTypeId),
      depositBankAccount: findDropdown(feeScheduleDropdownData?.bankAccount, data?.gBankAccountId),
      feeCodeDesc: data?.sLocalViolationDescription,
      ddsviolationDesc: data?.sDdsviolationDescription,
      glAccountNo: { name: "", id: "" },
      paymentPalnRecurring: data?.bPaymentPlanRecurring,
      paymentPalnMonthStart: findDropdownByNumber(
        feeScheduleDropdownData?.monthStart,
        data?.iPaymentPlanStartMonthOffset
      ),
      paymentPalnDayOfMonth: {
        name: data?.iPaymentPlanStartDay,
        id: data?.iPaymentPlanStartDay,
      },
      paymentPalnPeriod: findDropdownByNumber(feeScheduleDropdownData?.period, data?.iPaymentPlanRecurringMonths),
      paymentPlanNoAdvancePayment: data?.bNoAdvancedPayment,
      paymentPlanRoundUpNearestDollar: data?.bRoundUpDollar,
      paymentPlanPayPriority: data?.iPayPriority,
      paymentPlanInUseCount: "",
      quickAddName: data?.sQuickAddName,
      quickAddObligationType: findDropdown(feeScheduleDropdownData?.obligationType, data?.gObligationTypeId),
      quickAddRecipientCategory: findDropdown(
        feeScheduleDropdownData?.feeScheduleCategory,
        data?.gFeeScheduleCategoryId
      ),
      quickAddDisburseFrequency: findDropdown(feeScheduleDropdownData?.disburseFrequency, data?.gDisburseFrequencyId),
      quickAddDisburseMethod: findDropdown(feeScheduleDropdownData?.disburseMethod, data?.gDisburseMethodId),
      quickAddDisburse: data?.iDisbursePercent,
      gEntryUserId: data?.gEntryUserId,
      gFeeScheduleId: data?.gFeeScheduleId,
    });
    const selectedDocket = dataSource?.find((item) => item.gEntityId.toLowerCase() === data.gDisburseEntityId);
    setSelectedValue(selectedDocket);
  };

  useEffect(() => {
    if (params?.id) {
      getFeeScheduleDropdownData();
      getFeeScheduleData(params?.id);
      setTypeFeeSchedule("Edit");
      setIsEditableButton(false);
    } else {
      setTypeFeeSchedule("Add");
      emptyField();
    }
  }, [params?.id]);

  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Popup
        visible={findFeeSchedulePopup}
        onHiding={() => {
          setFindFeeSchedulePopup(false);
        }}
        showTitle={false}
        width={700}
        height={520}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <FeeScheduleFind
          findFeeSchedulePopup={findFeeSchedulePopup}
          setFindFeeSchedulePopup={setFindFeeSchedulePopup}
        />
      </Popup>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <AdminCrumbs title={"Fee Schedule"} user={"Data"} />
      <div className="fee-schedule-container">
        <AdminTabs />
       {viewAccess ? <div className="fee-schedule-content">
          <p className="page-info-number">BN.1</p>
          <div className="fee-schedule-buttons"
            style={{
              pointerEvents: editAccess ? "auto" : "none",
              opacity: editAccess ? 1 : 0.6,
              cursor: editAccess ? "default" : "not-allowed"
            }}
          >
            <div className="buttons">
              {!hideAdd && (
                <button
                  className="btn"
                  onClick={() => {
                    OnAdd();
                  }}
                >
                  Add
                  <svg
                    className="svg-blue-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_6247_15083)">
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                        fill="#424167"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6247_15083">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              )}
              {typeFeeSchedule === "Edit" && (
                <>
                  <button
                    className="btn"
                    onClick={() => {
                      OnClone();
                    }}
                  >
                    Clone
                    <svg
                      className="svg-blue-white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_6247_15083)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                          fill="#424167"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6247_15083">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </>
              )}
              <button
                className="btn"
                onClick={() => {
                  setFindFeeSchedulePopup(true);
                }}
              >
                Find
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15088)">
                    <path
                      d="M15.5006 14.0006H14.7106L14.4306 13.7306C15.6306 12.3306 16.2506 10.4206 15.9106 8.39063C15.4406 5.61063 13.1206 3.39063 10.3206 3.05063C6.09063 2.53063 2.53063 6.09063 3.05063 10.3206C3.39063 13.1206 5.61063 15.4406 8.39063 15.9106C10.4206 16.2506 12.3306 15.6306 13.7306 14.4306L14.0006 14.7106V15.5006L18.2506 19.7506C18.6606 20.1606 19.3306 20.1606 19.7406 19.7506C20.1506 19.3406 20.1506 18.6706 19.7406 18.2606L15.5006 14.0006ZM9.50063 14.0006C7.01063 14.0006 5.00063 11.9906 5.00063 9.50063C5.00063 7.01063 7.01063 5.00063 9.50063 5.00063C11.9906 5.00063 14.0006 7.01063 14.0006 9.50063C14.0006 11.9906 11.9906 14.0006 9.50063 14.0006Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15088">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  if (params?.id) {
                    getFeeScheduleData(params?.id);
                    getFeeScheduleDropdownData();
                    setTypeFeeSchedule("Edit");
                  }
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            {typeFeeSchedule === "Edit" && !isEditableButton && (
              <>
                <div className="buttons">
                  <button
                    className="primary_btn"
                    onClick={() => {
                      setIsEditableButton(true);
                      setIsEditable(true);
                      setTypeFeeSchedule("Edit");
                    }}
                  >
                    Edit
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g clipPath="url(#clip0_6809_74139)">
                        <path
                          d="M3 17.4586V20.4986C3 20.7786 3.22 20.9986 3.5 20.9986H6.54C6.67 20.9986 6.8 20.9486 6.89 20.8486L17.81 9.93859L14.06 6.18859L3.15 17.0986C3.05 17.1986 3 17.3186 3 17.4586ZM20.71 7.03859C21.1 6.64859 21.1 6.01859 20.71 5.62859L18.37 3.28859C17.98 2.89859 17.35 2.89859 16.96 3.28859L15.13 5.11859L18.88 8.86859L20.71 7.03859Z"
                          fill="#fff"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6809_74139">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </>
            )}
            {isEditableButton && (
              <div className="buttons">
                <button
                  className="secondary_btn"
                  onClick={() => {
                    oncancel();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="primary_btn"
                  onClick={() => {
                    handleSave();
                    setAftersave(true);
                  }}
                >
                  Save
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5 10V15C16.5 15.55 16.05 16 15.5 16H3.5C2.95 16 2.5 15.55 2.5 15V10C2.5 9.45 2.05 9 1.5 9C0.95 9 0.5 9.45 0.5 10V16C0.5 17.1 1.4 18 2.5 18H16.5C17.6 18 18.5 17.1 18.5 16V10C18.5 9.45 18.05 9 17.5 9C16.95 9 16.5 9.45 16.5 10ZM10.5 9.67L12.38 7.79C12.77 7.4 13.4 7.4 13.79 7.79C14.18 8.18 14.18 8.81 13.79 9.2L10.2 12.79C9.81 13.18 9.18 13.18 8.79 12.79L5.2 9.2C4.81 8.81 4.81 8.18 5.2 7.79C5.59 7.4 6.22 7.4 6.61 7.79L8.5 9.67V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V9.67Z"
                      fill="white"
                    ></path>
                  </svg>
                </button>
              </div>
            )}
          </div>
          {feeScheduleHandling ? (
            <ApiError />
          ) : (
            <div className="fee-schedule-fileds">
              <div className="first-fields">
                <div className="fee-schedule-fileds-content">
                  <div className="go-to-inputs-field">
                    <label htmlFor="" className="go-to-label-filed">
                      Fee Code <span className="marks_mandatary">*</span>
                    </label>
                    <input
                      type="text"
                      name="feeCode"
                      id="feeCode"
                      value={fields.feeCode}
                      placeholder="Enter Fee"
                      className="fee-payment-content-info-input"
                      onChange={handleInputChange}
                      disabled={!isEditable}
                    />
                    {feecodeValidation && <p className="error_validation">{feecodeValidation}</p>}
                  </div>
                  <div className="go-to-inputs-field">
                    <input
                      type="text"
                      name="feeCodeName"
                      id="feeCodeName"
                      value={fields.feeCodeName}
                      className="fee-payment-content-info-input"
                      style={{ marginTop: "23px" }}
                      onChange={handleInputChange}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
                <div className="fee-schedule-fileds-content">
                  <div className="go-to-inputs-field">
                    <label htmlFor="" className="go-to-label-filed">
                      DDS Legal Code
                    </label>
                    <input
                      type="text"
                      name="ddsLegalCode"
                      id="ddsLegalCode"
                      value={fields.ddsLegalCode}
                      placeholder="Enter DDS Legal Code"
                      className="fee-payment-content-info-input"
                      onChange={handleInputChange}
                      disabled={!isEditable}
                    />
                  </div>
                  <div className="go-to-inputs-field">
                    <label htmlFor="" className="go-to-label-filed">
                      ACD Code
                    </label>
                    <input
                      type="text"
                      name="acdCode"
                      id="acdCode"
                      value={fields.acdCode}
                      placeholder="Enter ACD Code"
                      className="fee-payment-content-info-input"
                      onChange={handleInputChange}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
                <div className="fee-schedule-fileds-content">
                  <div className="go-to-inputs-field">
                    <label htmlFor="" className="go-to-label-filed">
                      Active Date <span className="marks_mandatary">*</span>
                    </label>
                    <DateBox
                      useMaskBehavior={true}
                      value={fields.activeDate}
                      onValueChange={(e) => {
                        setFields((prev) => ({
                          ...prev,
                          activeDate: e,
                        }));
                        setActiveDateValidation("");
                      }}
                      disabled={!isEditable}
                    />
                    {activeDateValidation && <p className="error_validation">{activeDateValidation}</p>}
                  </div>
                  <div className="go-to-inputs-field edit-content-space">
                    <label htmlFor="" className="go-to-label-filed">
                      Inactive Beginning
                    </label>
                    <DateBox
                      useMaskBehavior={true}
                      value={fields.inactiveBeginning}
                      onValueChange={(e) => {
                        setFields((prev) => ({
                          ...prev,
                          inactiveBeginning: e,
                        }));
                      }}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
                <div className="fee-schedule-fileds-content">
                  <div className="go-to-inputs-field edit-content-space">
                    <label htmlFor="" className="go-to-label-filed">
                      Fee Sch Type <span className="marks_mandatary">*</span>
                    </label>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"feeSchType"}
                      dropDownData={feeScheduleDropdownData?.feeScheduleType?.map((vall) => {
                        return {
                          name: vall?.value,
                          id: vall?.key,
                        };
                      })}
                      reset={setFeeSchTypeValidation}
                      disabled={!isEditable}
                    />
                    {feeSchTypeValidation && <p className="error_validation">{feeSchTypeValidation}</p>}
                  </div>
                  <div className="go-to-inputs-field edit-content-space">
                    <label htmlFor="" className="go-to-label-filed">
                      Deposit Bank Account
                      <span className="marks_mandatary">*</span>
                    </label>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"depositBankAccount"}
                      dropDownData={feeScheduleDropdownData?.bankAccount?.map((vall) => {
                        return {
                          name: vall?.value,
                          id: vall?.key,
                        };
                      })}
                      reset={setDepositBankAccountValidation}
                      disabled={!isEditable}
                    />
                    {depositBankAccountValidation && <p className="error_validation">{depositBankAccountValidation}</p>}
                  </div>
                </div>
                <div className="fee-schedule-fileds-content">
                  <div className="go-to-inputs-field">
                    <label htmlFor="" className="go-to-label-filed">
                      Fee Code Desc <span className="marks_mandatary">*</span>
                    </label>
                    <input
                      type="text"
                      name="feeCodeDesc"
                      id="feeCodeDesc"
                      value={fields.feeCodeDesc}
                      placeholder="Enter Fee Code Desc"
                      className="fee-payment-content-info-input"
                      onChange={handleInputChange}
                      disabled={!isEditable}
                    />
                    {feecodeDescValidation && <p className="error_validation">{feecodeDescValidation}</p>}
                  </div>
                  <div className="go-to-inputs-field">
                    <label htmlFor="" className="go-to-label-filed">
                      DDS violation Desc
                    </label>
                    <input
                      type="text"
                      name="ddsviolationDesc"
                      id="ddsviolationDesc"
                      value={fields.ddsviolationDesc}
                      placeholder="Enter DDS violation Desc"
                      className="fee-payment-content-info-input"
                      onChange={handleInputChange}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>
              <div className="second-field flex">
                <div className="card-container">
                  <p className="card-container-header">Payment Plan</p>
                  <div className="card-container-header-content">
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Month Start</p>
                        <DropDown
                          setfields={setFields}
                          fields={fields}
                          fieldNmae={"paymentPalnMonthStart"}
                          dropDownData={feeScheduleDropdownData?.monthStart?.map((vall) => {
                            return {
                              name: vall?.value,
                              id: vall?.key,
                            };
                          })}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Day of month</p>
                        <DropDown
                          setfields={setFields}
                          fields={fields}
                          fieldNmae={"paymentPalnDayOfMonth"}
                          dropDownData={daysOfMonthsData?.map((vall) => {
                            return {
                              name: vall?.value,
                              id: vall?.key,
                            };
                          })}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="input-flex">
                      <input
                        type="checkbox"
                        name="paymentPalnRecurring"
                        id="paymentPalnRecurring"
                        checked={fields.paymentPalnRecurring}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                      />
                      <label htmlFor="paymentPalnRecurring" className="fee-payment-content-info-para">
                        Recurring
                      </label>
                    </div>
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Period</p>
                        <DropDown
                          setfields={setFields}
                          fields={fields}
                          fieldNmae={"paymentPalnPeriod"}
                          dropDownData={feeScheduleDropdownData?.period?.map((vall) => {
                            return {
                              name: vall?.value,
                              id: vall?.key,
                            };
                          })}
                          disabled={
                            (!fields.paymentPalnRecurring && !isEditable) ||
                            (!fields.paymentPalnRecurring && isEditable) ||
                            (fields.paymentPalnRecurring && !isEditable)
                          }
                        />
                      </div>
                    </div>
                    <div className="input-flex">
                      <input
                        type="checkbox"
                        name="paymentPlanNoAdvancePayment"
                        id="paymentPlanNoAdvancePayment"
                        checked={fields.paymentPlanNoAdvancePayment}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                      />
                      <label htmlFor="paymentPlanNoAdvancePayment" className="fee-payment-content-info-para">
                        No Advance Payment
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-container">
                  <div className="card-container-header-content">
                    <div className="input-flex">
                      <input
                        type="checkbox"
                        name="paymentPlanRoundUpNearestDollar"
                        id="paymentPlanRoundUpNearestDollar"
                        checked={fields.paymentPlanRoundUpNearestDollar}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                      />
                      <label htmlFor="paymentPlanRoundUpNearestDollar" className="fee-payment-content-info-para">
                        Round up nearest Dollar
                      </label>
                    </div>
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Pay Priority</p>
                        <input
                          type="number"
                          name="paymentPlanPayPriority"
                          id="paymentPlanPayPriority"
                          value={fields.paymentPlanPayPriority}
                          placeholder="Enter Pay Priority"
                          className="fee-payment-content-info-input"
                          style={{ width: "271px" }}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="input-flex">
                      <label className="fee-payment-content-info-para">In Use Count</label>
                      <label className="edit_container_label">{fields.paymentPlanInUseCount}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="third-field">
                <div className="card-container">
                  <p className="card-container-header">Quick Add Information</p>
                  <div className="card-container-header-content">
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Name</p>
                        <input
                          type="text"
                          name="quickAddName"
                          id="quickAddName"
                          value={fields.quickAddName}
                          placeholder="Enter Name"
                          className="fee-payment-content-info-input"
                          style={{ width: "271px" }}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Obligation Type</p>
                        <DropDown
                          setfields={setFields}
                          fields={fields}
                          fieldNmae={"quickAddObligationType"}
                          dropDownData={feeScheduleDropdownData?.obligationType?.map((vall) => {
                            return {
                              name: vall?.value,
                              id: vall?.key,
                            };
                          })}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">
                          Recipient Category
                          <span className="marks_mandatary">*</span>
                        </p>
                        <DropDown
                          setfields={setFields}
                          fields={fields}
                          fieldNmae={"quickAddRecipientCategory"}
                          dropDownData={feeScheduleDropdownData?.feeScheduleCategory?.map((vall) => {
                            return {
                              name: vall?.value,
                              id: vall?.key,
                            };
                          })}
                          reset={setquickAddRecipientCategoryValidation}
                          disabled={!isEditable}
                        />
                        {quickAddRecipientCategoryValidation && (
                          <p className="error_validation">{quickAddRecipientCategoryValidation}</p>
                        )}
                      </div>
                    </div>
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Disburse Frequency</p>
                        <DropDown
                          setfields={setFields}
                          fields={fields}
                          fieldNmae={"quickAddDisburseFrequency"}
                          dropDownData={feeScheduleDropdownData?.disburseFrequency?.map((vall) => {
                            return {
                              name: vall?.value,
                              id: vall?.key,
                            };
                          })}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Disburse Method</p>
                        <DropDown
                          setfields={setFields}
                          fields={fields}
                          fieldNmae={"quickAddDisburseMethod"}
                          dropDownData={feeScheduleDropdownData?.disburseMethod?.map((vall) => {
                            return {
                              name: vall?.value,
                              id: vall?.key,
                            };
                          })}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    {/* <div className="input-flex">
                    <input type="checkbox" name="" id="recurring" />
                    <label
                      htmlFor="recurring"
                      className="fee-payment-content-info-para"
                    >
                      Recurring
                    </label>
                  </div>
                  <div className="inputs-fields-container">
                    <div className="inputs-field-content dropdown-inputs-field-content">
                      <p className="edit_container_label">Day of month</p>
                      <DropDown />
                    </div>
                  </div> */}
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Disburse %</p>
                        <input
                          type="number"
                          name="quickAddDisburse"
                          id="quickAddDisburse"
                          value={fields.quickAddDisburse}
                          placeholder="Enter Disburse %"
                          className="fee-payment-content-info-input"
                          style={{ width: "271px" }}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="inputs-fields-container">
                      <div className="inputs-field-content dropdown-inputs-field-content">
                        <p className="edit_container_label">Entity</p>
                        <DropDownBox
                          placeholder="Select..."
                          opened={isGridBoxOpened}
                          disabled={!isEditable}
                          dataSource={dataSource}
                          value={selectedValue?.Entity}
                          valueExpr={"Entity"}
                          displayExpr={"Entity"}
                          onOptionChanged={onGridBoxOpened}
                          contentRender={(value, element) => (
                            <DataGrid
                              dataSource={dataSource}
                              hoverStateEnabled={true}
                              height={180}
                              onRowClick={(e) => {
                                setSelectedValue(e?.data);
                                setIsGridBoxOpened(false);
                              }}
                            >
                              <Selection mode="single" />
                              <FilterRow visible={true} />
                              <Column dataField="Entity" caption="Entity" width={"auto"} />
                              <Column dataField="Type" caption="Type" width={"auto"} />
                              <Column dataField="Address" caption="Address" width={"auto"} />
                            </DataGrid>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <FeeScheduleSubTab
            isEditableButton={isEditableButton}
            aftersave={aftersave}
            feeScheduleResponseData={feeScheduleResponseData}
            gDisburseEntityId
            setentitidata={setentitidata}
            EntitiesTabledata2={EntitiesTabledata2}
            setEntitiesTabledata2={setEntitiesTabledata2}
            entitidata={entitidata}
          />
        </div>
        :
        "No BN.1 view access"}
      </div>
    </>
  );
};
