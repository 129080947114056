import { DateBox, LoadPanel, Popup, TextBox } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { useEffect, useState } from "react";
import { ChangeSupervisionPopup } from "./ChangeSupervisionPopup";
import { ChangeReportDate } from "./ChangeReportDate";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { ApiError } from "../../../../Error/ApiError";
import { Toster } from "../../../../../components/Toster/Toster";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        disabled={disabled}
        // reset={reset}
      />
    </>
  );
};

const ReportDate = ({ reportdate, setreportdate }) => {
  return (
    <Popup
      visible={reportdate}
      onHiding={() => {
        setreportdate(false);
      }}
      showTitle={false}
      width={650}
      height={600}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <ChangeReportDate setreportdate={setreportdate} />
    </Popup>
  );
};

const ChangeSupervision = ({
  setChangeSupervision,
  changeSupervision,
  reset,
  getTopDynamicDocketid,
  setToastConfig,
  toastConfig,
}) => {
  return (
    <Popup
      visible={changeSupervision}
      onHiding={() => {
        setChangeSupervision(false);
      }}
      showTitle={false}
      width={650}
      height={550}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <ChangeSupervisionPopup
        reset={reset}
        setChangeSupervision={setChangeSupervision}
        getTopDynamicDocketid={getTopDynamicDocketid}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
      />
    </Popup>
  );
};

export const DataTab = ({ getTopDynamicDocketid, userinfo }) => {
  const [changeSupervision, setChangeSupervision] = useState(false);
  const [reportdate, setreportdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    "Court Sentence Date": "",
    "Supervision Start Date": "",
    "Use Create Date": false,
    "docket Link Type": { name: "", id: "" },
    "Parent Docket": { name: "", id: "" },
  });
  const [errorhandling, seterrorhandling] = useState(false);
  const [errorhandling2, seterrorhandling2] = useState(false);
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");
  const [dataTabdata, setdataTabdata] = useState({});
  const [docketLinkType, setdocketLinkTypes] = useState([]);
  const [ParentDocketdropdown, setParentDocketdropdown] = useState([]);
  const [reset, setreset] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const datatabapi = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-date?docketId=${getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setdataTabdata(val?.data?.data);
          setFields({
            ...fields,
            "Court Sentence Date": val?.data?.data?.courtSentenceDate,
            "Supervision Start Date": val?.data?.data?.supervisionStartDate,
          });
          setdocketLinkTypes(
            val?.data?.data?.docketLinkTypes.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
  const ParentDocketdropdownapi = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-docket-dropdown/?personId=${userinfo?.gPersonId}&docketId=${getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling2(false);
          setParentDocketdropdown(
            val?.data?.data.map((vall) => {
              return {
                name: vall?.sDocketNumber,
                id: vall?.gDocketId,
              };
            })
          );
        } else {
          seterrorhandling2(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling2(true);
        }
      });
  };

  useEffect(() => {
    if (getTopDynamicDocketid && userinfo) {
      datatabapi();
      ParentDocketdropdownapi();
    }
  }, [getTopDynamicDocketid, userinfo]);

  return (
    <>
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <ChangeSupervision
        setChangeSupervision={setChangeSupervision}
        changeSupervision={changeSupervision}
        reset={reset}
        getTopDynamicDocketid={getTopDynamicDocketid}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
      />
      <ReportDate setreportdate={setreportdate} reportdate={reportdate} />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      {!errorhandling || !errorhandling2 ? (
        <div
          style={{
            marginTop: "2%",
            marginBottom: "5%",
            display: "flex",
          }}
        >
          <div>
            <div
              style={{
                marginTop: "10px",
                justifyContent: "space-between",
              }}
              className="dataTab"
            >
              <div className="dataTabblock1">
                <div>
                  <p style={{ width: "135px" }}>Supervision</p>
                  <p
                    style={{
                      marginLeft: "6px",
                    }}
                  >
                    {dataTabdata?.supervision}
                  </p>
                </div>
                <div>
                  <p style={{ width: "135px" }}>Term in Days/Years</p>
                  <p
                    style={{
                      marginLeft: "6px",
                    }}
                  >
                    {dataTabdata?.sentenceDays ? dataTabdata?.sentenceDays : 0}{" "}
                    Days (
                    {((days) =>
                      `${Math.floor(
                        dataTabdata?.sentenceDays
                          ? dataTabdata?.sentenceDays
                          : 0 / 365
                      )}.${Math.floor(
                        (dataTabdata?.sentenceDays
                          ? dataTabdata?.sentenceDays
                          : 0 % 365) / 30
                      )}`)()}{" "}
                    years )
                  </p>
                </div>
                <div>
                  <p style={{ width: "135px" }}>Remaining Time</p>
                  <p
                    style={{
                      marginLeft: "6px",
                    }}
                  >
                    {dataTabdata?.sentenceDaysRemaining
                      ? dataTabdata?.sentenceDaysRemaining
                      : 0}{" "}
                    Days (
                    {((days) =>
                      `${Math.floor(
                        dataTabdata?.sentenceDaysRemaining
                          ? dataTabdata?.sentenceDaysRemaining
                          : 0 / 365
                      )}.${Math.floor(
                        (dataTabdata?.sentenceDaysRemaining
                          ? dataTabdata?.sentenceDaysRemaining
                          : 0 % 365) / 30
                      )}`)()}{" "}
                    years )
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop:'20px'
              }}
            >
              <div>
                <p style={{ fontWeight: "bold" }}>Parent Docket</p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"Parent Docket"}
                  disabled={!fields?.["docket Link Type"].id}
                  dropDownData={ParentDocketdropdown}
                />
              </div>
              <div style={{ marginTop: "20px", marginLeft: "2%" }}>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"docket Link Type"}
                  dropDownData={docketLinkType}
                />
              </div>
              <button
                onClick={() => {
                  setreset(!reset);
                  setChangeSupervision(true);
                }}
                style={{
                  marginTop: "25px",
                  marginLeft: "2%",
                }}
                className="btn"
              >
                Change Supervision Date
              </button>
            </div>
          </div>
          <div style={{ marginLeft: "10%", width: "25%" }}>
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Court Sentence Date
              </p>
              <DateBox
                disabled={true}
                style={{ marginTop: "3.5%", width: "70%" }}
                value={fields["Court Sentence Date"]}
                onValueChange={(e) => {
                  setFields({
                    ...fields,
                    "Court Sentence Date": e
                      ? new Date(
                          new Date(e).toLocaleDateString()
                        ).toLocaleDateString("en-CA")
                      : null,
                  });
                }}
                useMaskBehavior={true}
              />
            </div>
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Supervision Start Date
              </p>
              <DateBox
                disabled={true}
                style={{ marginTop: "3.5%", width: "70%" }}
                value={fields["Supervision Start Date"]}
                onValueChange={(e) => {
                  setFields({
                    ...fields,
                    "Supervision Start Date": e
                      ? new Date(
                          new Date(e).toLocaleDateString()
                        ).toLocaleDateString("en-CA")
                      : null,
                  });
                }}
                useMaskBehavior={true}
              />
              <div style={{ display: "flex", marginTop: "10px" }}>
                <input checked={fields["Use Create Date"]} type="checkbox" />
                <p style={{ fontWeight: "bold" }}>Use Create Date</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ApiError />
      )}
    </>
  );
};
