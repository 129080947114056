import React, { useEffect, useState } from "react";
import { ContextMenu, LoadPanel, Popup } from "devextreme-react";
import { set_dynamicParticipatTab } from "../../../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleGetLocal } from "../../../../services/auth-service";
import axios from "axios";
import { DeleteViewImage } from "./DeleteViewImage";
import { useSelector } from "react-redux";
import { Toster } from "../../../../components/Toster/Toster";

const DeleteImagePopUp = ({
  deletePopup,
  setdeletePopup,
  getbinaryDataIdInAttachment,
  toastConfig,
  setToastConfig,
  attachmentfunction,
  getphotoTableData,
}) => {
  return (
    <>
      <Popup
        visible={deletePopup}
        onHiding={() => {
          setdeletePopup(false);
        }}
        showTitle={false}
        width={500}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <DeleteViewImage
          setdeletePopup={setdeletePopup}
          getbinaryDataIdInAttachment={getbinaryDataIdInAttachment}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          attachmentfunction={attachmentfunction}
          getphotoTableData={getphotoTableData}
        />
      </Popup>
    </>
  );
};

const Attechment_Photos_allImage = ({ allimages, attachmentfunction, getphotoTableData }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [deletePopup, setdeletePopup] = useState(false);
  const [getbinaryDataIdInAttachment, setgetbinaryDataIdInAttachment] = useState("");
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  return (
    <>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <DeleteImagePopUp
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        deletePopup={deletePopup}
        setdeletePopup={setdeletePopup}
        getbinaryDataIdInAttachment={getbinaryDataIdInAttachment}
        attachmentfunction={attachmentfunction}
        getphotoTableData={getphotoTableData}
      />
      <div className="Attechment_Photos_allImage">
        <div style={{ marginTop: "2%", display: "flex", flexWrap: "wrap" }}>
          {allimages.map((val, i) => {
            return (
              <>
                <React.Fragment>
                  <div className="imagee" id={`image${i}`}>
                    <img src={"data:image/jpeg;base64," + encodeURI(val?.image)} />
                  </div>

                  <ContextMenu
                    dataSource={[
                      { text: "View", icon: "find" },
                      { text: "Delete", icon: "remove" },
                    ]}
                    width={200}
                    target={`#image${i}`}
                    onItemClick={(e) => {
                      if (e.itemData.text === "View") {
                        dispatch(
                          set_dynamicParticipatTab({
                            Offender: "/image-viewer",
                            title: "Image-Viewer",
                            image: val?.image,
                          })
                        );
                        localStorage.setItem("atachmentTab", 9);
                        navigation("/image-viewer");
                      } else if (e.itemData.text === "Delete") {
                        setgetbinaryDataIdInAttachment(val?.id);
                        setdeletePopup(true);
                      }
                    }}
                  />
                </React.Fragment>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
const Attechment_Photos_galery = ({ allimages, attachmentfunction, getphotoTableData }) => {
  const [selectImage, setselectImage] = useState("data:image/jpeg;base64," + encodeURI(allimages[0]?.image));
  const [select, setselect] = useState(0);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [deletePopup, setdeletePopup] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [getbinaryDataIdInAttachment, setgetbinaryDataIdInAttachment] = useState("");
  useEffect(() => {
    setselectImage("data:image/jpeg;base64," + encodeURI(allimages[0]?.image));
  }, [allimages]);
  return (
    <>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <DeleteImagePopUp
        deletePopup={deletePopup}
        setdeletePopup={setdeletePopup}
        getbinaryDataIdInAttachment={getbinaryDataIdInAttachment}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        attachmentfunction={attachmentfunction}
        getphotoTableData={getphotoTableData}
      />
      <div style={{ display: "flex", marginTop: "1.5%" }}>
        <div className="Attechment_Photos_galery_all_images">
          {allimages.map((val, i) => {
            return (
              <>
                <React.Fragment>
                  <img
                    id={`image${i}`}
                    onClick={() => {
                      setselectImage("data:image/jpeg;base64," + encodeURI(val?.image));
                      setselect(i);
                    }}
                    style={{ border: select == i && "2px solid #424167" }}
                    className="imagee"
                    src={"data:image/jpeg;base64," + encodeURI(val?.image)}
                  />
                  <ContextMenu
                    dataSource={[
                      { text: "View", icon: "find" },
                      { text: "Delete", icon: "remove" },
                    ]}
                    width={200}
                    target={`#image${i}`}
                    onItemClick={(e) => {
                      if (e.itemData.text === "View") {
                        dispatch(
                          set_dynamicParticipatTab({
                            Offender: "/image-viewer",
                            title: "Image-Viewer",
                            image: val?.image,
                          })
                        );
                        localStorage.setItem("atachmentTab", 9);
                        navigation("/image-viewer");
                      } else if (e.itemData.text === "Delete") {
                        setgetbinaryDataIdInAttachment(val?.id);
                        setdeletePopup(true);
                      }
                    }}
                  />
                </React.Fragment>
              </>
            );
          })}
        </div>
        <div className="Attechment_Photos_perticularImage">
          <div>{selectImage && <img className="imagee" src={selectImage} />}</div>
        </div>
      </div>
    </>
  );
};

export const Photos = ({ userinfo, allimages, loadingg, attachmentfunction, getphotoTableData }) => {
  const [tabSwitch, settabSwitch] = useState(1);
  const [onHover, setOnHovrer] = useState(false);
  const [onHover2, setOnHovrer2] = useState(false);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loadingg} delay={10} />
      <div className="Attechment_Photos">
        <div style={{ display: "flex", marginTop: "1.5%" }}>
          <div
            onMouseOver={() => {
              setOnHovrer(true);
            }}
            onMouseLeave={() => {
              setOnHovrer(false);
            }}
            style={{
              backgroundColor: tabSwitch === 1 && "#eeeeff",
              position: "relative",
            }}
            className="Attechment_Photos_subButton1"
            onClick={() => {
              settabSwitch(1);
            }}
          >
            <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 4H4V0H2C0.9 0 0 0.9 0 2V4ZM0 9H4V5H0V9ZM5 9H9V5H5V9ZM10 9H14V5H10V9ZM5 4H9V0H5V4ZM10 0V4H14V0H10ZM15 9H19V5H15V9ZM2 14H4V10H0V12C0 13.1 0.9 14 2 14ZM5 14H9V10H5V14ZM10 14H14V10H10V14ZM15 14H17C18.1 14 19 13.1 19 12V10H15V14ZM15 0V4H19V2C19 0.9 18.1 0 17 0H15Z"
                fill="#424167"
              />
            </svg>
            {onHover && (
              <div
                style={{
                  position: "absolute",
                  width: "80px",
                  height: "36px",
                  background: "var(--color2)",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                  borderRadius: "4px",
                  textAlign: "center",
                  marginLeft: "-2%",
                  marginTop: "80px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "8px",
                    fontWeight: 600,
                  }}
                >
                  {"Gallery View"}
                </p>
              </div>
            )}
          </div>
          <div
            onMouseOver={() => {
              setOnHovrer2(true);
            }}
            onMouseLeave={() => {
              setOnHovrer2(false);
            }}
            style={{
              backgroundColor: tabSwitch === 2 && "#eeeeff",
              position: "relative",
            }}
            className="Attechment_Photos_subButton1"
            onClick={() => {
              settabSwitch(2);
            }}
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 16V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16ZM5.9 10.98L8 13.51L11.1 9.52C11.3 9.26 11.7 9.26 11.9 9.53L15.41 14.21C15.66 14.54 15.42 15.01 15.01 15.01H3.02C2.6 15.01 2.37 14.53 2.63 14.2L5.12 11C5.31 10.74 5.69 10.73 5.9 10.98Z"
                fill="black"
                fillOpacity="0.6"
              />
            </svg>
            {onHover2 && (
              <div
                style={{
                  position: "absolute",
                  width: "105px",
                  height: "36px",
                  background: "var(--color2)",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                  borderRadius: "4px",
                  textAlign: "center",
                  marginLeft: "-2%",
                  marginTop: "80px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "8px",
                    fontWeight: 600,
                  }}
                >
                  {"Thumbnail View"}
                </p>
              </div>
            )}
          </div>
        </div>
        {tabSwitch == 1 ? (
          <Attechment_Photos_allImage
            allimages={allimages}
            attachmentfunction={attachmentfunction}
            getphotoTableData={getphotoTableData}
          />
        ) : (
          <Attechment_Photos_galery
            allimages={allimages}
            attachmentfunction={attachmentfunction}
            getphotoTableData={getphotoTableData}
          />
        )}
      </div>
    </>
  );
};
