import React, { useState } from "react";
import { Table1 } from "../../../../../../Administration/Table1/Table1";
import { emPeriodTabelColumn } from "../../Data";
import { Popup } from "devextreme-react";
import AddEditEMPeriodPopup from "../MonitoringPopup/AddEditEMPeriodPopup";
import VoidAlertPopup from "../MonitoringPopup/VoidAlertPopup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { handleGetLocal } from "../../../../../../../services/auth-service";

const EmPeriod = ({
  emPeriodTableData,
  loading,
  setLoading,
  toastConfig,
  setToastConfig,
  emPeriodDataApi,
  gObligationID,
  emTypeDropdownData,
  frequencyDropdownData,
  gotooblogationValue,
  paymentTypeDropdownData,
  obligationDropdownData,
  equipmentTypeDropdownData,
  isAccrual,
}) => {
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [addEditEMPeriodPopup, setAddEditEMPeriodPopup] = useState(false);
  const [voidAlertPopup, setVoidAlertPopup] = useState(false);
  const [typeTab, SetTypeTab] = useState("");
  const [rowLevelData, setRowLevelData] = useState([]);
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");

  const { dockerValue } = useSelector((store) => store.participateNotes);

  return (
    <>
      <Popup
        visible={addEditEMPeriodPopup}
        onHiding={() => {
          setAddEditEMPeriodPopup(!addEditEMPeriodPopup);
        }}
        showTitle={false}
        width={700}
        height={830}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddEditEMPeriodPopup
          addEditEMPeriodPopup={addEditEMPeriodPopup}
          setAddEditEMPeriodPopup={setAddEditEMPeriodPopup}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          setLoading={setLoading}
          typeTab={typeTab}
          rowLevelData={rowLevelData}
          gObligationID={gObligationID}
          emTypeDropdownData={emTypeDropdownData}
          frequencyDropdownData={frequencyDropdownData}
          refreshApi={emPeriodDataApi}
          gotooblogationValue={gotooblogationValue}
          paymentTypeDropdownData={paymentTypeDropdownData}
          obligationDropdownData={obligationDropdownData}
          equipmentTypeDropdownData={equipmentTypeDropdownData}
          isAccrual={isAccrual}
        />
      </Popup>
      <Popup
        visible={voidAlertPopup}
        onHiding={() => {
          setVoidAlertPopup(!voidAlertPopup);
        }}
        showTitle={false}
        width={450}
        height={170}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidAlertPopup
          voidAlertPopup={voidAlertPopup}
          setVoidAlertPopup={setVoidAlertPopup}
          setLoading={setLoading}
          navigate={navigate}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={emPeriodDataApi}
          apipayload={{
            gPeriodObligationID: rowLevelData?.gPeriodObligationId,
            gEMPeriodID: rowLevelData?.gPeriodId,
            comments: "",
          }}
          apiUrl={`api/obligations/voidMonitoringPeriodObligation`}
          typePara={"Void EM Period."}
        />
      </Popup>
      <div className="recipients-table-section">
        <div className="buttons">
          <button
            className="btn"
            onClick={() => {
              setLoading(true);

              const docketId = gotooblogationValue?.gDocketId
                ? gotooblogationValue?.gDocketId
                : gotooblogationValue?.getTopDynamicDocketid
                ? gotooblogationValue?.getTopDynamicDocketid
                : dockerValue?.getTopDynamicDocketid;

              if (docketId)
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/api/obligations/addEmPeriod?GDocketId=${docketId}`,
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        Domain: `${pathname}`,
                      },
                    }
                  )
                  .then((res) => {
                    setLoading(false);
                    const emPeriod = res.data.data;

                    setRowLevelData((prev) => ({ ...prev, ...emPeriod }));

                    SetTypeTab("Add");

                    setAddEditEMPeriodPopup(!addEditEMPeriodPopup);
                  })
                  .catch(() => {
                    setLoading(false);
                  });
            }}
          >
            Add
            <svg
              className="svg-blue-white"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_6714_35042)">
                <path
                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                  fill="#424167"
                />
              </g>
              <defs>
                <clipPath id="clip0_6714_35042">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button
            className="btn"
            onClick={() => {
              setexportPopupOpen(true);
            }}
          >
            Export
            <svg
              className="svg-blue-white"
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                fill="#424167"
              />
            </svg>
          </button>
        </div>
        <div className="table">
          <Table1
            editTable={false}
            height={400}
            Tablecoloum={emPeriodTabelColumn}
            tableRow={emPeriodTableData}
            load={loading}
            coloumWidthText={"EM Period Obligation"}
            mandatoryColoum={"Amount"}
            exportInDifferentFormate={true}
            exportPopupOpen={exportPopupOpen}
            setexportPopupOpen={setexportPopupOpen}
            exportwidth={27}
            exporttitle={"Em-Period-Obligation"}
            SetTypeTab={SetTypeTab}
            setAddEditEMPeriodPopup={setAddEditEMPeriodPopup}
            obligationMontoringEmPeriodMenu={true}
            setRowLevelData={setRowLevelData}
            setVoidAlertPopup={setVoidAlertPopup}
            setVoideFalse={true}
          />
        </div>
      </div>
    </>
  );
};

export default EmPeriod;
